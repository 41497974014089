import React from "react";
import HalfPie from "./HalfPie";
import HorizontalBarChart from "./HorzReport";
import { Box, Grid, Typography } from "@mui/material";
import Navbar from "../layout/Navbar";
import DeviceList from "./DeviceList";
import { getSideFlag } from "../common/mainfunctions";
export default function DeviceIndex() {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        {getSideFlag() == "true" ? <></> : <Navbar name="Devices" />}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            mt: getSideFlag() === "true" ? "0px" : "20px",
            ml: "-35px",
          }}
        >
          <DeviceList />
        </Box>
      </Box>
    </>
    // <>
    //   <Box sx={{ display: "-webkit-box" }}>
    //     <Navbar name="Device" />
    //     <Box component="main" sx={{ flexGrow: 1, p: 3, mt: "15px" }}>
    //       <Grid
    //         container
    //         rowSpacing={1}
    //         wrap="wrap"
    //         columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    //         className="chart-main"
    //         style={{
    //           backgroundColor: "#FFF",
    //           marginTop: "50px",
    //           overflow: "hidden",
    //         }}
    //       >
    //         <DeviceList />
    //         {/* <HalfPie /> */}
    //       </Grid>
    //       <Grid item xs={12} md={12}>
    //         <div style={{ marginTop: "10px" }}>
    //           {/* <HorizontalBarChart /> */}
    //         </div>
    //       </Grid>
    //       <Grid item xs={12} md={12}>
    //         <div style={{ marginTop: "10px" }}>
    //           {/* <Typography variant="h2">400</Typography> */}
    //           {/* <Typography>Patching Compliance</Typography> */}
    //         </div>
    //       </Grid>
    //     </Box>
    //   </Box>
    // </>
  );
}
