import {React,
  useState,useEffect }from 'react';
  import PropTypes from 'prop-types';
  import Button from '@mui/material/Button';
  import { alpha,styled } from '@mui/material/styles';
  import Dialog from '@mui/material/Dialog';
  import DialogTitle from '@mui/material/DialogTitle';
  import DialogContent from '@mui/material/DialogContent';
  import IconButton from '@mui/material/IconButton';
  import CloseIcon from '@mui/icons-material/Close';
  import Typography from '@mui/material/Typography';
  import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText,
    InputBase,
      Select, Stack } from '@mui/material';
  import { useFormik } from "formik";
import CustomLoader from '../common/CustomLoader';
import { DELETE_USER_FROM_AZURE_URL } from '../common/ApiUrl';
import { CustomMessage, authHeader } from '../common/mainfunctions';
import API from '../common/API';
import { useSnackbar } from 'notistack';
import deluser from "../../images/deluser.svg"
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
export default function DeleteMailbox(props){
  const {open,handleclose,id,successCall}=props
  const [value,setValue]=useState()
  const {enqueueSnackbar}=useSnackbar()
  const [loading,setLoading]=useState(false)
  const handleChange=(e)=>{
      setValue(e.target.value)
  }
  const handleDeleteYes = async () => {
      console.log('log',value)
      if(value.toLowerCase()=='delete'){
      setLoading(true)
      const config = {
        method: "DELETE",
        url: DELETE_USER_FROM_AZURE_URL,
        headers: authHeader(),
        data: {
          userId: id,
        },
      };
      try {
        const response = await API(config);
        const { data, message, status } = response.data;
        if (status == "Success") {
          CustomMessage("Successfully Deleted.It takes time to reflect the changes.", "success", enqueueSnackbar);
          successCall()
        }
      } catch (error) {
        console.error(error);
      } finally {
          setLoading(false)
      }
  }else{
      CustomMessage("Type Delete if you want to delete the user", "error", enqueueSnackbar);

  }
    };
  return(
      <div 
      >
      
      <BootstrapDialog
        onClose={handleclose}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{borderRadius:'3% !important',
      padding:'10px !important'}}
      >
        <BootstrapDialogTitle
        style={{minWidth:'500px'}}
         id="customized-dialog-title" onClose={handleclose}>
          
        </BootstrapDialogTitle>
        <DialogContent >
          <Box style={{textAlign:'center'}}>
          <img src={deluser}/>

          </Box>
          <Typography
          style={{
            fontWeight: "700",
            fontSize: "16px",
            textAlign: "center",
          }}
        >Delete User
        </Typography>
          {/* <form 
          onSubmit={handleDeleteYes}
          > */}
        
         
        
        <Typography

        style={{fontWeight:'700',
      fontSize:'16px',
  padding:'10px',
}}
        >
          Type "Delete"
        </Typography>
   
        <FormControl sx={{ width: "100%" }} variant="standard">
              <BootstrapInput
                name="confirm"
                id="confirm"
                value={value}
                onChange={handleChange}
                placeholder="Type Delete"
              />
              
            </FormControl>

      <Box sx={{textAlign:'center'}}>

          <Button type="submit" className="login-btn-group-bl" size="medium" 
      
          onClick={handleDeleteYes}
          >
            {loading&&<CustomLoader loading={loading}/>}
              SUBMIT
            </Button>
            <Button  className="login-btn-group" size="medium" 
          style={{
          marginBottom:'5px'
      }}
          onClick={handleclose}
          >
              CANCEL
            </Button>
      </Box>

          {/* </form> */}

        </DialogContent>
        
      </BootstrapDialog>
    </div>
  )
}