import { combineReducers } from "redux";
import NavbarFlagReducer from "./NavbarFlagReducer";
import StateListReducer from "./StateListReducer"
import ZipcodeReducer from "./ZipcodeReducer"
import SteponeReducer from "./SteponeReducer"
import AuthFlagReducer from "./AuthFlagReducer";
import AuthTokenSaveReducer from "./AuthTokenSaveReducer";
import UserDetailsReducer from "./UserDetailsReducer"
import GroupListReducer from "./GroupListReducer";
import OfficeListReducer from "./OfficeListReducer";
import DepartmentReducer from "./DepartmentReducer";
import LicenceListReducer from "./LicenceListReducer";
import RoleListReducer from "./RoleListReducer";
import JobTitleReducer from "./JobTitleReducer";
import UserListReducer from "./UserListReducer";
import CreateOrgDetailsReducer from "./CreateOrgDetailsReducer";
import StepTwoReducer from "./StepTwoReducer";
import StepThreeReducer from "./StepThreeReducer"
import StepFourReducer from "./StepFourReducer";
import StepFiveReducer from "./StepFiveReducer";
import StepSixReducer from "./StepSixReducer";
import SystemLicenseReducer from "./SystemLicenseReducer"
import DomainListReducer from "./DomainListReducer";
import HardCodeRoleListReducer from "./HardCodeRoleListReducer";
import DistributionGroupListReducer from "./DistributionGroupListReducer";
import DistributionForListingReducer from "./DistributionForListingReducer"
const RootReducer = combineReducers({
    NavbarFlagReducer,
    StateListReducer,
    ZipcodeReducer,
    SteponeReducer,
    AuthFlagReducer,
    AuthTokenSaveReducer,
    UserDetailsReducer,
    GroupListReducer,
    OfficeListReducer,
    DepartmentReducer,
    LicenceListReducer,
    RoleListReducer,
    JobTitleReducer,
    UserListReducer,
    CreateOrgDetailsReducer,
    StepTwoReducer,
    StepThreeReducer,
    StepFourReducer,
    StepFiveReducer,
    StepSixReducer,
    SystemLicenseReducer,
    DomainListReducer,
    HardCodeRoleListReducer,
    DistributionGroupListReducer,
    DistributionForListingReducer,
})
export default RootReducer
