import { Box, Grid, IconButton, InputBase, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import filicon from "../../../images/fil-icon.png"
import SearchIcon from "@mui/icons-material/Search";
import addicon from "../../../images/addicon.png"
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import oval from "../../../images/Oval-white.png"
import deleteIcon from "../../../images/deleteicon.png"
import AddNewLicense from "../../organizationadd/AddNewLicense";
import { getLicenceList } from "../../../redux/action";
import { DELETE_LICENSE_URL, DELETE_OFFICES_URL, DELETE_ROLE_URL, GET_ROLES_LIST_URL, HIDE_LICENSE_URL } from "../../common/ApiUrl";
import { CustomMessage, authHeader } from "../../common/mainfunctions";
import API from "../../common/API";
import { useSnackbar } from "notistack";
import closeIcon from "../../../images/close1.png"
import AddNewOffice from "../../organizationadd/AddNewOffice";
import AddNewRole from "../../organizationadd/AddNewRole";
export default function OrganizationRoles(props){
    const [open,setOpen]=useState(false)
    // const roleList=useSelector((list)=>list.RoleListReducer)
    const [roleList,setRoleList]=useState()
    const {data=[],getOrgDetails,orgid}=props
    const {orgRoleDetails=[]}=data||[]
    const dispatch=useDispatch()
    const {enqueueSnackbar}=useSnackbar()
    const [search,setSearch]=useState()
const getRoleList=async()=>{
  const options = {
    method: 'GET',
    headers: authHeader(),
    url: GET_ROLES_LIST_URL,
    params:{
      organizationId:orgid,
      search:search,
    }
  }
  try{
    const response=await API(options)
  
      if (response.status === 200) {
        const { status, data } = response.data;
       setRoleList(data.list)
      }
    }
    catch(error){
      if (error.response) {
        let { data } = error.response
      }
    }
}
    const addlicense=()=>{
        setOpen(!open)
    }
    const handleClose=()=>{
        setOpen(false)
        getOrgDetails()
        getRoleList(orgid,search)

    }
    useEffect(()=>{
        getRoleList(orgid,search)
    },[orgid,search])
    
    const handleDelete=async(e)=>{
        const id=e.currentTarget.dataset.id
        const config = {
            method: "DELETE",
            url: DELETE_ROLE_URL,
           headers:authHeader(),
           data:{
            roleId:id,
           }
          };
          try {
            const response = await API(config);
            const {status,message}=response.data
            if(status=="Success"){
        CustomMessage(message, "success", enqueueSnackbar);
getOrgDetails()
getRoleList(orgid,search)

            }
          } catch (error) {
            console.log(error);
          } 
    }
    const setSearchFun=(e)=>{
      setSearch(e.target.value)
    }
    const setSearchFuncall=()=>{
      getRoleList(orgid,search)

    }
    return(
        <>
          <Box sx={{ display: "flex" }}>
        <Box component="main" 
        sx={{ flexGrow: 1 }}>
        <Grid
            sx={{ mt: 1.5,pl:2}}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
            {/* <Stack 
        direction='row'
        justifyContent='space-between'> */}
        <Typography className="acti-log" style={{
      fontSize:'24px',fontWeight:'500',lineHeight:'50px'}}>
                Roles List
                <IconButton
                aria-label="delete"
                // className={classes.icoBtnInside}
                size="small"
                // onClick={editProject
                >
                {/* <FilterAltIcon fontSize="inherit" /> */}
                <img src={filicon}/>
              </IconButton>
            </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
            <Paper
            //   component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "-webkit-fill-available",
                marginTop: "auto",
                marginBottom: "auto",
                height:'50px',
              }}
            >
              
                <InputBase
                  sx={{ flex: 1 }}
                  placeholder="Search ..."
                  inputProps={{ "aria-label": "   search" }}
                  onChange={setSearchFun}
                  value={search}
                />
              <IconButton type="submit" sx={{ p: "10px" }}
               aria-label="search"
                style={{backgroundColor:"#FFAB00",borderRadius:'10%'}}
                onClick={setSearchFuncall}
                >
                <SearchIcon />
              </IconButton>
              </Paper>
            </Grid>
            <Grid item xs={12} md={1}>
            <IconButton type="submit" sx={{ p: "12px" }}
               aria-label="search"
                style={{backgroundColor:"#00A1F1",borderRadius:'10%'
                // position: "absolute",
                // left: "90.56%",
                // right: "3.06%",
                // top: "15%",
                // bottom: "78.75%"
            }}
                // onClick={setSearchFun}
                >
                <img src={addicon} style={{color:'#FFFFFF'}}
                 onClick={addlicense}
                 />
              </IconButton>
            </Grid>
{roleList&&roleList.map((rec,i)=>{
                
                return(
                  <Box sx={{ml:'6px'}}>

                  <Box
                    className="category-item"
                    key={rec.roleName}
                    sx={{ cursor: "pointer",ml:'12px',mt:'12px',fontWeight:'500' }}
                    // onClick={() => handleCategoryClick(category)}
                  >
                    {rec.roleName}
                    <IconButton
                aria-label="delete"
                size="small"
                data-id={rec.id}
                onClick={handleDelete}
                >
                    <img src={closeIcon} 
                    alt={rec.roleName} 
                />
                </IconButton>
                    </Box>
                    </Box>
                )
               })}
            </Grid>
            </Box>
            {open && <AddNewRole open={open} handleClose={handleClose} flag={true} orgid={orgid}/>}
        </Box>
        
        </>
    )
}