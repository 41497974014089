import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomLoader from "../common/CustomLoader";
import NoRecordFound from "../common/NoRecordFound";
import { getSorting, stableSort } from "../common/function";
import MoreIcon from "../../images/more icon.png";
import styled from "@emotion/styled";
import shareicon from "../../images/sharemail.svg";
import remove from "../../images/remove.svg";
import SharedConfirmation from "./SharedConfirmation";
import { async } from "q";
import {
  DELETE_USER_FROM_AZURE_URL,
  MAILBOX_LIST_URL,
  MAILBOX_USER_LIST_URL,
} from "../common/ApiUrl";
import { CustomMessage, authHeader, getOrgId } from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import moment from "moment";
import DeleteMailbox from "./DeleteMailbox";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.15);",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export default function IndividualList(props) {
  const { search, loading, list, getIndividualMailList, usersList } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [sharepop, setSharePop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userid, setId] = useState();
  const [utype, setType] = useState();
  const [upn, setUPN] = useState();
  const [removeFlag, setRemoveFlag] = useState(false);
  const open = Boolean(anchorEl);
  const setShownFun = (event) => {
    setAnchorEl(event.currentTarget);
    setId(event.currentTarget.dataset.id);
    setType(event.currentTarget.dataset.type);
    setUPN(event.currentTarget.dataset.upn);
  };
  const shareBoxFun = () => {
    setSharePop(!sharepop);
  };
  const handleclose = () => {
    setSharePop(false);
    setAnchorEl(null);
  };
  const removeFun = () => {
    setRemoveFlag(!removeFlag);
  };
  const handleCloseRemove = () => {
    setRemoveFlag(false);
    getIndividualMailList();
  };

  return (
    <>
      {loading ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}></Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ textAlign: "center", margin: "40px" }}
          >
            <CustomLoader loading={loading} />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
      ) : list && list.length == 0 ? (
        <NoRecordFound />
      ) : (
        <></>
      )}
      <div
        style={{ paddingLeft: "0px", paddingRight: "15px" }}
        className="scr-activity"
      >
        {!loading &&
          list &&
          stableSort(list, getSorting("asc", "DisplayName")).map((r, i) => {
            const {
              DisplayName = "",
              LastLogonTime = "",
              id = "",
              UserPrincipalName = "",
              recipienttypedetails = "",
              LastLoggedOnUserAccount = "",
              OwnerADGuid = "",
              MailboxTypeDetail = "",
            } = r;
            return (
              <Grid
                container
                spacing={1}
                style={{
                  backgroundColor: "white",
                  marginTop: "15px",
                  // marginLeft: "20px",
                  padding: "6px 20px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  height: "60px",
                }}
              >
                <Grid item xs={12} md={8} sx={{ display: "content" }}>
                  <Stack direction="row" spacing={2}>
                    <Typography
                      className="dis-name-typ"
                      style={{
                        // marginTop: "12px",
                        fontSize: "20px",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }}
                    >
                      {DisplayName || "N/A"}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography
                      className="mail-text-btn"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {UserPrincipalName || "N/A"}
                    </Typography>
                    <Typography
                      className="mail-text-btn"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {LastLogonTime
                        ? `Last Login: ${moment(new Date(LastLogonTime)).format(
                            "DD MMMM  YYYY"
                          )}`
                        : ""}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={1}
                  style={{
                    alignContent: "flex-end",
                    pr: "15px",
                    marginTop: "15px",
                  }}
                ></Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{
                    alignContent: "flex-end",
                    pr: "15px",
                    // marginTop: "15px",
                  }}
                >
                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                    {/* <Box
                    sx={{
                      // p: "3px 5px",
                      border: "1px solid #BBB5B5",
                      borderRadius: "8%",
                      textAlign: "center",
                      width: "fit-content",
                      minWidth: "80px",
                      float: "right",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography
                      className="acti-ad"
                      style={{ fontSize: "10px", fontWeight: "700",marginTop:'3px' }}
                    >
                      {recipienttypedetails}
                    </Typography>
                  </Box> */}
                    <IconButton
                      aria-label="delete"
                      id="demo-customized-menu"
                      style={{
                        backgroundColor: "none",
                        borderRadius: "10%",
                      }}
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      size="small"
                      data-id={UserPrincipalName}
                      data-type={recipienttypedetails}
                      data-upn={UserPrincipalName}
                      onClick={setShownFun}
                    >
                      <img
                        src={MoreIcon}
                        style={{ width: "16px", height: "17px" }}
                      />
                    </IconButton>
                  </Stack>
                </Grid>
                <StyledMenu
                  className="userl-popup"
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  style={{ minWidth: "50px", minHeight: "50px", margin: "5px" }}
                  onClose={handleclose}
                >
                  <MenuItem
                    disableRipple
                    onClick={shareBoxFun}
                    disabled={utype == "SharedMailbox" ? true : false}
                  >
                    <img style={{ paddingRight: "15px" }} src={shareicon} />
                    Convert as Shared Mailbox
                  </MenuItem>
                  <MenuItem disableRipple onClick={removeFun}>
                    <img style={{ paddingRight: "15px" }} src={remove} />
                    Remove
                  </MenuItem>
                </StyledMenu>
              </Grid>
            );
          })}
        {sharepop && (
          <SharedConfirmation
            open={sharepop}
            handleclose={handleclose}
            userid={userid}
            usersList={usersList}
            upn={upn}
          />
        )}
        {removeFlag && (
          <DeleteMailbox
            open={removeFlag}
            handleclose={removeFun}
            successCall={handleCloseRemove}
            id={userid}
          />
        )}
      </div>
    </>
  );
}
