import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import Navbar from "../layout/Navbar";
import ReportAddedUsersList from "./ReportAddedUsersList";
import ReportDeletedUsersList from "./ReportDeletedUsersList";
import ReportDisabledUsersList from "./ReportDisabledUsersList";
import { useEffect } from "react";
import { REPORT_LISTED_URL } from "../common/ApiUrl";
import { authHeader, getSideFlag } from "../common/mainfunctions";
import API from "../common/API";
import { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import moment from "moment/moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { useNavigate } from "react-router-dom";
import ReportUnidentifyList from "./ReportUnidentifyList";
import ReportExport from "./ReportExport";
import filicon from "../../images/fil-icon.png";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none",
        },
      },
    },
  },
});

function TabPanel({ children, value, index, other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const staticDates = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const staticMonths = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function ReportGraphIndexdev(props) {
  const [value, setValue] = React.useState(1);
  const [adduserList, setAddUserList] = useState();
  const [deluserList, setDelUserList] = useState();
  const [disuserList, setDisUserList] = useState();
  const [unidentifyList, setUndentifiedList] = useState([]);
  const [selvalue, setSelValue] = useState("weekly");
  const [reportData, setReportData] = useState([]);
  const [monthReport, setMonthReport] = useState([]);
  const [yearReport, setYearReport] = useState([]);
  const [dailyReport, setDailyReport] = useState([]);
  const [dateValue, setDateValue] = useState(null);
  const [openExport, setOpenExport] = useState(false);
  const [rtype, setRtype] = useState("added");
  const [jobCheck, setJobCheck] = useState(true);
  const [depCheck, setDepCheck] = useState(true);
  const [officeCheck, setOfficeCheck] = useState(true);
  const tok = authHeader();
  let dailyArr = [];
  const donothing = () => {};
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1) {
      setRtype("added");
    } else if (newValue == 2) {
      setRtype("deleted");
    } else if (newValue == 3) {
      setRtype("disabled");
    } else if (newValue == 4) {
      setRtype("unidentified");
    }
  };
  const [loading, setLoading] = useState(false);
  const handleDate = (e) => {
    setDateValue(e);
    setSelValue("selectDate");
  };
  const navigation = useNavigate();
  const getReportList = async () => {
    setLoading(true);
    setReportData([]);
    var dtm = moment(new Date(dateValue), "YYYY-MM-DD HH:mm:ss");
    const config = {
      method: "GET",
      url: REPORT_LISTED_URL,
      headers: authHeader(),
      params: {
        dateFilter: selvalue,
        selectDate: dateValue != null ? dtm.format("yyyy-MM-DD") : null,
        department: depCheck == true ? parseInt(1) : null,
        jobTitle: jobCheck == true ? parseInt(1) : null,
        officeLocation: officeCheck == true ? parseInt(1) : null,
      },
    };
    try {
      const response = await API(config);
      const { status, message, data } = response.data;
      if (status == "Success") {
        console.log("dta", data);
        const {
          addedUsersList,
          disabledUsersList,
          deletedUsersList,
          addedUserWeeklyDateCount,
          disabledUserWeeklyDateCount,
          deletedUserWeeklyDateCount,
          addedUsersMonthGroupList,
          disabledUsersMonthGroupList,
          deletedUsersMonthGroupList,
          addedUsersYearlyGroupList,
          disabledUsersYearlyGroupList,
          deletedUsersYearlyGroupList,
          unIdentifiedUsersList,
          unIdentifiedUserWeeklyDateCount,
          unIdentifiedUsersMonthGroupList,
          unIdentifiedUsersYearlyGroupList,
        } = data;
        console.log("dta1", addedUsersList);

        if (selvalue == "weekly") {
          addedUserWeeklyDateCount &&
            addedUserWeeklyDateCount.map((re, ad) => {
              return (re.type = "Added Users");
            });
          disabledUserWeeklyDateCount &&
            disabledUserWeeklyDateCount.map((dis, i) => {
              return (dis.type = "Disabled Users");
            });
          deletedUserWeeklyDateCount &&
            deletedUserWeeklyDateCount.map((del, index) => {
              return (del.type = "Deleted Users");
            });
          unIdentifiedUserWeeklyDateCount &&
            unIdentifiedUserWeeklyDateCount.map((un) => {
              return un.type == "Unidentified Users";
            });
          Array.prototype.push.apply(
            addedUserWeeklyDateCount,
            deletedUserWeeklyDateCount
          );
          Array.prototype.push.apply(
            addedUserWeeklyDateCount,
            disabledUserWeeklyDateCount
          );
          Array.prototype.push.apply(
            addedUserWeeklyDateCount,
            unIdentifiedUserWeeklyDateCount
          );
          if (addedUserWeeklyDateCount) {
            addedUserWeeklyDateCount &&
              addedUserWeeklyDateCount.map((r, i) => {
                var dt = moment(r.date, "YYYY-MM-DD HH:mm:ss");

                return (r.date = dt.format("dddd"));
              });
            setReportData(addedUserWeeklyDateCount);
          } else {
            setReportData([]);
          }
        }
        if (selvalue == "monthly") {
          addedUsersMonthGroupList &&
            addedUsersMonthGroupList.map((mau) => {
              return (mau.type = "Added Users");
            });

          disabledUsersMonthGroupList &&
            disabledUsersMonthGroupList.map((dis, i) => {
              return (dis.type = "Disabled Users");
            });

          deletedUsersMonthGroupList &&
            deletedUsersMonthGroupList.map((del, index) => {
              return (del.type = "Deleted Users");
            });
          unIdentifiedUsersMonthGroupList &&
            unIdentifiedUsersMonthGroupList.map((un) => {
              return (un.type = "Unidentified Users");
            });
          // monthly
          Array.prototype.push.apply(
            addedUsersMonthGroupList,
            deletedUsersMonthGroupList
          );
          Array.prototype.push.apply(
            addedUsersMonthGroupList,
            disabledUsersMonthGroupList
          );
          Array.prototype.push.apply(
            addedUsersMonthGroupList,
            unIdentifiedUsersMonthGroupList
          );
          // monthly
          if (addedUsersMonthGroupList) {
            addedUsersMonthGroupList &&
              addedUsersMonthGroupList.map((r, i) => {
                var dt = staticMonths[parseInt(r.monthNumber)];
                return (r.date = dt);
              });
            setMonthReport(addedUsersMonthGroupList);
          } else {
            setMonthReport([]);
          }
        }
        if (selvalue == "yearly") {
          addedUsersYearlyGroupList &&
            addedUsersYearlyGroupList.map((re, ad) => {
              return (re.type = "Added Users");
            });
          disabledUsersYearlyGroupList &&
            disabledUsersYearlyGroupList.map((dis, i) => {
              return (dis.type = "Disabled Users");
            });
          deletedUsersYearlyGroupList &&
            deletedUsersYearlyGroupList.map((del, index) => {
              return (del.type = "Deleted Users");
            });
          unIdentifiedUsersYearlyGroupList &&
            unIdentifiedUsersYearlyGroupList.map((un) => {
              return (un.type = "Unidentified Users");
            });
          Array.prototype.push.apply(
            addedUsersYearlyGroupList,
            deletedUsersYearlyGroupList
          );
          Array.prototype.push.apply(
            addedUsersYearlyGroupList,
            disabledUsersYearlyGroupList
          );
          Array.prototype.push.apply(
            addedUsersYearlyGroupList,
            unIdentifiedUsersYearlyGroupList
          );
          if (addedUsersYearlyGroupList) {
            setYearReport(addedUsersYearlyGroupList);
          } else {
            setYearReport([]);
          }
          console.log("old user", addedUsersList);
          const newAddusersList = addedUsersList.map((item) => {
            const {
              DisplayName: displayName,
              CreatedDateTime: createdDateTime,
              DeletedDateTime: deletedDateTime,
              UserPrincipalName: userPrincipalName,

              ...rest
            } = item;
            return {
              displayName,
              createdDateTime,
              deletedDateTime,
              userPrincipalName,
              ...rest,
            };
          });
          setAddUserList(newAddusersList);
          console.log("newadd", newAddusersList);
          const newdelusersList = deletedUsersList.map((item) => {
            const {
              DisplayName: displayName,
              CreatedDateTime: createdDateTime,
              DeletedDateTime: deletedDateTime,
              UserPrincipalName: userPrincipalName,
              ...rest
            } = item;
            return {
              displayName,
              createdDateTime,
              deletedDateTime,
              userPrincipalName,
              ...rest,
            };
          });
          setDelUserList(newdelusersList);
          const newdisusersList = disabledUsersList.map((item) => {
            const {
              DisplayName: displayName,
              CreatedDateTime: createdDateTime,
              DeletedDateTime: deletedDateTime,
              UserPrincipalName: userPrincipalName,
              ...rest
            } = item;
            return {
              displayName,
              createdDateTime,
              deletedDateTime,
              userPrincipalName,
              ...rest,
            };
          });
          setDisUserList(newdisusersList);
          const newunidentifyusersList = unIdentifiedUsersList.map((item) => {
            const {
              DisplayName: displayName,
              CreatedDateTime: createdDateTime,
              DeletedDateTime: deletedDateTime,
              UserPrincipalName: userPrincipalName,
              ...rest
            } = item;
            return {
              displayName,
              createdDateTime,
              deletedDateTime,
              userPrincipalName,
              ...rest,
            };
          });
          setUndentifiedList(newunidentifyusersList);
        }

        if (selvalue == "daily" || selvalue == "selectDate") {
          var dtm = moment(new Date(dateValue), "YYYY-MM-DD HH:mm:ss");

          let dailyList = addedUsersList;
          if (addedUsersList) {
            dailyArr.push({
              date: dtm.format("dddd"),
              data: addedUsersList.length,
              type: "Added Users",
            });
            setDailyReport(dailyArr);
          }
          if (deletedUsersList.length > 0) {
            dailyArr.push({
              date: dtm.format("dddd"),
              data: deletedUsersList.length,
              type: "Deleted Users",
            });
            setDailyReport(dailyArr);
          }

          if (disabledUsersList.length > 0) {
            dailyArr.push({
              date: dtm.format("dddd"),
              data: disabledUsersList.length,
              type: "Disabled Users",
            });
            setDailyReport(dailyArr);
          }
          if (unIdentifiedUsersList.length > 0) {
            dailyArr.push({
              date: dtm.format("dddd"),
              data: unIdentifiedUsersList.length,
              type: "Unidentified Users",
            });
            setDailyReport(dailyArr);
          }
        }
        const newAddusersList = addedUsersList.map((item) => {
          const {
            DisplayName: displayName,
            CreatedDateTime: createdDateTime,
            DeletedDateTime: deletedDateTime,
            UserPrincipalName: userPrincipalName,

            ...rest
          } = item;
          return {
            displayName,
            createdDateTime,
            deletedDateTime,
            userPrincipalName,
            ...rest,
          };
        });
        setAddUserList(newAddusersList);
        console.log("newadd", newAddusersList);
        const newdelusersList = deletedUsersList.map((item) => {
          const {
            DisplayName: displayName,
            CreatedDateTime: createdDateTime,
            DeletedDateTime: deletedDateTime,
            UserPrincipalName: userPrincipalName,
            ...rest
          } = item;
          return {
            displayName,
            createdDateTime,
            deletedDateTime,
            userPrincipalName,
            ...rest,
          };
        });
        setDelUserList(newdelusersList);
        const newdisusersList = disabledUsersList.map((item) => {
          const {
            DisplayName: displayName,
            CreatedDateTime: createdDateTime,
            DeletedDateTime: deletedDateTime,
            UserPrincipalName: userPrincipalName,
            ...rest
          } = item;
          return {
            displayName,
            createdDateTime,
            deletedDateTime,
            userPrincipalName,
            ...rest,
          };
        });
        setDisUserList(newdisusersList);
        const newunidentifyusersList = unIdentifiedUsersList.map((item) => {
          const {
            DisplayName: displayName,
            CreatedDateTime: createdDateTime,
            DeletedDateTime: deletedDateTime,
            UserPrincipalName: userPrincipalName,
            ...rest
          } = item;
          return {
            displayName,
            createdDateTime,
            deletedDateTime,
            userPrincipalName,
            ...rest,
          };
        });
        setUndentifiedList(newunidentifyusersList);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getReportList();
  }, [selvalue, dateValue, jobCheck, depCheck, officeCheck]);
  const handleFilterValue = (e) => {
    setSelValue(e.currentTarget.dataset.filvalue);
    if (e.currentTarget.dataset.filvalue == "daily") {
      setDateValue(new Date());
    } else {
      setDateValue(null);
    }
  };

  // weekly

  const data = reportData;

  // Create a unique key for each group
  const groups = data.reduce((acc, cur) => {
    const { type, date, count } = cur;
    if (!acc[type]) {
      acc[type] = {};
    }
    acc[type][date] = count;
    return acc;
  }, {});
  // Convert the grouped data into an array for the chart
  const chartData = staticDates.map((date) => {
    const dataPoint = { name: date };
    Object.keys(groups).forEach((type) => {
      dataPoint[type] = groups[type][date] || 0;
    });
    return dataPoint;
  });

  // Get all unique types for the Legend
  const types = [...new Set(data.map((entry) => entry.type))];
  //  monthly
  const monthdata = monthReport;

  const monthgroup = monthdata.reduce((acc, cur) => {
    const { type, date, data } = cur;
    if (!acc[type]) {
      acc[type] = {};
    }
    acc[type][date] = data;
    return acc;
  }, {});
  // Convert the grouped data into an array for the chart
  const monthchartData = staticMonths
    .filter((date) => date !== "")
    .map((date) => {
      const dataPoint = { name: date };
      Object.keys(monthgroup).forEach((type) => {
        dataPoint[type] = monthgroup[type][date] || 0;
      });
      return dataPoint;
    });

  // Get all unique types for the Legend
  const monthtypes = [...new Set(monthdata.map((entry) => entry.type))];
  //  yearly
  const yearData = yearReport;

  const yearGroup = yearData.reduce((acc, cur) => {
    const { type, year, data } = cur;
    if (!acc[type]) {
      acc[type] = {};
    }
    acc[type][year] = data;
    return acc;
  }, {});
  const yearchartData = yearData.map((date) => {
    const { type, data, year } = date;
    const dataPoint = { name: year };
    Object.keys(yearGroup).forEach((type) => {
      dataPoint[type] = yearGroup[type][year] || 0;
    });
    return dataPoint;
  });

  // Get all unique types for the Legend
  const yeartypes = [...new Set(yearData.map((entry) => entry.type))];
  const ids = yearchartData.map(({ name }) => name);
  const filtered = yearchartData.filter(
    ({ name }, index) => !ids.includes(name, index + 1)
  );
  const dailyData = dailyReport;

  const dailyGroup = dailyData.reduce((acc, cur) => {
    const { type, date, data } = cur;
    if (!acc[type]) {
      acc[type] = {};
    }
    acc[type][date] = data;
    return acc;
  }, {});
  const dailyChartData = dailyData.map((dat) => {
    const { type, data, date } = dat;
    const dataPoint = { name: date };
    Object.keys(dailyGroup).forEach((type) => {
      dataPoint[type] = dailyGroup[type][date] || 0;
    });
    return dataPoint;
  });

  // Get all unique types for the Legend
  const dailyTypes = [...new Set(dailyData.map((entry) => entry.type))];
  const ids1 = dailyChartData.map(({ name }) => name);
  const filtered1 = dailyChartData.filter(
    ({ name }, index) => !ids1.includes(name, index + 1)
  );
  useEffect(() => {
    if (!tok) {
      navigation("/signin");
    } else {
      console.log("tok", `*****${tok}`);
    }
  }, [tok]);
  const exportFun = () => {
    setOpenExport(!openExport);
  };
  const handleChkChange = (e) => {
    console.log(e.target.value);
    if (e.target.value == "Job Title") {
      setJobCheck(!jobCheck);
    } else if (e.target.value == "Department") {
      setDepCheck(!depCheck);
    } else {
      setOfficeCheck(!officeCheck);
    }
  };
  return (
    <>
      <Box sx={{ display: "-webkit-box" }}>
      {getSideFlag() == "true" ? <></> : <Navbar name="Reports" />}

        <Box component="main" sx={{ flexGrow: 1, p: 3,            
         mt: getSideFlag() === "true" ? "0px" : "15px",
}}>
          <Grid
            container
            rowSpacing={1}
            wrap="wrap"
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="chart-main"
            style={{
              backgroundColor: "#FFF",
              marginTop: "50px",
              overflow: "hidden",
              marginTop: getSideFlag()=="true"?"0px":"50px",

            }}
          >
            {/* <Box style={{ backgroundColor: "#FFF", marginTop: "50px" }}> */}
            <Grid item xs={12} md={12}>
              <Stack direction="row" justifyContent="space-between">
                <Box
                  style={{
                    textAlign: "center",
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {/* date picker */}
                  <ThemeProvider theme={theme}>
                    <div className="report-date">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          components={{
                            OpenPickerIcon: ({ className }) => (
                              <ArrowDropDownIcon
                                className={className}
                                style={{ margin: "-8px" }}
                              />
                            ),
                          }}
                          value={dateValue}
                          maxDate={new Date()}
                          onChange={handleDate}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  border: "none",
                                },
                              }}
                              sx={{
                                width: "130px !important",
                                mr: 2,
                                background: "#FFFFFF !important",
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "Select date",
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </ThemeProvider>
                </Box>

                <Stack direction="row">
                  <IconButton data-filvalue="daily" onClick={handleFilterValue}>
                    <Typography
                      className={
                        selvalue == "daily"
                          ? "report-button-text"
                          : "report-button-unselect"
                      }
                    >
                      Daily
                    </Typography>
                  </IconButton>
                  <IconButton
                    data-filvalue="weekly"
                    onClick={handleFilterValue}
                  >
                    <Typography
                      className={
                        selvalue == "weekly"
                          ? "report-button-text"
                          : "report-button-unselect"
                      }
                    >
                      Weekly
                    </Typography>
                  </IconButton>
                  <IconButton
                    data-filvalue="monthly"
                    onClick={handleFilterValue}
                  >
                    <Typography
                      className={
                        selvalue == "monthly"
                          ? "report-button-text"
                          : "report-button-unselect"
                      }
                    >
                      Monthly
                    </Typography>
                  </IconButton>
                  <IconButton
                    data-filvalue="yearly"
                    onClick={handleFilterValue}
                  >
                    <Typography
                      className={
                        selvalue == "yearly"
                          ? "report-button-text"
                          : "report-button-unselect"
                      }
                    >
                      Yearly
                    </Typography>
                  </IconButton>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              {selvalue == "daily" || selvalue == "selectDate" ? (
                !loading && dailyReport && dailyReport.length > 0 ? (
                  <>
                    <ResponsiveContainer width="100%" height="30%">
                      <BarChart
                        data={filtered1}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        barSize={20}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        {console.log("type", dailyTypes)}

                        {dailyTypes.map((type) => (
                          <Bar
                            key={type}
                            dataKey={type}
                            name={type}
                            fill={
                              type === "Added Users"
                                ? "#7CBB00"
                                : type == "Deleted Users"
                                ? "#F65314"
                                : type == "Undefined Users"
                                ? "#BC00FF"
                                : "#FFAB00"
                            }
                          />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                    <Stack
                      direction="row"
                      justifyContent="left"
                      spacing={2}
                      style={{ marginLeft: "50px" }}
                    >
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                          sx={{ marginBottom: "10px" }}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#7CBB00",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#7CBB00" }}
                          >
                            Added Users
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#F65314",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#F65314" }}
                          >
                            Deleted Users
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#ffab00",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#ffab00" }}
                          >
                            Disabled Users
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#bc00ff",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#bc00ff" }}
                          >
                            Unidentified Users
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </>
                ) : (
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "1000px",
                      height: "300px",
                    }}
                  >
                    <Typography>No Data</Typography>
                  </Box>
                )
              ) : (
                <></>
              )}
              {selvalue == "weekly" ? (
                !loading && reportData && reportData.length > 0 ? (
                  <>
                    <ResponsiveContainer width="100%" height="30%">
                      <BarChart
                        data={chartData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        barSize={20}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        {types.map((type) => (
                          <Bar
                            key={type}
                            dataKey={type}
                            name={type}
                            fill={
                              type === "Added Users"
                                ? "#7CBB00"
                                : type == "Deleted Users"
                                ? "#F65314"
                                : type == "Unidentified Users"
                                ? "#bc00ff"
                                : "#FFAB00"
                            }
                          />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                    <Stack
                      direction="row"
                      justifyContent="left"
                      spacing={2}
                      style={{ marginLeft: "50px" }}
                    >
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                          sx={{ marginBottom: "10px" }}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#7CBB00",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#7CBB00" }}
                          >
                            Added Users
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#F65314",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#F65314" }}
                          >
                            Deleted Users
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#FFAB00",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#FFAB00" }}
                          >
                            Disabled Users
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#BC00FF",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#BC00FF" }}
                          >
                            Unidentified Users
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </>
                ) : (
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "1000px",
                      height: "300px",
                    }}
                  >
                    <Typography>No Data</Typography>
                  </Box>
                )
              ) : (
                <></>
              )}
              {/* month report */}
              {selvalue == "monthly" ? (
                !loading && monthReport && monthReport.length > 0 ? (
                  <>
                    <ResponsiveContainer width="100%" height="30%">
                      <BarChart
                        data={monthchartData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        barSize={20}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        {monthtypes.map((type) => (
                          <Bar
                            key={type}
                            dataKey={type}
                            name={type}
                            fill={
                              type === "Added Users"
                                ? "#7CBB00"
                                : type == "Deleted Users"
                                ? "#F65314"
                                : type == "Unidentified Users"
                                ? "#bc00ff"
                                : "#FFAB00"
                            }
                          />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                    <Stack
                      direction="row"
                      justifyContent="left"
                      spacing={2}
                      style={{ marginLeft: "50px" }}
                    >
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                          sx={{ marginBottom: "10px" }}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#7CBB00",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#7CBB00" }}
                          >
                            Added Users
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#F65314",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#F65314" }}
                          >
                            Deleted Users
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#FFAB00",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#FFAB00" }}
                          >
                            Disabled Users
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#bc00ff",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#bc00ff" }}
                          >
                            Unidentified Users
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </>
                ) : (
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "1000px",
                      height: "300px",
                    }}
                  >
                    <Typography>No Data</Typography>
                  </Box>
                )
              ) : (
                <></>
              )}
              {selvalue == "yearly" ? (
                !loading && yearReport && yearReport.length > 0 ? (
                  <>
                    <ResponsiveContainer width="100%" height="30%">
                      <BarChart
                        data={filtered}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        barSize={20}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        {console.log("yeartypes", yeartypes)}
                        {yeartypes.map((type) => (
                          <Bar
                            key={type}
                            dataKey={type}
                            name={type}
                            fill={
                              type === "Added Users"
                                ? "#7CBB00"
                                : type == "Deleted Users"
                                ? "#F65314"
                                : type == "Unidentified Users"
                                ? "#bc00ff"
                                : "#FFAB00"
                            }
                          />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                    <Stack
                      direction="row"
                      justifyContent="left"
                      spacing={2}
                      style={{ marginLeft: "50px" }}
                    >
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                          sx={{ marginBottom: "10px" }}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#7CBB00",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#7CBB00" }}
                          >
                            Added Users
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#F65314",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#F65314" }}
                          >
                            Deleted Users
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#FFAB00",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#FFAB00" }}
                          >
                            Disabled Users
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Box
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "#bc00ff",
                              width: "16px",
                              height: "16px",
                              margin: "auto",
                            }}
                          ></Box>
                          <Typography
                            className="report-legend-text"
                            style={{ color: "#bc00ff" }}
                          >
                            Unidentified Users
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </>
                ) : (
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "1000px",
                      height: "300px",
                    }}
                  >
                    <Typography>No Data</Typography>
                  </Box>
                )
              ) : (
                <></>
              )}
            </Grid>
            {/* </Box> */}
          </Grid>

          <Box sx={{ width: "100%", mt: "20px" }}>
            <Stack direction="row" justifyContent="space-between">
              <Tabs
                className="tabs"
                value={value}
                onChange={handleChange}
                aria-label="secondary tabs example"
              >
                <Tab value={1} label="ADDED USERS" />
                <Tab value={2} label="DELETED USERS" />
                <Tab value={3} label="DISABLED USERS" />
                <Tab value={4} label="UNIDENTIFIED USERS" />
              </Tabs>

              <Typography
                className="export-btn"
                onClick={!loading ? exportFun : donothing}
                disabled={loading}
              >
                Export
              </Typography>
            </Stack>

            <TabPanel value={1} index={value}>
              <ReportAddedUsersList
                addedUsersList={adduserList}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value={2} index={value}>
              <ReportDeletedUsersList
                deletedUsersList={deluserList}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value={3} index={value}>
              <ReportDisabledUsersList
                disabledUsersList={disuserList}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value={4} index={value}>
              <FormControlLabel
                style={{ marginLeft: "2px" }}
                value="Job Title"
                control={
                  <Checkbox checked={jobCheck} onChange={handleChkChange} />
                }
                label="Job Title"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Department"
                control={
                  <Checkbox checked={depCheck} onChange={handleChkChange} />
                }
                label="Department"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Office Location"
                control={
                  <Checkbox checked={officeCheck} onChange={handleChkChange} />
                }
                label="Office Location"
                labelPlacement="end"
              />
              <ReportUnidentifyList
                unidentifyList={unidentifyList}
                loading={loading}
              />
            </TabPanel>
          </Box>
        </Box>
      </Box>
      {openExport && (
        <ReportExport
          open={openExport}
          handleclose={exportFun}
          // excelData={adduserList.concat(deluserList).concat(disuserList).concat(unidentifyList)}
          excelData={[
            ...(adduserList || []),
            ...(deluserList || []),
            ...(disuserList || []),
            ...(unidentifyList || []),
          ]}
          selvalue={selvalue}
          dateValue={dateValue}
          rtype={rtype}
          depCheck={depCheck}
          jobCheck={jobCheck}
          officeCheck={officeCheck}
        />
      )}
    </>
  );
}
