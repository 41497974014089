import * as types from "../action/ActionType";
import {  STEP_THREE_DATA_INITIAL } from "../store/InitialStore";
export default function StepThreeReducer(state = STEP_THREE_DATA_INITIAL, action) {
  const { type, payload } = action;
  switch (type) {
    case types.STEP_THREE_DATA_TYPE:
      return {
        ...state,
        arr: [...state.arr, payload]
      }
      case types.STEP_THREE_DATA_TYPE_DELETE:
        return {
          ...state,
          arr:payload
        }
    default:
      return state;
  }
}
