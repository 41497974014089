import React from "react";
import Navbar from "../layout/Navbar";
import { Box } from "@mui/material";
import UsersList from "./UsersList";
import { getSideFlag } from "../common/mainfunctions";
export default function UserListIndex() {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        {getSideFlag() == "true" ? <></> : <Navbar name="Users" />}
        <Box
          component="main"
          sx={{ flexGrow: 1, mt: getSideFlag() === "true" ? "0px" : "20px" }}
        >
          <UsersList />
        </Box>
      </Box>
    </>
  );
}
