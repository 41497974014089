import * as types from "../action/ActionType";
import {  JOB_TITLE_LIST_INITIAL } from "../store/InitialStore";
export default function JobTitleReducer(state = JOB_TITLE_LIST_INITIAL, action) {
  const { type, payload } = action;
  switch (type) {
    case types.JOB_TITLE_TYPE:
      return (state = payload);
    default:
      return state;
  }
}
