import * as types from "../action/ActionType";
import {  STEP_FOUR_DATA_INITIAL } from "../store/InitialStore";
export default function StepFourReducer(state = STEP_FOUR_DATA_INITIAL, action) {
  const { type, payload } = action;
  switch (type) {
    case types.STEP_FOUR_DATA_TYPE:
      return {
        ...state,
        department: [...state.department, payload]
      }
      case types.STEP_FOUR_DATA_TYPE1:
      return {
        ...state,
        department:payload
      }
    default:
      return state;
  }
}
