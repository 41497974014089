import * as types from "../action/ActionType";
import {    SYSTEM_LICENCE_LIST_INITIAL } from "../store/InitialStore";
export default function SystemLicenseReducer(state = SYSTEM_LICENCE_LIST_INITIAL, action) {
  const { type, payload } = action;
  switch (type) {
    case types.SYSTEM_LICENCE_TYPE:
      return (state = payload);
    default:
      return state;
  }
}
