import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Box,
  FormControl,
  FormHelperText,
  InputBase,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormik } from "formik";
import {
  GET_LICENCE_LIST_URL,
  LICENSE_ADD_FOR_ORGANIZATION,
  PRODUCT_ID_PRICE_API_URL,
} from "../common/ApiUrl";
import { CustomMessage, authHeader } from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { STEP_TWO_DATA_TYPE } from "../../redux/action/ActionType";
import windowicon from "../../images/licenseim.png";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import CustomLoader from "../common/CustomLoader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const BootstrapInputSel = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const initialValues = {
  productName: "productName",
  productId: "",
  productPrice: "",
  priceType: "priceType",
  quantity: "",
};
export default function AddNewLicense(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { open, handleClose, gid, flag = false, orgid, licensData } = props;
  const [skuid, setSkuId] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const addLicense = async (values) => {
    if (flag) {
      if (values.productName == "productName" || values.productName == "") {
        CustomMessage(
          "Missing required parameters. Please provide all necessary information.",
          "error",
          enqueueSnackbar
        );
      } else {
        setLoading(true);
        console.log(values.productName, licensData);
        const {
          organizationId,
          productName,
          productId,
          productPrice,
          priceType,
          quantity,
        } = values;
        let liskuid =
          licensData &&
          licensData.filter((r) => r.altVendorSku == productName)[0]
            .altVendorSku;
        let pname =
          licensData &&
          licensData.filter((r) => r.altVendorSku == productName)[0]
            .productName;
        let ld =
          licensData &&
          licensData.filter((r) => r.altVendorSku == productName)[0]
            .licenseDetails;
        console.log("pname", pname, ld.id);
        const config = {
          method: "POST",
          url: LICENSE_ADD_FOR_ORGANIZATION,
          headers: authHeader(),
          data: {
            organizationId: parseInt(orgid),
            productName: pname,
            productId: productId,
            productPrice: JSON.stringify(productPrice),
            priceType: priceType,
            quantity: JSON.stringify(quantity),
            licenseId: ld.id,
            licenseSkuId: liskuid,
          },
        };
        try {
          const response = await API(config);
          const { status, message } = response.data;
          if (status == "Success") {
            CustomMessage(message, "success", enqueueSnackbar);
            handleClose();
          }
        } catch (error) {
          console.log(error);
          if (error.response) {
            const { message } = error.response.data;
            CustomMessage(message, "error", enqueueSnackbar);
          }
        } finally {
          setLoading(false);
        }
      }
    } else {
      dispatch({
        type: STEP_TWO_DATA_TYPE,
        payload: values,
      });
      handleClose();
    }
  };
  const validationSchema = Yup.object().shape({
    productName: Yup.string().test(
      "productName",
      "Select a product",
      (value) => value != "productName"
    ),
  });
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      addLicense(values);
    },
  });

  const handleChangeFun = (e) => {
    console.log(e.target.value);
    let pid = licensData.filter((r) => r.altVendorSku == e.target.value)[0]
      .productId;
    let skuid = licensData.filter((r) => r.altVendorSku == e.target.value)[0]
      .altVendorSku;
    setSkuId(skuid);
    setValues({ ...values, productId: pid });
    handleChange(e);
  };

  const getProductPrice = async () => {
    const config = {
      method: "GET",
      url: PRODUCT_ID_PRICE_API_URL,
      headers: authHeader(),
      params: {
        licenseSkuId: skuid,
      },
    };
    try {
      const response = await API(config);
      const { status, message, data } = response.data;

      if (status == "Success") {
        const { content } = data.subscriptionDetails;
        const [{ quantity, billingTerm, price }] = content;
        setValues({
          ...values,
          productPrice: price,
          priceType: billingTerm,
          quantity: quantity,
        });
      }
    } catch (error) {
      console.error(error);
      if (error) {
        const { message } = error.response.data;
        CustomMessage(message, "error", enqueueSnackbar);
      }
    }
  };
  useEffect(() => {
    if (skuid) {
      // getProductPrice();
      console.log("skuid", skuid);
      let ld =
        licensData && licensData.filter((r) => r.altVendorSku == skuid)[0];
      const { price, quantity, billingTerm } = ld;
      console.log(ld, "shk");
      setValues({
        ...values,
        productPrice: price,
        priceType: billingTerm,
        quantity: quantity,
      });
    }
  }, [skuid]);
  console.log("error", errors);
  return (
    <div>
      <BootstrapDialog
        className="org-popup"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box style={{ textAlign: "center" }}>
            <img src={windowicon} />
          </Box>
          <Box style={{ textAlign: "center" }}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                alignContent: "center",
                marginBottom: "8px",
              }}
            >
              Add New Windows License
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <FormControl
              style={{ marginTop: "10px" }}
              sx={{ width: "100%", minWidth: "500px" }}
              variant="standard"
            >
              <BootstrapInputSel
                labelId="productName"
                id="productName"
                value={values.productName}
                label="productName"
                name="productName"
                placeholder="Product Name"
                onChange={handleChangeFun}
              >
                <MenuItem selected={true} disabled={true} value="productName">
                  Product Name
                </MenuItem>
                {licensData &&
                  licensData.map((license, i) => {
                    const {
                      skuPartNumber,
                      skuId,
                      servicePlans = [],
                      productName,
                      licenseDetails,
                      altVendorSku,
                    } = license;
                    console.log("licesnseddd", licenseDetails);
                    return (
                      <MenuItem data-skuid={altVendorSku} value={altVendorSku}>
                        {productName}
                      </MenuItem>
                    );
                  })}
              </BootstrapInputSel>
              <FormHelperText error={true}>
                {errors.productName && touched.productName
                  ? errors.productName
                  : ""}
              </FormHelperText>
            </FormControl>
            <FormControl
              style={{ marginTop: "10px" }}
              sx={{ width: "100%", minWidth: "500px" }}
              variant="standard"
            >
              <BootstrapInput
                labelId="productId"
                id="productId"
                value={values.productId}
                label="productId"
                name="productId"
                placeholder="Product ID"
                // onChange={handleChange}
                disabled
              ></BootstrapInput>
            </FormControl>
            <FormControl
              style={{ marginTop: "10px" }}
              sx={{ width: "100%", minWidth: "500px" }}
              variant="standard"
            >
              <BootstrapInput
                labelId="productPrice"
                id="productPrice"
                value={values.productPrice}
                label="productPrice"
                name="productPrice"
                placeholder="Product Price (USD)"
                onChange={handleChange}
                disabled
              ></BootstrapInput>
            </FormControl>
            <FormControl
              style={{ marginTop: "10px" }}
              sx={{ width: "100%", minWidth: "500px" }}
              variant="standard"
            >
              <BootstrapInput
                labelId="priceType"
                id="priceType"
                value={values.priceType}
                label="priceType"
                name="priceType"
                placeholder="Price Type"
                onChange={handleChange}
                disabled
              ></BootstrapInput>
            </FormControl>
            <FormControl
              style={{ marginTop: "10px" }}
              sx={{ width: "100%", minWidth: "500px" }}
              variant="standard"
            >
              <BootstrapInput
                labelId="quantity"
                id="quantity"
                value={values.quantity}
                label="quantity"
                name="quantity"
                placeholder="Quantity"
                onChange={handleChange}
                disabled
              ></BootstrapInput>
            </FormControl>
            <Box sx={{ textAlign: "center" }}>
              <Button
                type="submit"
                className="login-btn-group-bl"
                size="medium"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading && <CustomLoader loading={loading} />} SUBMIT
              </Button>

              <Button
                className="login-btn-group"
                size="medium"
                onClick={handleClose}
              >
                CANCEL
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
