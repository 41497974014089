import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  alpha,
} from "@mui/material";
import exportIcon from "../../images/export.svg";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CustomMessage, authHeader } from "../common/mainfunctions";
import API from "../common/API";
import { DELETE_THE_EXPORT_URL, EXPORT_THE_REPORT_URL } from "../common/ApiUrl";
import moment from "moment";
import Axios from "axios";
import { useState } from "react";
import CustomLoader from "../common/CustomLoader";
import { useSnackbar } from "notistack";

// import ReactExport from "react-data-export";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInputSel = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const initialValues = {
  emailType: "",
  downloadType: "",
  email: "",
};

const types = [
  { name: "Excel (.xls)", value: "excel" },
  { name: "PDF (.pdf)", value: "pdf" },
];
export default function ReportExport(props) {
  var fileDownload = require("js-file-download");
  const { enqueueSnackbar } = useSnackbar();

  const {
    open,
    handleclose,
    selvalue,
    dateValue = null,
    rtype,
    jobCheck,
    officeCheck,
    depCheck,
  } = props;
  const [loading, setLoading] = useState(false);
  const [fp, setFPath] = useState();
  const [fileN, setFileName] = useState();
  const validationSchema = Yup.object()
    .shape({
      downloadType: Yup.string(),
      emailType: Yup.string(),
      email: Yup.string(),
    })
    .test({
      name: "emailRequired",
      test: function (values) {
        const { emailType, email } = values;
        if (
          emailType &&
          emailType.length > 0 &&
          (!email || email.length === 0) &&
          (emailType == "excel" || emailType == "pdf")
        ) {
          return this.createError({
            path: "email",
            message: "Email is required",
          });
        }
        return true;
      },
    })
    .test({
      name: "atLeastOne",
      test: function (values) {
        const { downloadType, emailType } = values;
        if (!downloadType && (!emailType || emailType.length === 0)) {
          return this.createError({
            path: "downloadType",
            message: "At least one of email or download must have a value",
          });
        }
        return true;
      },
    });
  const deleteFileFun = async (filePath, fileName) => {
    console.log(fp, fileN);
    const config = {
      method: "DELETE",
      url: DELETE_THE_EXPORT_URL,
      headers: authHeader(),
      params: {
        fileName: fileName,
        filePath: filePath,
      },
    };
    try {
      const res = await API(config);
    } catch (error) {
      console.error(error);
    } finally {
      handleclose();
    }
  };
  const downloadFile = (filePath) => {
    // Create a link element
    const link = document.createElement("a");

    // Set the href attribute to the file path
    link.href = filePath;

    // Set the download attribute with the desired file name
    link.download = "downloaded_file.pdf";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  const exportFun = async (values) => {
    setLoading(true);
    const { downloadType, emailType, email } = values;
    console.log("dem", downloadType, emailType);
    let sendType = "";

    if (downloadType == "excel" || downloadType == "pdf") {
      sendType = "download";
    }
    if (emailType == "excel" || emailType == "pdf") {
      sendType = "email";
    }

    var dtm = null;
    if (dateValue != null) {
      dtm = moment(new Date(dateValue), "YYYY-MM-DD HH:mm:ss");
    }
    const config = {
      method: "GET",
      url: EXPORT_THE_REPORT_URL,
      headers: authHeader(),
      params: {
        dateFilter: selvalue,
        selectDate: dtm != null ? dtm.format("yyyy-MM-DD") : null,
        reportType: rtype,
        sendEmail: email ? email : null,
        fileType: sendType == "email" ? emailType : downloadType,
        sendType: sendType,
        department: depCheck == true ? parseInt(1) : null,
        jobTitle: jobCheck == true ? parseInt(1) : null,
        officeLocation: officeCheck == true ? parseInt(1) : null,
      },
    };
    try {
      const response = await API(config);
      console.log(response);
      const { status, data } = response.data;
      if (status == "Success") {
        const { filePath, fileName } = data;
        setFPath(filePath);
        setFileName(fileName);
        //  downloadFile(filePath)
        if (sendType == "download") {
          window.open(filePath);
          setTimeout(() => {
            deleteFileFun(filePath, fileName);
          }, 1000);
        } else {
          CustomMessage("Report sent successfully", "success", enqueueSnackbar);
          deleteFileFun(filePath, fileName);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      exportFun(values);
    },
  });
  console.log("values", values);
  return (
    <>
      <div>
        <BootstrapDialog
          className="rq-user-popup org-popup "
          onClose={handleclose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleclose}
          ></BootstrapDialogTitle>
          <DialogContent>
            <Box style={{ textAlign: "center" }}>
              <img src={exportIcon} />
            </Box>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              Export
            </Typography>
            <form onSubmit={handleSubmit}>
              <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "10px",
                  mt: "10px",
                }}
              >
                Share via Email or download
              </InputLabel>
              <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                Share via Email
              </InputLabel>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <BootstrapInputSel
                  labelId="emailType"
                  id="emailType"
                  value={values.emailType || "Select Type"}
                  label="emailType"
                  name="emailType"
                  onChange={handleChange}
                  placeholder="Select Type"
                  //   data-fname="domain"
                  disabled={
                    values.downloadType == "Select Type" ||
                    values.downloadType == ""
                      ? false
                      : true
                  }
                >
                  <MenuItem selected value="Select Type">
                    Select Type
                  </MenuItem>
                  {types.map((rec, i) => {
                    return (
                      <MenuItem key={i} name={rec.name} value={rec.value}>
                        {rec.name}
                      </MenuItem>
                    );
                  })}
                </BootstrapInputSel>
                {/* <FormHelperText error={true}>
                  {errors.domain && touched.domain ? errors.domain : ""}
                </FormHelperText> */}
              </FormControl>
              <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                To Email Address
              </InputLabel>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <BootstrapInput
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Enter the email address"
                  disabled={
                    values.downloadType == "Select Type" ||
                    values.downloadType == ""
                      ? false
                      : true
                  }
                />
                <FormHelperText error={true}>
                  {errors.email && touched.email ? errors.email : ""}
                </FormHelperText>
              </FormControl>
              <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                Download As
              </InputLabel>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <BootstrapInputSel
                  labelId="downloadType"
                  id="downloadType"
                  value={values.downloadType || "Select Type"}
                  label="downloadType"
                  name="downloadType"
                  onChange={handleChange}
                  placeholder="Select Type"
                  disabled={
                    values.emailType == "Select Type" || values.emailType == ""
                      ? false
                      : true
                  }
                >
                  <MenuItem selected value="Select Type">
                    Select Type
                  </MenuItem>
                  {types.map((rec, i) => {
                    return (
                      <MenuItem key={i} name={rec.name} value={rec.value}>
                        {rec.name}
                      </MenuItem>
                    );
                  })}
                </BootstrapInputSel>
                <FormHelperText error={true}>
                  {errors.downloadType && touched.downloadType
                    ? errors.downloadType
                    : ""}
                </FormHelperText>
              </FormControl>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  className="login-btn-group-bl"
                  size="medium"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  <CustomLoader loading={loading} />
                  SUBMIT
                </Button>

                <Button
                  className="login-btn-group"
                  size="medium"
                  onClick={handleclose}
                >
                  CANCEL
                </Button>
              </Box>
            </form>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
}
