import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { alpha,styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import APIcon from "../../images/icon api.png"
import Bitmap from "../../images/Bitmap.png"
import { Box, FormControl, FormControlLabel, FormHelperText, Input, InputAdornment, InputBase, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import MailIcon from "../../images/mail.svg"
import PassIcon from "../../images/password.svg"
import { useFormik } from "formik";
import { ADD_NEW_GROUP_URL, ADD_NEW_USER_URL, ADD_ROLE_URL, ADD_USER_TO_GROUP, AZURE_CONNECT_URL, DELETE_WORKFLOW_URL, VERIFY_AZURE_URL } from '../common/ApiUrl';
import { CustomMessage, authHeader } from '../common/mainfunctions';
import API from '../common/API';
import { useSnackbar } from 'notistack';
import { getGroupList, getUserDetails } from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import conicon from "../../images/icon confirmation.png"
import { STEP_FIVE_DATA_TYPE } from '../../redux/action/ActionType';
import { Label } from '@mui/icons-material';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
      position: "relative",
      backgroundColor: "#F8F8F8",
      //border: "2px solid #CFCFCF",
      fontSize: 18,
      width: "100%",
      padding: "18px 20px",
      color: "#343434",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const BootstrapInputSel = styled(Select)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
      position: "relative",
      backgroundColor: "#F8F8F8",
      //border: "2px solid #CFCFCF",
      fontSize: 18,
      width: "100%",
      padding: "18px 20px",
      color: "#343434",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  const initialValues={
   roles:''
  }
export default function DeleteWorkFlowPopup(props){
    // const [open, setOpen] = React.useState(false);
    const ulist=useSelector((ul)=>ul.UserListReducer)
    const glist=useSelector((gl)=>gl.GroupListReducer)
    const {enqueueSnackbar}=useSnackbar()
    const {open,handleClose,gid,orgid,flag,id,successcall}=props
const dispatch=useDispatch()

   
    const deleteWorkflowFun=async(values)=>{
        const config = {
          method: "DELETE",
          url: DELETE_WORKFLOW_URL,
         headers:authHeader(),
         data:{
          workflowId:parseInt(id),
         }
        };
        try {
          const response = await API(config);
          const {status,message}=response.data
          if(status=="Success"){
      CustomMessage(message, "success", enqueueSnackbar);
     successcall()

          }
        } catch (error) {
          const {data}=error.response
          const {message,status}=data
          CustomMessage(message,status, enqueueSnackbar);

        } 
     
    }
    const {
      handleSubmit,
      handleChange,
      setValues,
      values,
      errors,
      setErrors,
      touched,
    } = useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      // validationSchema: validationSchema,
      onSubmit: (values) =>{
        deleteWorkflowFun(values)
    
      }

    });
   
    return(
        <div
        style={{
            maxWidth:"500px !important",
height:"670px"
        }}>
        
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            
          </BootstrapDialogTitle>
          <DialogContent >
            <Box sx={{width:'500px',height:'300px',borderRadius:'20px'}}>
            <Box style={{textAlign:'center'}}>
            <img src={conicon}/>

            </Box>
            <Box style={{textAlign:'center'}}>

            <Typography style={{fontWeight:'700',
                                fontSize:'16px',
                                alignContent:'center',
                                }}      >
             Are you sure you want to delete this workflow?
            </Typography>
</Box>
            <form onSubmit={handleSubmit}>
          
           <Box sx={{textAlign:'center'}}>
            <Button type="submit" className="login-btn-group-bl" size="medium" 
       
            onClick={handleSubmit}
            >
                CONFIRM
              </Button>
              <Button  className="login-btn-group" size="medium" 
         
            onClick={handleClose}
            >
                CANCEL
              </Button>
           </Box>

            </form>
</Box>
          </DialogContent>
          
        </BootstrapDialog>
      </div>
    )
}