import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
} from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { DEVICE_LIST_URL } from "../common/ApiUrl";
import { authHeader, getOrgId, getSideFlag } from "../common/mainfunctions";
import API from "../common/API";
import filtericon from "../../images/filter-new.png";
import clrfilter from "../../images/clearfilter.svg";
import SearchIcon from "@mui/icons-material/Search";
import boxicon from "../../images/boxicon.png";
import DeviceFilter from "./DeviceFilter";
import DeviceWipeConfirm from "./DeviceWipeConfirm";
import WipeConfirm from "./WipeConfirm";
import CustomLoader from "../common/CustomLoader";
import styled from "@emotion/styled";
import DeviceRestart from "./DeviceRestart";
import moment from "moment";
import { debounce } from "underscore";
import { DataGrid } from "@mui/x-data-grid";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.15);",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid green`, // Green border for checkboxes
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "green", // Green background color when checked
      borderColor: "green", // Green border color when checked
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "green", // Green background color for indeterminate state
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "firstName", headerName: "First name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 90,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  },
];

const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

const initialValues = {
  deviceType: "",
  fromDate: "",
  toDate: "",
  compliantState: "",
};
const DeviceList = () => {
  const [loading, setLoading] = useState(false);
  const [deviceData, setDeviceData] = useState([
    // { id: 1, deviceType: "windows" },
    // { id: 2, deviceType: "windows" },
    // { id: 3, deviceType: "windows" },
    // { id: 4, deviceType: "windows" },
    // { id: 5, deviceType: "windows" },
    // { id: 6, deviceType: "windows" },
  ]);
  const [selectAll, setSelectAll] = useState(false);
  const [dum, setDum] = useState(false);
  const [openfil, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(initialValues);
  const [search, setSearch] = useState("");
  const [removeFlag, setRemoveFlag] = useState(false);
  const [delpop, setdelpop] = useState(false);
  const [checked, setChecked] = useState([]);
  const [sids, setIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [restartFlag, setRestartFlag] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [total, setTotal] = useState(0);
  const [pageurl, setPageUrl] = useState();
  const [toFirst, settoFirst] = useState();
  const [deleteid, setDeleteId] = useState();
  let scrollRef = useRef(null);
  const scrollCall = () => {
    sessionStorage.setItem("scrollPosition", scrollRef.current.scrollTop);

    console.log("call with", toFirst, deviceData.length);
    if (toFirst > 0) {
      setPage(page + 1);
      setLoading(true);
    } else {
    }
  };
  const onScrollHandler = (e) => {
    console.log("scroll handle");
    const { target } = e;
    console.log("scroll handle1", target.scrollHeight);

    const rmHeight = target.scrollHeight - target.scrollTop;
    const clHeight = target.clientHeight;
    console.log(rmHeight, clHeight);
    if (!loading) {
      if (rmHeight === clHeight && toFirst > 0) {
        console.log("called");
        scrollCall();
      }
    }
  };
  const setShownFun = (event) => {
    setAnchorEl(event.currentTarget);
    // setDeleteId(event.currentTarget.dataset.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getDeviceList = async (searchf) => {
    setLoading(true);
    const { deviceType, fromDate, toDate, compliantState } = filter;

    const config = {
      method: "GET",
      url: DEVICE_LIST_URL,
      headers: authHeader(),
      params: {
        organizationId: getOrgId(),
        os: deviceType,
        fromDate: fromDate,
        toDate: toDate,
        compliantState: compliantState,
        search: searchf,
        pageLimit: 10,
        paginationUrl: pageurl,
      },
    };
    try {
      const response = await API(config);
      console.log("response", response.data);
      const { status, data } = response.data;
      if (status == "Success") {
        console.log("data", data, data.value);
        const { "@odata.count": count, "@odata.nextLink": pageurl = "" } = data;
        console.log(pageurl, "pageurl");
        setTotal(count);
        if (count > 0 && pageurl != "") {
          settoFirst(count);
        } else {
          settoFirst(0);
        }
        if (pageurl) {
          setPageUrl(pageurl);
        } else {
          setPageUrl("");
        }
        console.log("search", searchf);
        if (searchf != "") {
          setDeviceData(data.value);
        } else {
          setDeviceData((prev) => prev.concat(data.value));
        }
        // if (deleteid) {
        //   console.log("filter");
        //   let filresult = data.value.filter((r) => r.id != deleteid);
        //   setDeviceData(filresult);
        //   console.log("fil res", filresult);
        // } else {
        //   console.log("filter else", search, count, pageurl);

        //   if (search == "" && count > 0 && pageurl != "") {
        //     if (search != "") {
        //       setDeviceData(data.value);
        //     } else {
        //       setDeviceData((prev) => prev.concat(data.value));
        //     }
        //   }
        // }
        // if (search != "") {
        //   setDeviceData(data.value);
        // }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDeviceList();
  }, []);
  useEffect(() => {
    const isAnyFilterApplied = Object.values(filter).some(
      (value) => value !== ""
    );
    if (isAnyFilterApplied || page) getDeviceList();
  }, [filter, page]);
  const selectInvoiceChange = (e) => {
    // setChkAll(false)

    const { checked: isChecked, id } = e.target;
    setChecked((prevState) => ({
      ...prevState,
      [id]: isChecked,
    }));
    if (isChecked == true) {
      setIds([...sids, { id: id }]);
    } else {
      let ch = sids.filter((e) => e.id != id);
      setIds(ch);
    }
    const areAllUnchecked = Object.values(isChecked).every((value) => !value);
    console.log("sids", sids.length);
    if (sids.length == 1) {
      // Update selectAll state accordingly
      setSelectAll(!areAllUnchecked);
    } else if (sids.length + 1 == deviceData.length) {
      setSelectAll(true);
    }
  };
  const removeFun = () => {
    setRestartFlag(!restartFlag);
  };
  const closeWipe = () => {
    setRemoveFlag(false);
  };
  const handleCloseRemove = () => {
    setRestartFlag(false);
    setIds([]);
    setChecked([]);
    getDeviceList();
  };
  const handleCloseWipe = () => {
    setRemoveFlag(false);
    setIds([]);
    setChecked([]);
    getDeviceList();
  };
  const openFilter = () => {
    setOpenFilter(!openfil);
  };
  const clearFilter = () => {
    setFilter(initialValues);
    delaySearch(search);
  };
  const handleDelete = (e) => {
    setAnchorEl(null);
    // setdelpop(!delpop);
    setRemoveFlag(true);
  };
  const successcall = () => {
    setdelpop(false);
    setRemoveFlag(false);
    getDeviceList();
  };
  const isAnyFilterApplied = Object.values(filter).some(
    (value) => value !== ""
  );
  console.log(
    "filters",
    Object.values(filter).some((value) => console.log("val", value))
  );
  const setSearchFun = (e) => {
    setSearch(e.target.value);
    if (e.target.value == "") {
      setDeviceData([]);
    }
    delaySearch(e.target.value);
  };
  const delaySearch = useRef(
    debounce((search) => getDeviceList(search), 500)
  ).current;
  // useEffect(() => {
  //   // delaySearch(search);
  // }, [search]);
  const toggleSelectAll1 = () => {
    const allIds = deviceData.map((device) => device.id);
    const updatedChecked = {};

    if (!selectAll) {
      allIds.forEach((id) => {
        updatedChecked[id] = true;
      });
      setIds(allIds.map((id) => ({ id })));
    } else {
      setIds([]);
    }

    setChecked(updatedChecked);
    setSelectAll(!selectAll);
  };

  // useEffect(() => {
  //   const allIds = deviceData.map((device) => device.id);
  //   console.log("set", allIds);
  //   if (!selectAll) {
  //     console.log("set1", allIds, selectAll);

  //     // If selectAll is true, set all ids in checked state
  //     setIds(allIds.map((id) => ({ id })));
  //     setChecked((values) => {
  //       const updatedValues = {};
  //       allIds.forEach((id) => {
  //         updatedValues[id] = true; // or false based on your requirement
  //       });
  //       return {
  //         ...values,
  //         ...updatedValues,
  //       };
  //     });
  //   } else {
  //     setIds([]);
  //   }
  // }, [selectAll]);
  console.log("idsss", sids, checked);
  const handleViewUser = async (e) => {
    //  setUid(e.currentTarget.dataset.id);
    //  setAddUSer(false);
    //  setEditFlag(e.currentTarget.dataset.flag);
    //  setView(!viewOpen);
  };
  const handleRestart = async (e) => {
    setAnchorEl(null);

    setRestartFlag(true);
  };
  return (
    <>
      {/* <div style={{ height: 400, width: "100%", color: "#FFF" }}>
        <DataGrid
          className="chk-tab-custom"
          style={{ backgroundColor: "#FFF" }}
          rows={rows}
          columns={columns}
          rowStyle={{
            spacing: "10px",
            borderBottom: "none", // Remove border line between rows
            marginBottom: "10px", // Add margin between rows
            padding: "10px", // Optional padding for rows
          }}
          checkboxSelection
          // disableRowSelectionOnClick

          onRowSelectionModelChange={(ids) => {
            console.log("ids", ids);
            setIds(ids);
          }}
        />
      </div> */}
      <Grid
        sx={{ mt: getSideFlag() == "true" ? -2 : 2 }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      >
        <Grid item xs={12} md={6}>
          <Typography className="group-head" style={{ marginLeft: "16px" }}>
            Devices List
            <IconButton
              aria-label="delete"
              sx={{ padding: "10px" }}
              size="small"
              onClick={openFilter}
            >
              <img src={filtericon} />
            </IconButton>
            {isAnyFilterApplied && (
              <IconButton
                aria-label="delete"
                sx={{ padding: "10px" }}
                size="small"
                onClick={clearFilter}
              >
                <img src={clrfilter} />
              </IconButton>
            )}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{ display: "flex", textAlign: "right" }}
        >
          <Button
            className="login-btn-wipe approve-head-text"
            size="medium"
            style={{
              marginLeft: "10px",
            }}
            onClick={setShownFun}
          >
            <Typography className="apr-btn-text">Tools</Typography>
          </Button>
          <Paper
            //   component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "-webkit-fill-available",
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: "18px",
              height: "50px",
              boxShadow: "none !important",
            }}
          >
            <InputBase
              sx={{ flex: 1 }}
              placeholder="Search ..."
              inputProps={{ "aria-label": "   search" }}
              onChange={setSearchFun}
              value={search}
              // disabled={loading || loadingdis}
            />
            <IconButton
              type="submit"
              sx={{ p: "10px" }}
              aria-label="search"
              style={{
                backgroundColor: "#FFAB00",
                borderRadius: "10%",
                color: "#FFF",
              }}
              onClick={setSearchFun}
              // disabled={loading || loadingdis}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
      <div
        ref={scrollRef}
        onScroll={onScrollHandler}
        className="scr-activitydev"
        style={{ marginTop: "10px", marginLeft: "-8px" }}
      >
        <TableContainer
          component={Paper}
          style={{
            margin: "auto",
            width: "100%",
            backgroundColor: "#f4f4f4",
            boxShadow: "none !important",
            // overflowY: "scroll",
          }}
          aria-label="simple table"
          className="custom-row chk-tab-customTab request-table"
        >
          <Table
            sx={{
              minWidth: 650,
              border: "0px !important",
              boxShadow: "none",
              borderCollapse: "separate !important",
              borderSpacing: "0px 20px !important",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#FFF" }}>
                <TableCell sx={{ border: "0px !important" }}>
                  <Stack direction="row">
                    <FormControlLabel
                      name="exceptionRoleIdList"
                      className="dev-head"
                      control={
                        <Checkbox
                          // icon={<img src={boxicon} />}
                          checked={selectAll}
                          color="success"
                          className="device-check dev-chk"
                          onClick={toggleSelectAll1}
                        />
                      }
                    />
                    <Typography
                      className="device-list-header"
                      style={{ marginLeft: "-16px !important" }}
                    >
                      Device Name
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell sx={{ border: "0px !important" }}>
                  <Typography className="device-list-header">
                    Complaince
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "0px !important" }}>
                  <Typography className="device-list-header">Os</Typography>
                </TableCell>
                <TableCell sx={{ border: "0px !important" }}>
                  <Typography className="device-list-header">
                    Os version
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "0px !important" }}>
                  <Typography className="device-list-header">
                    Patch Status
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "0px !important" }}>
                  <Typography className="device-list-header">User</Typography>
                </TableCell>
                <TableCell sx={{ border: "0px !important" }}>
                  <Typography className="device-list-header">
                    Last check-in
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {/* <div
            ref={scrollRef}
            onScroll={onScrollHandler}
            className="scr-activity"
            style={{ marginTop: "10px" }}
          > */}

            <TableBody>
              {!loading &&
                deviceData &&
                deviceData.map((r, index) => {
                  const {
                    id,
                    deviceName,
                    complianceState,
                    deviceType,
                    osVersion,
                    lastSyncDateTime,
                    userPrincipalName,
                    OsPatchingPercentage,
                  } = r;
                  return (
                    <>
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor: "#FFF",
                          borderRadius: "5px !important",
                        }}
                      >
                        <TableCell sx={{ border: "0px !important" }}>
                          <Stack direction="row">
                            <FormControlLabel
                              sx={{
                                margin: "0px !important",
                              }}
                              id={id}
                              name="exceptionRoleIdList"
                              onChange={selectInvoiceChange}
                              control={
                                <Checkbox
                                  id={id}
                                  checked={checked[id] || false}
                                  className="device-check dev-chk"
                                />
                              }
                            />
                            <Typography className="device-list-devname">
                              {deviceName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell sx={{ border: "0px !important" }}>
                          <Typography className="device-list-con">
                            {complianceState}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ border: "0px !important" }}>
                          <Typography className="device-list-con">
                            {deviceType == "desktop" ||
                            deviceType == "windowsRT" ||
                            deviceType == "winEmbedded" ||
                            deviceType == "surfaceHub"
                              ? "windows"
                              : deviceType}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ border: "0px !important" }}>
                          <Typography className="device-list-con">
                            {osVersion}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ border: "0px !important" }}>
                          <Typography className="device-list-con">
                            {OsPatchingPercentage == null
                              ? "No patches"
                              : `${OsPatchingPercentage}%`}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ border: "0px !important" }}>
                          <Typography className="device-list-con">
                            {userPrincipalName}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ border: "0px !important" }}>
                          <Typography className="device-list-con">
                            {lastSyncDateTime
                              ? moment(new Date(lastSyncDateTime)).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
            {/* </div> */}
          </Table>
        </TableContainer>

        {loading ? (
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}></Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ textAlign: "center", margin: "40px" }}
            >
              <CustomLoader loading={loading} />
            </Grid>
            <Grid item xs={12} md={4}></Grid>
          </Grid>
        ) : deviceData && deviceData.length == 0 ? (
          <Grid
            item
            xs={12}
            md={12}
            style={{ textAlign: "center", margin: "40px" }}
          >
            <Typography>No matching records found.</Typography>
          </Grid>
        ) : (
          <></>
        )}
        {/* {!loading &&
          deviceData &&
          deviceData.map((r, index) => {
            const {
              id,
              deviceName,
              complianceState,
              deviceType,
              osVersion,
              lastSyncDateTime,
              userPrincipalName,
            } = r;
            console.log("checkid", checked[id]);
            return (
              <Grid
                spacing={1}
                sx={{ mt: 1 }}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                className="device-ui"
                key={`device_${index}`}
              >
                <Grid item xs={12} md={2}>
                  <Stack direction="row">
                    <FormControlLabel
                      sx={{
                        margin: "0px !important",
                      }}
                      id={id}
                      name="exceptionRoleIdList"
                      onChange={selectInvoiceChange}
                      control={
                        <Checkbox
                          id={id}
                          checked={checked[id] || false}
                          className="device-check dev-chk"
                        />
                      }
                    />
                    <Typography className="device-list-devname">
                      {deviceName}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography className="device-list-con">
                    {complianceState}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography className="device-list-con">
                    {deviceType == "desktop" ||
                    deviceType == "windowsRT" ||
                    deviceType == "winEmbedded" ||
                    deviceType == "surfaceHub"
                      ? "windows"
                      : deviceType}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography className="device-list-con">
                    {osVersion}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography className="device-list-con">
                    {userPrincipalName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography className="device-list-con">
                    {lastSyncDateTime
                      ? moment(new Date(lastSyncDateTime)).format("DD/MM/YYYY")
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            );
          })} */}
        {!loading && deviceData && deviceData.length > 0 && toFirst == 0 && (
          <Typography sx={{ textAlign: "center", margin: "5px" }}>
            No more records
          </Typography>
        )}
      </div>
      <StyledMenu
        className="userl-popup"
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleDelete}
          disableRipple
          // data-id={deleteid}
          data-flag={false}
          disabled={sids.length > 0 ? false : true}
        >
          Wipe
        </MenuItem>
        <MenuItem
          onClick={handleRestart}
          disableRipple
          disabled={sids.length > 0 ? false : true}
        >
          Restart
        </MenuItem>
      </StyledMenu>
      {openfil && (
        <DeviceFilter
          open={openfil}
          handleClose={openFilter}
          filter={filter}
          setFilter={setFilter}
          setSearch={setSearch}
          setDeviceData={setDeviceData}
        />
      )}
      {removeFlag && (
        <DeviceWipeConfirm
          open={removeFlag}
          handleclose={closeWipe}
          successCall={handleCloseWipe}
          id={sids}
          setDeviceData={setDeviceData}
        />
      )}
      {restartFlag && (
        <DeviceRestart
          open={restartFlag}
          handleclose={removeFun}
          successCall={handleCloseRemove}
          id={sids}
          setDeviceData={setDeviceData}
        />
      )}
      {/* {delpop && (
        <WipeConfirm
          open={delpop}
          handleClose={handleDelete}
          id={1}
          successcall={successcall}
        />
      )} */}
    </>
  );
};

export default DeviceList;
