import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import loadicon from "../../images/icon _loading.svg";
import CircularProgress from "@mui/material/CircularProgress";
import progress from "../../images/progress.svg";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ApprovalLoadingWithClose(props) {
  const { open, handleclose, closeFun } = props;
  return (
    <div className="loader-box">
      <BootstrapDialog
        onClose={handleclose}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ borderRadius: "3% !important", padding: "10px !important" }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleclose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box style={{ textAlign: "center" }}>
            {/* <img src={loadicon}/> */}
            {/* <CircularProgress style={{ color: "#FFAB00" }} /> */}
            <img
              src={progress}
              // style={{ width: "100%" }}
              alt="Main Image"
              className="main-image centered-image"
            />
          </Box>

          <form>
            {/* <Typography className="loader-title">Loading ...</Typography> */}
            <Typography className="loader-text">
              Your request has been submitted and you will be notified via Teams
              when the task has completed .
            </Typography>
            {/* <Typography className="loader-text">
Your approval request is being processed</Typography> */}
            <div style={{ textAlign: "right" }}>
              <Button className="login-btn-apr" onClick={closeFun}>
                Ok
              </Button>
            </div>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
