import * as React from "react";
import { useState, useEffect } from "react";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import {
  ADD_DOMAIN_LIST,
  ADD_OFFICE_URL,
  AZURE_DOMAIN_LIST_URL,
} from "../common/ApiUrl";
import { CustomMessage, authHeader } from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import officon from "../../images/officon.png";
import { STEP_THREE_DATA_TYPE } from "../../redux/action/ActionType";
import * as Yup from "yup";
import CustomLoader from "../common/CustomLoader";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { MultiSelect } from "react-multi-select-component";
import cli from "../../images/close1.png";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInputSel = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const initialValues = {
  domain: "",
  flagDefault: false,
};
export default function AddNewDomain(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { open, handleClose, gid, orgid, flag } = props;
  const [loading, setLoading] = React.useState(false);
  const [domainList, setDomainList] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [showDoneButton, setShowDoneButton] = useState(false);
  const [searchString, setSearchString] = React.useState("");
  const multiSelectRef = React.useRef(null);
  const dropdownRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpensel, setDropdownOpensel] = useState(false);
  const [dscl, setDoneClick] = useState("no");
  const [selone, setSelOne] = useState("Selected domains");
  const handleDoneButtonClick = () => {
    setDropdownOpen(!dropdownOpen);
    setDropdownOpensel(!dropdownOpensel);
    setAnchorEl(null);

    // multiSelectRef.current.focus(); // Remove focus
    // }
  };
  let sampleSel = [
    { label: "apptomate.co", id: "1", value: "apptomate.co" },
    { label: "apptomate1.co", id: "3", value: "apptomate1.co" },
    { label: "apptomate2.co", id: "2", value: "apptomate2.co" },
    { label: "onmicrosoft.co", id: "4", value: "apptomate3.co" },
    { label: "devo365tc2.onmicrost.com", id: "5", value: "apptomate3.co" },
    { label: "gmail.com", id: "6", value: "apptomate3.co" },
    { label: "yahoo.com", id: "7", value: "apptomate3.co" },
    { label: "apptomate3.co", id: "8", value: "apptomate3.co" },
    { label: "apptomate4.co", id: "9", value: "apptomate3.co" },
    { label: "appto.co", id: "10", value: "apptomate3.co" },
  ];
  const [selectedone, setSelectedone] = useState([]);
  const [allItemsAreSelected, setAllItemsAreSelected] = useState(false);
  const dispatch = useDispatch();

  const customOptionRenderer = ({ checked, option, onClick }) => (
    <>
      {console.log("option,", option)}

      <div key={option.value} style={{ height: "50px !important" }}>
        <label>
          <input
            style={{ opacity: option.label == "done" ? "0" : "" }}
            type="checkbox"
            className="option-checkbox"
            checked={option.label == "done" ? true : checked}
            onChange={() => {
              onClick(option);

              if (option.label != "done") {
                setDropdownOpen(true);
                setDropdownOpensel(false);
              }
              if (option.label == "done") {
                setDropdownOpen(false);
              }
              setShowDoneButton(true);
            }}
          />
          {option.label != "done" ? option.label : ""}
        </label>
      </div>
      <div>
        {showDoneButton && option === items[items.length - 1] && (
          <Box style={{ textAlign: "end" }}>
            <Button onClick={handleDoneButtonClick}>Done</Button>
          </Box>
        )}
      </div>
    </>
  );
  const validationSchema = Yup.object({
    // domain: Yup.string().required("Domain name is required"),
  });
  const addDomain = async (values) => {
    console.log("sel", selectedone);
    // let domainex = domainList.filter((r) => r.id === values.domain);
    // console.log("domain", domainList, values.domain, domainex);
    if (selectedone && selectedone.length == 0) {
      CustomMessage(
        "Missing required parameters. Please provide all necessary information.",
        "error",
        enqueueSnackbar
      );
    } else {
      const transformedArray = selectedone
        .filter((item) => item.label !== "done") // Filter out items with label 'done'
        .map((item) => ({
          domainId: item.label,
          isDefault: values.flagDefault ? "1" : "0", // Assuming values.flagDefault is available
        }));
      setLoading(true);
      const config = {
        method: "POST",
        url: ADD_DOMAIN_LIST,
        headers: authHeader(),
        data: {
          domainIds: transformedArray,
        },
      };
      try {
        const response = await API(config);
        const { status, message } = response.data;
        if (status == "Success") {
          CustomMessage(message, "success", enqueueSnackbar);
          handleClose();
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          let { data } = error.response;
          CustomMessage(data.message, "error", enqueueSnackbar);
        }
      } finally {
        setLoading(false);
      }
    }
  };
  const getAzureDomainList = async (values) => {
    const config = {
      method: "GET",
      url: AZURE_DOMAIN_LIST_URL,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { status, message, data } = response.data;
      if (status == "Success") {
        setDomainList(data.value);
        let temp = data.value;
        let newColumns;
        if (temp) {
          newColumns = temp.map((item) => {
            const { id: name, ...rest } = item;
            return { name, ...rest };
          });
        }
        const transformedArray = data.value.map((item) => ({
          label: item.id,
          id: item.id,
          value: item.id,
        }));
        const modifiedGlist = [
          ...transformedArray,
          { label: "done", id: "", value: "" },
        ];
        setItems(modifiedGlist);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const handleOnSearch = (string, results) => {
    setValues({ ...values, domain: string });
  };

  const handleOnHover = (result) => {
    // the item hovered
  };
  const handleOnSelect = (item) => {
    // the item selected
    // setValues(item)
    setValues({ ...values, domain: item.name });
  };

  const handleOnFocus = () => {
    console.log("Focued");
  };
  const nowork = () => {};
  const formatResult = (item) => {
    console.log(item, "item");
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  const { handleSubmit, handleChange, values, errors, touched, setValues } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      enableReinitialize: true,
      onSubmit: (values) => {
        addDomain(values);
      },
    });
  const handleChangeFun = () => {
    setValues({ ...values, flagDefault: !values.flagDefault });
  };
  React.useEffect(() => {
    getAzureDomainList();
  }, []);
  const handleDelete = (e) => {
    let id = e.currentTarget.dataset.id;
    console.log("selone", selectedone, id);

    let selremove = selectedone && selectedone.filter((r) => r.label != id);
    console.log("selonere", selremove);

    setSelectedone(selremove);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        multiSelectRef.current &&
        !multiSelectRef.current.contains(event.target) &&
        dropdownOpen
      ) {
        // Prevent closing the dropdown on outside clicks
        setDropdownOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);
  const handleFieldClick = () => {
    // Open the dropdown when the field is clicked
    console.log("field click");
    setDropdownOpen(true);
  };
  console.log("dropdown", dropdownOpen);
  return (
    <div>
      <BootstrapDialog
        className="org-popup"
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableBackdropClick
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box style={{ textAlign: "center" }}>
            <img src={officon} />
          </Box>
          <Box style={{ textAlign: "center" }}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                alignContent: "center",
              }}
            >
              Add Domain
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <FormControl
              style={{ marginTop: "10px" }}
              sx={{ width: "100%", minWidth: "500px" }}
              variant="standard"
              // ref={dropdownRef}
              onClick={handleFieldClick}
            >
              <MultiSelect
                ref={multiSelectRef}
                style={{
                  backgroundColor: "#f8f8f8",
                }}
                id="group"
                isOpen={dropdownOpen}
                name="group"
                isCreatable={false}
                options={items}
                value={
                  selectedone.length == items.length
                    ? selectedone
                    : selectedone.filter((el) => el.label != "done")
                }
                // value={selectedone}
                onChange={setSelectedone}
                labelledBy="Domain"
                closeOnChangedValue={dropdownOpensel}
                ItemRenderer={customOptionRenderer}
                onClick={handleFieldClick}
                //   onMenuClose={() => setDropdownOpen(false)}
                overrideStrings={{
                  selectSomeItems: "Select domain",
                  allItemsAreSelected:
                    selectedone.length == 2
                      ? selectedone.map((item) =>
                          item.label != "done" ? item.label : ""
                        )
                      : "All Domains are selected",
                  search: "Type the domain",
                }}
                allItemsAreSelected={
                  items.length != 2 && items.length == items.length - 1
                    ? true
                    : false
                }
                className="custom-multi-select"
              />
            </FormControl>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  sx={{ mt: 1.5, pl: 2 }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {selectedone &&
                    selectedone
                      .filter((r) => r.label != "done")
                      .map((rec, i) => {
                        return (
                          <Box sx={{ ml: "6px" }}>
                            <Box
                              className="categorylic-itemdom"
                              key={rec.label}
                              sx={{
                                cursor: "pointer",
                                ml: "4px",
                                mt: "12px",
                                fontWeight: "500",
                              }}
                            >
                              {rec.label}
                              <IconButton
                                aria-label="delete"
                                size="small"
                                data-id={rec.label}
                                onClick={handleDelete}
                              >
                                <img src={cli} alt={rec.label} />
                              </IconButton>
                            </Box>
                          </Box>
                        );
                      })}
                </Grid>
              </Box>
            </Box>
            {/* <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              variant="standard"
              value={selone}
              disabled={selectedone && selectedone.length == 0 ? true : false}
            >
              <BootstrapInputSel ref={multiSelectRef} id="sample" name="sample">
                <MenuItem
                  selected={true}
                  disabled={true}
                  value="Selected domains"
                >
                  Selected domains
                </MenuItem>
                {selectedone &&
                  selectedone.map((rec, i) => {
                    return (
                      <MenuItem key={`depart_${i}`} value={rec.id} disabled>
                        {rec.label != "done" ? rec.label : ""}
                      </MenuItem>
                    );
                  })}
              </BootstrapInputSel>
            </FormControl> */}
            {/* <FormControl
              style={{ marginTop: "10px" }}
              sx={{ width: "100%", minWidth: "500px" }}
              variant="standard"
            >
              <ReactSearchAutocomplete
                name="domain"
                items={items}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                showItemsOnFocus
                autoFocus
                multiple
                showIcon={false}
                placeholder="Write the domain"
                styling={{
                  backgroundColor: "#F8F8F8",
                  borderRadius: "0px",
                  height: "64px",
                  zIndex: 2,
                }}
                formatResult={formatResult}
              />
              <FormHelperText error={true}>
                {errors.domain && touched.domain ? errors.domain : ""}
              </FormHelperText>
            </FormControl> */}
            <FormControlLabel
              // ref={multiSelectRef}
              // className="acc-showtext"
              style={{ padding: "0px 0px 0px 5px" }}
              control={
                <Checkbox
                  onChange={handleChangeFun}
                  value={values.flagDefault}
                  checked={values.flagDefault}
                />
              }
              label="Set as a default"
            />
            <Box sx={{ textAlign: "center" }}>
              <Button
                type="submit"
                className="login-btn-group-bldom"
                size="medium"
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading && <CustomLoader loading={loading} />}
                SUBMIT
              </Button>
              <Button
                className="login-btn-group"
                size="medium"
                onClick={handleClose}
              >
                CANCEL
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
