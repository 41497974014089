export const NAV_FLAG_TYPE = "NAV_FLAG_TYPE";
export const STATE_LIST = "STATE_LIST";
export const ZIP_CODE_LIST = "ZIP_CODE_LIST";
export const STEP_ONE_DATA = "STEP_ONE_DATA";
export const MIC_AUTH_FLAG_TYPE = "MIC_AUTH_FLAG_TYPE";
export const AUTH_TOKEN_TYPE = "AUTH_TOKEN_TYPE";
export const USER_DETAILS_FLAG = "USER_DETAILS_FLAG";
export const GROUP_LIST_TYPE = "GROUP_LIST_TYPE";
export const OFFICE_LIST_TYPE = "OFFICE_LIST_TYPE";
export const DEPARTMENT_LIST_TYPE = "DEPARTMENT_LIST_TYPE";
export const ROLE_TYPE = "ROLE_TYPE";
export const JOB_TITLE_TYPE = "JOB_TITLE_TYPE";
export const LICENCE_TYPE = "LICENCE_TYPE";
export const SYSTEM_LICENCE_TYPE = "SYSTEM_LICENCE_TYPE";
export const USER_LIST_TYPE = "USER_LIST_TYPE";
export const CREATE_ORG_DETAILS_TYPE = "CREATE_ORG_DETAILS_TYPE";
export const STEP_TWO_DATA_TYPE = "STEP_TWO_DATA_TYPE";
export const STEP_THREE_DATA_TYPE = "STEP_THREE_DATA_TYPE";
export const STEP_THREE_DATA_TYPE_DELETE = "STEP_THREE_DATA_TYPE_DELETE";
export const STEP_FOUR_DATA_TYPE = "STEP_FOUR_DATA_TYPE";
export const STEP_FOUR_DATA_TYPE1 = "STEP_FOUR_DATA_TYPE1";
export const STEP_TWO_DATA_TYPE_DELETE = "STEP_TWO_DATA_TYPE_DELETE";
export const STEP_FIVE_DATA_TYPE = "STEP_FIVE_DATA_TYPE";
export const STEP_FIVE_DATA_TYPE_DELETE = "STEP_FIVE_DATA_TYPE_DELETE";
export const STEP_SIX_DATA_TYPE = "STEP_SIX_DATA_TYPE";
export const STEP_SIX_DATA_TYPE_DELETE = "STEP_SIX_DATA_TYPE_DELETE";
export const DOMAIN_LIST_TYPE = "DOMAIN_LIST_TYPE";
export const HARD_CODE_ROLE_TYPE = "HARD_CODE_ROLE_TYPE";
export const DISTRIBUTION_GROUP_LIST_TYPE = "DISTRIBUTION_GROUP_LIST_TYPE";
export const DISTRIBUTION_GROUPS = "DISTRIBUTION_GROUPS";
export const USER_PAGE_TYPE = "USER_PAGE_TYPE";
