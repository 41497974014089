import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import rej from "../../images/rejet.svg";
import rejtick from "../../images/fi-rr-cross-small.png";
import { REJECT_FROM_NOTIFICATION } from "../common/ApiUrl";
import loader from "../../images/icon _loading.svg";
import API from "../common/API";
import { CustomMessage } from "../common/mainfunctions";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import progress from "../../images/progress.svg";

export default function RejectNotification() {
  const location = useLocation();
  const { pathname } = location;
  const paramsString = pathname.split("/").pop();
  const params = new URLSearchParams(paramsString);
  const requestId = params.get("requestId");
  const token = params.get("token");
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setErrorFlag] = useState(false);
  const [errormes, setErrorMes] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [authEmail, setEmail] = useState();
  const [authFlag, setAuthFlag] = useState(true);
  let cliendid = process.env.REACT_APP_CLIENT_ID;
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;
  const NotificationReject = async () => {
    const config = {
      method: "POST",
      url: REJECT_FROM_NOTIFICATION,
      data: {
        requestId: requestId,
        token: token,
        email: authEmail,
      },
    };
    try {
      const response = await API(config);
      const { status } = response.data;
      if (status == "Success") {
        setFlag(true);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomMessage(
          `${data.message}.Please close this tab.`,
          "error",
          enqueueSnackbar
        );
        setErrorFlag(true);
        setErrorMes(data.message);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (token && requestId) {
      // NotificationApprove();
      const popup = window.open("", "_blank", "width=500,height=600");
      window.addEventListener("message", (event) => {
        // Check the origin of the sender
        // if (event.origin === window.location.origin) {
        //   const { token, type } = event.data;
        //   popup.close();
        // }
      });
      popup.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                          response_type=id_token%20code
                          &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send
                          &client_id=${cliendid}
                          &redirect_uri=${redirecturl}
                          &state=12345
                          &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
                          &client_info=1
                          &x-client-SKU=MSAL.JS
                          &x-client-Ver=1.4.18
                          &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
                          &response_mode=fragment`;
    }
  }, []);
  useEffect(() => {
    if (authEmail) {
      NotificationReject();
    }
  }, [authEmail]);
  useEffect(() => {
    const handleMessage = (event) => {
      const { data } = event;
      if (data && data.type === "tokenMessage") {
        console.log("data", data);
        // Assuming that the message contains the token
        // setCode(data.token.split("::")[1]);
        const receivedToken = data.token.split("::")[0];
        const base64Url = receivedToken.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const decodedData = JSON.parse(atob(base64));
        const userDisplayName = decodedData.preferred_username;
        const tid = decodedData.tid;
        setEmail(userDisplayName);
        setAuthFlag(false);
        // localStorage.setItem("TID", tid);
        // setToken(receivedToken);
        // setUPN(userDisplayName);
        // Respond back to the redirect.html page with a confirmation message
        event.source.postMessage({ type: "tokenReceived" }, event.origin);
      }
      if (data && data.type === "code") {
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  return (
    <div className="center-container">
      <div className="image-container centered-image">
        {
          flag == true ? (
            <>
              <img
                src={rej}
                style={{ width: "100%" }}
                alt="Main Image"
                className="main-image centered-image"
              />
              {/* <img src={aprtick} 
        style={{width:'76px',height:'76px'}} 
        alt="Tick Image" className="tick-image centered-image"/> */}
            </>
          ) : error == false && authFlag == false ? (
            <img
              src={progress}
              style={{ width: "100%" }}
              alt="Main Image"
              className="main-image centered-image"
            />
          ) : (
            ""
          )
          //  <img src={loader}

          //  style={{width:'100%'}}
          //  alt="Main Image" className="main-image centered-image"/>
        }
      </div>
      {flag == true ? (
        <>
          <Typography className="apr-noty-title centered-text">
            Rejected
          </Typography>
          <Typography className="apr-noty-text" style={{ padding: "15px" }}>
            Oops! We will notify the requester about the rejection
          </Typography>
          <Typography className="apr-noty-text" style={{ padding: "15px" }}>
            Please Close this tab.
          </Typography>
        </>
      ) : error == true ? (
        <>
          <Typography className="apr-noty-text" style={{ padding: "15px" }}>
            {errormes}
          </Typography>
          <Typography className="apr-noty-text" style={{ padding: "15px" }}>
            Please Close this tab.
          </Typography>
        </>
      ) : authFlag == false ? (
        <>
          <Typography className="apr-noty-title centered-text">
            Inprogress
          </Typography>
          <Typography className="apr-noty-text" style={{ padding: "30px" }}>
            This request could take a maximum of five minutes to complete. You
            can close this tab.
          </Typography>
        </>
      ) : authFlag == true ? (
        <Typography className="apr-noty-title centered-text">
          Authentication process
        </Typography>
      ) : (
        ""
      )}
    </div>
  );
}
