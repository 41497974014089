import * as React from "react";

const button = {
    width: "28px",
    height: "20px",
    borderRadius: "12px",
    margin: "0px",
};
const mainOn = {
    border: "2px solid #7CBB00",
    width: "28px",
    height: "20px",
    borderRadius: "12px",
    position: "relative",
    cursor: "pointer",
    backgroundColor: "#fff"
};
const mainOff = {
    border: "2px solid #626262",
    width: "28px",
    height: "20px",
    borderRadius: "12px",
    position: "relative",
    cursor: "pointer",
    backgroundColor: "#fff"
};
const mainOnDisabled = {
    border: "2px solid #489e9280",
    width: "28px",
    height: "20px",
    borderRadius: "12px",
    position: "relative",
    cursor: "default",
};
const mainOffDisabled = {
    border: "2px solid #62626280",
    width: "28px",
    height: "20px",
    borderRadius: "12px",
    position: "relative",
    cursor: "default",
};
const roundOn = {
    color: "#489E92",
    backgroundColor: "#7CBB00",
    width: "11px",
    height: "11px",
    borderRadius: "50%",
    position: "absolute",
    top: "2.5px",
    left: "11px",
    cursor: "pointer",
};
const roundOff = {
    color: "#626262",
    backgroundColor: "#626262",
    width: "11px",
    height: "11px",
    borderRadius: "50%",
    position: "absolute",
    top: "2.5px",
    left: "3px",
    cursor: "pointer",
};
const roundOnDisabled = {
    color: "#489e9280",
    backgroundColor: "#489e9280",
    width: "11px",
    height: "11px",
    borderRadius: "50%",
    position: "absolute",
    top: "2.5px",
    left: "11px",
    cursor: "default",
};
const roundOffDisabled = {
    color: "#62626280",
    backgroundColor: "#62626280",
    width: "11px",
    height: "11px",
    borderRadius: "50%",
    position: "absolute",
    top: "2.5px",
    left: "3px",
    cursor: "default",
};

const ToggleButton = (props) => {

    const { id, value, onChange, disabled } = props;


    return (
        <>
            {value ?
                disabled ? <button onClick={() => onChange(id, !value)} disabled={disabled} style={mainOnDisabled}><div style={roundOnDisabled}></div></button>
                    : <button onClick={() => onChange(id, !value)} disabled={disabled} style={mainOn}><div style={roundOn}></div></button>
                : disabled ? <button onClick={() => onChange(id, !value)} disabled={disabled} style={mainOffDisabled}><div style={roundOffDisabled}></div></button>
                    : <button onClick={() => onChange(id, !value)} disabled={disabled} style={mainOff}><div style={roundOff}></div></button>
            }
        </>
    )
}

export default ToggleButton;