import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { alpha,styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import APIcon from "../../images/icon api.png"
import Bitmap from "../../images/Bitmap.png"
import { Box, FormControl, FormHelperText, Input, InputAdornment, InputBase, MenuItem, Select, Stack } from '@mui/material';
import MailIcon from "../../images/mail.svg"
import PassIcon from "../../images/password.svg"
import { useFormik } from "formik";
import { ADD_NEW_GROUP_URL, ADD_NEW_USER_URL, ADD_USER_TO_GROUP, AZURE_CONNECT_URL, USERS_DISABLE_WITH_FORWARD_URL, VERIFY_AZURE_URL } from '../common/ApiUrl';
import { CustomMessage, authHeader } from '../common/mainfunctions';
import API from '../common/API';
import { useSnackbar } from 'notistack';
import { getGroupList, getUserDetails } from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import iconConfirm from "../../images/icon confirmation.png"
import ForwardEmailPopup from './ForwardEmailPopup';
import { useState } from 'react';
import CustomLoader from '../common/CustomLoader';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
      position: "relative",
      backgroundColor: "#F8F8F8",
      //border: "2px solid #CFCFCF",
      fontSize: 18,
      width: "100%",
      padding: "18px 20px",
      color: "#343434",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const BootstrapInputSel = styled(Select)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
      position: "relative",
      backgroundColor: "#F8F8F8",
      //border: "2px solid #CFCFCF",
      fontSize: 18,
      width: "100%",
      padding: "18px 20px",
      color: "#343434",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  const initialValues={
   userName:'User Name'
  }
export default function UserDisableConfirmPopup(props){
    // const [open, setOpen] = React.useState(false);
    const ulist=useSelector((ul)=>ul.UserListReducer)
    const glist=useSelector((gl)=>gl.GroupListReducer)
    const {enqueueSnackbar}=useSnackbar()
    const {open,handleClose,gid,userid,userPrinName}=props
    const [loading,setLoading]=useState(false)
const dispatch=useDispatch()
const [yeswindow,setYesWindow]=React.useState(false)
   
   const handleYes=(e)=>{
    setYesWindow(!yeswindow)
    // handleClose()
   }
   const setYesFun=()=>{
    setYesWindow(!yeswindow)
   }
   const successcall=()=>{
    setYesWindow(!yeswindow)
    handleClose()
   }
   
   const handleDisableUser=async(values)=>{
    setLoading(true)
    const config={
      method:'GET',
      url:USERS_DISABLE_WITH_FORWARD_URL,
      headers:authHeader(),
      params:{
        disableUserId:userid,
        disableUserPrincipalName:userPrinName,
        emailForwardingStatus:'no',
        emailForwardingDisplayName:'',
        emailForwardingUserPricipalName:'',
      }
    }
try{
const response =await API(config)
const {message,status}=response.data
if(status=="Success"){
  CustomMessage(message,'success',enqueueSnackbar)
  handleClose()
}
}catch(error){
  console.log(error)
}finally{
  setLoading(false)
}
    }
   
    const {
      handleSubmit,
      handleChange,
      setValues,
      values,
      errors,
      setErrors,
      touched,
    } = useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      // validationSchema: validationSchema,
      onSubmit: (values) =>{
        handleDisableUser(values)
    
      }

    });
 
    return(
        <div 
        >
        
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          style={{borderRadius:'3% !important',
        padding:'10px !important'}}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            
          </BootstrapDialogTitle>
          <DialogContent >
            <Box style={{textAlign:'center'}}>
            <img src={iconConfirm}/>

            </Box>
           
            <form >
          
           <Box sx={{textAlign:'center'}}>
          
          <Typography
    //   className="login-action"

          style={{fontWeight:'700',
        fontSize:'16px',
    padding:'10px',
textAlign:'center'}}
          >
            Do you want to forward email to another account?
          </Typography>
      <Typography
      className="login-action"
      sx={{fontWeight:'400',
      fontSize:'14px'}}>
        If yes, then your account will be disabled and you will be redirected
        </Typography>
        <Typography
      className="login-action"
      sx={{fontWeight:'400',
      fontSize:'14px'}}>
        to the email forwarding process.
        </Typography>
        <Typography
      className="login-action"
      sx={{fontWeight:'400',
      fontSize:'14px'}}>
        If no then your account will be disabled only.
        </Typography>

            <Button  className="login-btn-group-bl" size="medium" 
           
            onClick={handleYes}
            >
                YES
              </Button>
              <Button  className="login-btn-group" size="medium" 
            style={{
            marginBottom:'5px'
        }}
            onClick={handleDisableUser}
            >
              {loading&&<CustomLoader loading={loading}/>}
                NO
              </Button>
              </Box>
            </form>

          </DialogContent>
          {yeswindow&&<ForwardEmailPopup 
          open={yeswindow}
           setYesFun={setYesFun}
            userid={userid}
            userPrinName={userPrinName}
            successcall={successcall}/>}
        </BootstrapDialog>
      </div>
    )
}