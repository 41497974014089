import React from 'react'

export default function Policy() {
  return (
    <>
      <div style={{ margin: '20px' }}>

      <h1>Policy</h1>
      <p>
        Welcome to O365 ("Company," "we," "our," or "us"). We value your privacy
        and are committed to protecting your personal information. This Privacy
        Policy outlines how we collect, use, disclose, and safeguard your
        information when you visit our website
        https://devo365tc3.devo365toolkit.com (collectively, "Services"). By
        using our Services, you agree to the collection and use of information
        in accordance with this Privacy Policy.
      </p>
      </div>
    </>
  );
}