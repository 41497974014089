import { Grid, Typography } from "@mui/material";
import React from "react";
export default function NoRecordFound(){
    return(
        <>
        <Grid item xs={12} md={12} style={{ textAlign: "center",margin:'40px' }}>
          <Typography>No matching records found.</Typography>
        </Grid>
        </>
    )
}