import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import addicon from "../../images/addicon.png";
import AddNewUserPopup from "../users/AddNewUserPopup";
import { useState } from "react";
import CustomLoader from "../common/CustomLoader";
import { getSorting, stableSort } from "../common/function";
import {
  CustomMessage,
  authHeader,
  getOrgId,
  getRole,
} from "../common/mainfunctions";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import request from "../../images/request.png";
import editIcon from "../../images/editIcon.png";
import moment from "moment";
import boxicon from "../../images/boxicon.png";
import {
  ALL_LICENSE_REQUEST_LIST_URL,
  ALL_REQUESTER_APPROVAL_LIST_URL,
  APPROVE_STATUS_CHANGE_URL,
  CHANGE_LICENSE_STATUS,
  DELETE_LICENSE_REQUEST,
  REQUEST_LIST_FOR_ADMIN,
} from "../common/ApiUrl";
import API from "../common/API";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  getAzurerDomainList,
  getDepartmentList,
  getDistributionList,
  getJobTitleList,
  getOfficeList,
} from "../../redux/action";
import { useDispatch } from "react-redux";
import ApprovalLoading from "./ApprovalLoading";
import filtericon from "../../images/filter-new.png";
import clrfilter from "../../images/clearfilter.svg";
import ApprovalFilter from "./ApprovalFilter";
import { useNavigate } from "react-router-dom";
import delIcon from "../../images/deleteicon.png";
import ApprovalLoadingWithClose from "./ApprovalLoadingWithClose";
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const initialValues = {
  statusFilter: "Status",
  type: "Type",
  fromDate: "",
  toDate: "",
  priority: "Priority",
};
export default function ApprovalRequestAll() {
  let oid = getOrgId();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [openReq, setOpenReq] = useState(false);
  const [editId, setEditId] = useState();
  const [editDetails, setEditDetails] = useState();
  const [data, setData] = useState([]);
  const [checked, setChecked] = useState([]);
  const [sids, setIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [total, setTotal] = useState(0);
  const [aprloading, setAprLoading] = useState(true);
  const [aprFlag, setAprFlag] = useState(false);
  const [openfil, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(initialValues);
  const [delLoading, setDelLoading] = useState(false);
  const progressClose = () => {
    setAprFlag(false);
  };
  const tok = authHeader();
  //   let rows=10
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - total.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const addRequest = () => {
    setOpenReq(!openReq);
  };
  const handleClose = () => {
    setOpenReq(!openReq);
    getListedRequest();
  };
  const editRequest = (e) => {
    setEditId(e.currentTarget.dataset.id);
    setEditDetails(e.currentTarget.dataset.details);
    setOpenReq(!openReq);
  };
  const deleteRequest = async (e) => {
    setDelLoading(true);
    const delId = e.currentTarget.dataset.id;
    const config = {
      method: "DELETE",
      url: DELETE_LICENSE_REQUEST,
      headers: authHeader(),
      data: {
        requestId: delId,
      },
    };
    try {
      const response = await API(config);
      const { status, message } = response.data;
      if (status == "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
        getListedRequest();
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        CustomMessage(message, "error", enqueueSnackbar);
      }
    } finally {
      setDelLoading(false);
    }
  };
  const selectInvoiceChange = (e) => {
    // setChkAll(false)
    const { checked, id } = e.target;
    setChecked((values) => ({
      ...values,
      [id]: checked,
    }));
    if (checked == true) {
      setIds([...sids, { id: id }]);
    } else {
      let ch = sids.filter((e) => e.id != id);
      setIds(ch);
    }
  };
  const getListedRequest = async () => {
    setLoading(true);
    const { statusFilter, type, fromDate, toDate, priority } = filter;
    const options = {
      method: "GET",
      url:
        getRole() == "Requester"
          ? ALL_REQUESTER_APPROVAL_LIST_URL
          : getRole() == "Admin"
          ? REQUEST_LIST_FOR_ADMIN
          : ALL_LICENSE_REQUEST_LIST_URL,
      headers: authHeader(),
      params: {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        statusFilter:
          statusFilter == "Status" || statusFilter == "" ? null : statusFilter,
        priority: priority == "Priority" || priority == "" ? null : priority,
        fromDate: fromDate == "" ? null : fromDate,
        toDate: toDate == "" ? null : toDate,
      },
    };
    try {
      var apiResponse = await API(options);
      const { status, message, data } = apiResponse.data;
      if (status === "Success") {
        setData(data.list);
        setTotal(data.listCount);
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        CustomMessage(message, "error", enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!tok) {
      navigation("/signin");
    } else {
      console.log("tok", `*****${tok}`);
    }
  }, [tok]);
  useEffect(() => {
    getListedRequest();
  }, [page, rowsPerPage, filter]);
  const handleChangeStatus = async (e) => {
    let check = Object.keys(checked).filter((el) => checked[el] == true);
    if (check.length == 0) {
      CustomMessage("Please select any request", "error", enqueueSnackbar);
    } else {
      let idsarr = sids && sids.map((it) => it.id).join(",");
      let fd = data.filter((item) => idsarr.includes(item.id));
      let statusArray = fd.map((item) => item.status);
      if (
        statusArray.some((status) =>
          ["Approved", "Denied", "Rejected"].includes(status)
        )
      ) {
        CustomMessage(
          "Can not choose any Approved/Denied request.",
          "error",
          enqueueSnackbar
        );
      } else {
        const options = {
          method: "POST",
          url: CHANGE_LICENSE_STATUS,
          headers: authHeader(),
          data: {
            status: e.currentTarget.dataset.aprstatus,
            requestIdDetails: sids,
          },
        };
        try {
          var apiResponse = await API(options);
          const { status, message, data } = apiResponse.data;
          if (status === "Success") {
            CustomMessage(message, "success", enqueueSnackbar);
            setIds([]);
            setChecked([]);
            getListedRequest();
          }
        } catch (error) {
          if (error.response) {
            const { message } = error.response.data;
            CustomMessage(message, "error", enqueueSnackbar);
          }
        }
      }
    }
  };
  const handleChangeStatusApprove = async (e) => {
    let check = Object.keys(checked).filter((el) => checked[el] == true);
    if (sids.length == 0) {
      CustomMessage(
        "Pick an approval request to approve.",
        "error",
        enqueueSnackbar
      );
    } else {
      let idsarr = sids && sids.map((it) => it.id).join(",");
      let fd = data.filter((item) => idsarr.includes(item.id));
      let statusArray = fd.map((item) => item.status);
      if (
        statusArray.some((status) => ["Approved", "Denied"].includes(status))
      ) {
        CustomMessage(
          "Can not choose any Approved/Denied request.",
          "error",
          enqueueSnackbar
        );
      } else {
        setAprFlag(true);

        setAprLoading(true);
        const requestDetails = data.filter((elem) => {
          return sids.some((ele) => {
            return parseInt(ele.id) === parseInt(elem.id);
          });
        });
        const newArr = requestDetails.map(
          ({
            type,
            approverUserId,
            approverFirstName,
            approverLastName,
            createdDate,
            priority,
            requestNotes,
            requesterName,
            requesterUserId,
            status,
            ...rest
          }) => {
            return rest;
          }
        );
        const newColumns = newArr.map((item) => {
          const { office: officeLocation, ...rest } = item;
          return { officeLocation, ...rest };
        });
        const options = {
          method: "POST",
          url: APPROVE_STATUS_CHANGE_URL,
          headers: authHeader(),
          data: {
            status: e.currentTarget.dataset.aprstatus,
            requestIdDetails: newColumns,
          },
        };
        // setTimeout(() => {
        //   setAprFlag(false);
        // }, 1500);

        try {
          var apiResponse = await API(options);

          const { status, data, message } = apiResponse.data;

          if (status == "Success") {
            if (message == "Approval granted.") {
              CustomMessage(message, "success", enqueueSnackbar);
            } else if (Array.isArray(data[0]) == true) {
              const [{ message, status }] = data[0];
              if (status == "success") {
                CustomMessage(message, "success", enqueueSnackbar);
              } else {
                console.log("error", message);
                CustomMessage(message, "error", enqueueSnackbar);
              }
            } else {
              const { message, status } = data[0];
              console.log("error1", message);

              CustomMessage(message, status, enqueueSnackbar);
            }
          } else {
            if (status == "success") {
              CustomMessage(message, "success", enqueueSnackbar);
            } else {
              console.log("error2", message);

              CustomMessage(message, "error", enqueueSnackbar);
            }
          }

          setIds([]);
          setChecked([]);
          getListedRequest();
        } catch (error) {
          if (error.response) {
            const { message } = error.response.data;
            CustomMessage(message, "error", enqueueSnackbar);
          }
        } finally {
          setAprLoading(false);
          setAprFlag(false);
        }
      }
    }
  };
  useEffect(() => {
    dispatch(getDistributionList());
    dispatch(getDepartmentList(oid));
    dispatch(getOfficeList(oid));
    dispatch(getJobTitleList(oid));
    dispatch(getAzurerDomainList());
  }, []);
  const isAnyFilterApplied = Object.keys(filter).some((key) => {
    return filter[key] !== initialValues[key] && filter[key] !== "";
  });
  const openFilter = () => {
    setOpenFilter(!openfil);
  };
  const clearFilter = () => {
    setFilter(initialValues);
  };
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ margin: "10px" }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography
            style={{ fontSize: "24px", fontWeight: "500", marginLeft: "15px" }}
          >
            Request List
          </Typography>
          <IconButton
            aria-label="delete"
            sx={{ padding: "10px" }}
            size="small"
            onClick={openFilter}
          >
            <img src={filtericon} />
          </IconButton>
          {isAnyFilterApplied && (
            <IconButton
              aria-label="delete"
              sx={{ padding: "10px" }}
              size="small"
              onClick={clearFilter}
            >
              <img src={clrfilter} />
            </IconButton>
          )}
        </Stack>
        {/* <IconButton
          sx={{ p: "12px" }}
          aria-label="search"
          style={{ backgroundColor: "#00A1F1", borderRadius: "10%" }}
        >
          <img
            src={addicon}
            style={{ color: "#FFFFFF" }}
            onClick={addRequest}
          />
        </IconButton> */}
      </Stack>

      <TableContainer
        component={Paper}
        // className={data && data.length == 0 ? "non-overflow" : "yes-overflow"}
        className={`request-table ${
          data && data.length === 0 ? "non-overflow" : "yes-overflow"
        }`}
        sx={{
          // margin: "auto",
          // width: "95%",
          maxHeight: "440px",
          // overflowY: {data.length==0?"none":"scroll"},
          // maxWidth: "300px",
        }}
      >
        <Table
          // className="request-table"
          sx={{
            // minWidth: 650,
            border: "0px !important",
            boxShadow: "none",
          }}
          aria-label="simple table"
        >
          {!loading && data && data.length > 0 ? (
            <TableHead>
              <TableRow className="approve-heading">
                <TableCell sx={{ border: "0px !important" }}></TableCell>
                <TableCell sx={{ border: "0px !important" }}></TableCell>
                <TableCell sx={{ border: "0px !important" }}>
                  <Typography>Requester</Typography>
                </TableCell>
                <TableCell sx={{ border: "0px !important" }}>
                  <Typography>User</Typography>
                </TableCell>
                <TableCell sx={{ border: "0px !important" }}>
                  <Typography>Type</Typography>
                </TableCell>
                <TableCell sx={{ border: "0px !important" }}>
                  <Typography>STATUS</Typography>
                </TableCell>
                <TableCell sx={{ border: "0px !important" }}>
                  <Typography>APPROVER</Typography>
                </TableCell>
                <TableCell sx={{ border: "0px !important" }}>
                  <Typography>PRIORITY</Typography>
                </TableCell>
                <TableCell sx={{ border: "0px !important" }}>
                  <Typography>Request Date</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          ) : (
            <TableHead>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                {!loading ? "No matching records found." : ""}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableHead>
          )}
          {loading ? (
            <TableHead>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                {loading && <CustomLoader loading={loading} />}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableHead>
          ) : (
            <></>
          )}
          {!loading && data.length == 0 ? (
            <></>
          ) : (
            //   <TableBody>
            //     <TableCell></TableCell>
            //     <TableCell></TableCell>
            //     <TableCell></TableCell>
            //     <TableCell>No matching records found.</TableCell>
            //     <TableCell></TableCell>
            //     <TableCell></TableCell>
            //     <TableCell></TableCell>
            //   </TableBody>
            <>
              <TableBody>
                {delLoading ? (
                  <TableBody>
                    <Grid
                      container
                      item
                      xs={12}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <Grid item xs={4}></Grid>
                      <Grid item xs={4}>
                        <div style={{ textAlign: "center" }}>
                          <CustomLoader loading={delLoading} />
                        </div>
                      </Grid>
                      <Grid item xs={4}></Grid>
                    </Grid>
                  </TableBody>
                ) : (
                  <></>
                )}
                {!loading &&
                  data &&
                  stableSort(data, getSorting("desc", "createdDate")).map(
                    (row) => {
                      const {
                        id,
                        status,
                        priority,
                        type,
                        createdDate,
                        requestNotes,
                        requesterName,
                        approverLastName,
                        approverFirstName,
                        firstName,
                        lastName,
                        requestedUserFirstName,
                        requestedUserLastName,
                      } = row;
                      let clr = "";
                      let tclr = "";
                      if (status == "Requested") {
                        tclr = "#BC00FF";
                      } else if (status == "Approved") {
                        tclr = "#7CBB00";
                      } else if (status == "Denied" || status == "Rejected") {
                        tclr = "#F65314";
                      } else if (status == "Pending") {
                        tclr = "#FFAB00";
                      }
                      let typecle = "#00A1F1";
                      if (type == "Offboarding") {
                        typecle = "#F65314";
                      }
                      if (priority == "High") {
                        clr = "#F65314";
                      } else if (priority == "Medium") {
                        clr = "#FFAB00";
                      } else {
                        clr = "#7CBB00";
                      }
                      let tck;
                      if (sids.length > 0) {
                        tck = sids && sids.find((id) => id == id);
                      }
                      return (
                        <TableRow
                          key={id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <Box
                            //   className={
                            //     status == "Requested"
                            //       ? "line9"
                            //       : title == "APPROVED"
                            //       ? "line6"
                            //       : title == "DENIED"
                            //       ? "line7"
                            //       : "line8"
                            //   }
                            style={{ marginBottom: "10px" }}
                          >
                            <Box className="line"></Box>
                          </Box>
                          <TableCell
                            className="approve-cell-border"
                            sx={{
                              backgroundColor: "#FFF !important",
                              padding: "0px !important",
                            }}
                          >
                            {getRole() != "Requester" ? (
                              <FormControlLabel
                                sx={{ margin: "0px !important" }}
                                id={id}
                                name="exceptionRoleIdList"
                                onChange={selectInvoiceChange}
                                control={
                                  <Checkbox
                                    icon={<img src={boxicon} />}
                                    id={id}
                                    checked={checked[id]}
                                  />
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </TableCell>
                          <TableCell
                            className="approve-cell-border"
                            style={{
                              backgroundColor: "#F4F4F4",
                              border: "2px solid #FFFFFF",
                            }}
                          >
                            <Stack direction="row">
                              <IconButton>
                                <img src={request} />
                              </IconButton>
                              <Tooltip
                                title={requestNotes ? requestNotes : "N/A"}
                              >
                                <Typography
                                  style={{ marginTop: "7px" }}
                                  className="approve-text"
                                >
                                  {requestedUserFirstName
                                    ? requestedUserFirstName
                                        .concat(" ")
                                        .concat(requestedUserLastName)
                                    : ""}
                                </Typography>
                              </Tooltip>
                            </Stack>
                          </TableCell>
                          <TableCell
                            className="approve-cell-border"
                            style={{
                              backgroundColor: "#F4F4F4",
                              border: "2px solid #FFFFFF",
                            }}
                          >
                            <Typography
                              style={{ marginTop: "7px" }}
                              className="approve-text"
                            >
                              {requesterName
                                ? requesterName
                                : firstName
                                ? firstName.concat(" ").concat(lastName)
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#FFFFFF",
                              backgroundColor: typecle,
                            }}
                            className="approve-type-text approve-cell-border1"
                          >
                            {type}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#FFFFFF",
                              border: "2px solid #FFFFFF !important",
                              backgroundColor: tclr,
                              // tclr
                            }}
                            className="approve-type-text"
                          >
                            {status}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#000000",
                              backgroundColor: "#F4F4F4",
                            }}
                            className="approve-text approve-cell-border"
                          >
                            {approverFirstName &&
                              approverLastName &&
                              approverFirstName
                                .concat(" ")
                                .concat(approverLastName)}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#FFFFFF",
                              backgroundColor: clr,
                            }}
                            className="approve-type-text approve-cell-border1"
                          >
                            {priority}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#000000",
                              backgroundColor: "#000000",
                            }}
                            className="approve-text approve-cell-border"
                          >
                            {moment(new Date(createdDate)).format(
                              "MMMM DD, YYYY "
                            )}
                          </TableCell>
                          {/* {getRole() == "Requester" ? ( */}
                          <TableCell
                            style={{ backgroundColor: "#F4F4F4" }}
                            className="approve-cell-border"
                          >
                            <IconButton
                              sx={{
                                p: "12px",
                                border: "none !important",
                                borderRadius: "none !important",
                              }}
                              aria-label="search"
                            >
                              <img
                                src={editIcon}
                                style={{ color: "#FFFFFF" }}
                                onClick={editRequest}
                                data-id={id}
                                data-details={row}
                              />
                            </IconButton>
                            {status !== "Approved" ? (
                              <IconButton
                                sx={{
                                  p: "12px",
                                  border: "none !important",
                                  borderRadius: "none !important",
                                }}
                                aria-label="search"
                              >
                                <img
                                  src={delIcon}
                                  style={{ color: "#FFFFFF" }}
                                  onClick={deleteRequest}
                                  data-id={id}
                                  data-details={row}
                                />
                              </IconButton>
                            ) : (
                              <></>
                            )}{" "}
                          </TableCell>
                          {/* ) : (
                            <></>
                          )} */}
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
      <Paper sx={{ margin: "auto", width: "auto" }}>
        {!loading && data && data.length > 0 ? (
          <Grid container>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6} sx={{ padding: "15px" }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                //  colSpan={3}
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {!loading && data.length > 0 && getRole() != "Requester" ? (
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ textAlign: "left", margin: "auto" }}
            >
              <Typography
                style={{ marginTop: "5px", marginLeft: "22px" }}
                className="approve-note"
              >
                You need to select 1 or more request to "Deny", "Withdraw" or
                "Approve"
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ marginBottom: "5px" }}>
              <Stack
                sx={{ p: { xs: "15px", sm: "15px", md: "0px" } }}
                direction={{ xs: "column", sm: "column", md: "row" }}
                justifyContent="space-between"
                spacing={1.5}
              >
                <Button
                  className="login-btn3 approve-head-text"
                  size="medium"
                  data-aprstatus="Denied"
                  onClick={handleChangeStatus}
                >
                  <Typography className="apr-btn-text">DENY</Typography>
                </Button>
                <Button
                  className="login-btn2 approve-head-text"
                  size="medium"
                  data-aprstatus="Pending"
                  onClick={handleChangeStatus}
                >
                  <Typography className="apr-btn-text">WITHDRAW</Typography>
                </Button>
                <Button
                  className="login-btn1 approve-head-text"
                  size="medium"
                  data-aprstatus="Approved"
                  onClick={handleChangeStatusApprove}
                >
                  <Typography className="apr-btn-text">APPROVE</Typography>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Paper>

      {openReq && (
        <AddNewUserPopup
          open={openReq}
          handleClose={handleClose}
          editId={editId}
          details={editDetails}
        />
      )}
      {aprFlag && (
        <ApprovalLoadingWithClose open={aprFlag} closeFun={progressClose} />
      )}
      {openfil && (
        <ApprovalFilter
          open={openfil}
          handleClose={openFilter}
          setFilter={setFilter}
          filter={filter}
          // setSearch={setSearch}
        />
      )}
    </>
  );
}
