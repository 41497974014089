import { Box, Grid, Stack, Typography } from "@mui/material";
import ErrorIcon from "../images/error-icon.svg";
import React, { useEffect, useState } from "react";
import {
  DASHBOARD_LICENSE_URL,
  DASHBOARD_ORGANIZATION_LIST,
  DASHBOARD_URL,
  REQUESTER_LICENSE_REQUEST_LIST_URL,
} from "./common/ApiUrl";
import { NumberOfDays, authHeader, getRole } from "./common/mainfunctions";
import API from "./common/API";
import moment from "moment";

export default function DashboardOnboarding() {
  const [loading, setLoading] = useState(false);
  const [licList, setLicList] = useState();
  const getLicenseList = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url:
        getRole() != "Requester"
          ? DASHBOARD_LICENSE_URL
          : REQUESTER_LICENSE_REQUEST_LIST_URL,
      headers: authHeader(),
      params: {
        type: "",
      },
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        setLicList(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLicenseList();
  }, []);
  const {
    requestedTotalCount = "",
    requestedCount = "",
    requestedList = [],
    pendingList = [],
    approvedList = [],
    deniedList = [],
    deniedCount = "",
    approvedCount = "",
    pendingCount = "",
    deniedTotalCount = "",
    approvedTotalCount = "",
    pendingTotalCount = "",
  } = licList || {};
  return (
    <>
      <Grid
        sx={{ mt: 2 }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      >
        <Grid item xs={12} md={3}>
          <Box className="Number-box color1">
            <Typography className="num-title" variant="body2">
              Requested
            </Typography>
            <Typography className="count" variant="h4">
              {requestedCount || 0}
            </Typography>
          </Box>
          {/* {requestedList&&requestedList.map((rec,i)=>{
                  const {requestedUserFirstName,
                    requestedUserLastName,
                    status,
                    createdDate,
                    requestNotes,priority,
                    firstName,
                    lastName,
                  }=rec

return(
  <Box className="Normal-box" key={i}>
                  <Stack
                    direction="row"
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography className="nm-title" variant="body2">
                      {firstName?firstName.concat(' ').concat(lastName):'N/A'}

                    </Typography>
                    {priority=="High"?
                    <img src={ErrorIcon} />:''}
                  </Stack>
                  <Typography className="role" variant="h4">
                  {requestNotes}
                  </Typography>
                  <Typography className="role" variant="h4">
                    {NumberOfDays(moment(new Date(createdDate)))}
                  </Typography>
                </Box>
)
                })} */}
        </Grid>
        <Grid item xs={12} md={3}>
          <Box className="Number-box color2">
            <Typography className="num-title" variant="body2">
              Pending (Last 30 days)
            </Typography>
            <Typography className="count" variant="h4">
              {pendingCount || 0}
            </Typography>
          </Box>
          {/* {pendingList&&pendingList.map((record,pi)=>{
                  const {requestedUserFirstName,
                    requestedUserLastName,
                    status,
                    createdDate,
                    requestNotes,priority,
                  firstName,
                lastName}=record
                  return(
<Box className="Normal-box"
key={pi}>
                  <Stack
                    direction="row"
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                    justifyContent="space-between"
                    alignItems="center"
                    
                  >
                    <Typography className="nm-title" variant="body2">
                      {firstName?firstName.concat(' ').concat(lastName):'N/A'}

                      
                    </Typography>
                    {priority=="High"?
                    <img src={ErrorIcon} />:''}
                  </Stack>
                  <Typography className="role" variant="h4">
                    {requestNotes}
                  </Typography>
                  <Typography className="role" variant="h4">
                    {NumberOfDays(moment(new Date(createdDate)))}

                  </Typography>
                </Box>
                  )
                })} */}
        </Grid>
        <Grid item xs={12} md={3}>
          <Box className="Number-box color3">
            <Typography className="num-title" variant="body2">
              Approved (Last 30 days)
            </Typography>
            <Typography className="count" variant="h4">
              {approvedCount || 0}
            </Typography>
          </Box>
          {/* {approvedList&&approvedList.map((arec,ai)=>{
                   const {requestedUserFirstName,
                    requestedUserLastName,
                    status,
                    createdDate,
                    requestNotes,priority,
                  firstName,
                lastName}=arec
                  return(
<Box className="Normal-box"
key={`indx_${ai}`}>
                  <Stack
                    direction="row"
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography className="nm-title" variant="body2">
                    {firstName?firstName.concat(' ').concat(lastName):'N/A'}


                    </Typography>
                    {priority=="High"?
                    <img src={ErrorIcon} />:''}
                  </Stack>
                  <Typography className="role" variant="h4">
                    {requestNotes}
                  </Typography>
                  <Typography className="role" variant="h4">
                    {NumberOfDays(moment(new Date(createdDate)))}

                  </Typography>
                </Box>
                  )
                })} */}
        </Grid>
        <Grid item xs={12} md={3}>
          <Box className="Number-box color4">
            <Typography className="num-title" variant="body2">
              Denied (Last 30 days)
            </Typography>
            <Typography className="count" variant="h4">
              {deniedCount || 0}
            </Typography>
          </Box>
          {/* {deniedList&&deniedList.map((deni,di)=>{
                   const {requestedUserFirstName,
                    requestedUserLastName,
                    status,
                    createdDate,
                    requestNotes,priority,
                  firstName,
                lastName}=deni
                  return(
<Box className="Normal-box"
key={`denied_${di}`}>
                  <Stack
                    direction="row"
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography className="nm-title" variant="body2">
                    {firstName?firstName.concat(' ').concat(lastName):'N/A'}


                    </Typography>
                    {priority=="High"?
                    <img src={ErrorIcon} />:''}
                  </Stack>
                  <Typography className="role" variant="h4">
                    {requestNotes}
                  </Typography>
                  <Typography className="role" variant="h4">
                    {NumberOfDays(moment(new Date(createdDate)))}

                  </Typography>
                </Box>
                  )
                })} */}
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, mt: "10px" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} md>
            <Box className="bottom-box line1">
              <Box className="line"></Box>
              <Typography className="dash-box-title" variant="body2">
                REQUESTED (YTD)
              </Typography>
              <Typography variant="h4">{requestedTotalCount || 0}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md>
            <Box className="bottom-box line2">
              <Box className="line"></Box>
              <Typography className="dash-box-title" variant="body2">
                PENDING (YTD)
              </Typography>
              <Typography variant="h4">{pendingTotalCount || 0}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md>
            <Box className="bottom-box line3">
              <Box className="line"></Box>
              <Typography className="dash-box-title" variant="body2">
                APPROVED (YTD)
              </Typography>
              <Typography variant="h4">{approvedTotalCount || 0}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md>
            <Box className="bottom-box line4">
              <Box className="line"></Box>
              <Typography className="dash-box-title" variant="body2">
                DENIED (YTD)
              </Typography>
              <Typography variant="h4">{deniedTotalCount || 0}</Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={12} md>
                <Box className="bottom-box line5">
                  <Box className="line"></Box>
                  <Typography className="dash-box-title" variant="body2">DISBURSED (YTD)</Typography>
                  <Typography variant="h4">$0</Typography>
                </Box>
              </Grid> */}
        </Grid>
      </Box>
    </>
  );
}
