import * as types from "../action/ActionType";
import { CREATE_ORG_DETAILS_INITIAL } from "../store/InitialStore";
export default function CreateOrgDetailsReducer(state = CREATE_ORG_DETAILS_INITIAL, action) {
  const { type, payload } = action;

  const {name,
  contactEmail,
  contactNumber,
  address,
//   state,
  zipCode,
  profilePath,}=payload||[]
  switch (type) {
    case types.CREATE_ORG_DETAILS_TYPE:
      return {...state,
        name:name,
  contactEmail:contactEmail,
  contactNumber:contactNumber,
  address:address,
//   state,
  zipCode:zipCode,
  profilePath:profilePath,
      }
      
    default:
      return state;
  }
}
