import * as types from "../action/ActionType";
import { USER_DETAILS_INITIAL } from "../store/InitialStore";
export default function UserDetailsReducer(state = USER_DETAILS_INITIAL, action) {
  const { type, payload } = action;
  switch (type) {
    case types.USER_DETAILS_FLAG:
      return (state = payload);
    default:
      return state;
  }
}
