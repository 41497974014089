import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from 'react-router-dom'
import { SnackbarProvider } from "notistack";
import { Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import ConfigureStore from './redux/store/ConfigureStore';
import { Provider } from 'react-redux'

const store = ConfigureStore()

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
 {/* <React.StrictMode> */}
    {/* <AzureAD provider={authProvider} forceLogin={true}> */}
    <BrowserRouter>
  <Provider store={store}>

    <ThemeProvider theme={theme}>
          <SnackbarProvider
            ref={notistackRef}
            action={(key) => (
              <Button onClick={onClickDismiss(key)}>
                <CloseIcon style={{ color: "#fff" }} />
              </Button>
            )}
            maxSnack={1}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <App />
          </SnackbarProvider>
        </ThemeProvider>
</Provider>
    </BrowserRouter>
  </>
  /* </React.StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
