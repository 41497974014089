import * as types from "../action/ActionType";
import {  STEP_FIVE_DATA_INITIAL } from "../store/InitialStore";
export default function StepFiveReducer(state = STEP_FIVE_DATA_INITIAL, action) {
  const { type, payload } = action;
  switch (type) {
    case types.STEP_FIVE_DATA_TYPE:
      return {
        ...state,
        roles: [...state.roles, payload]
      }
      case types.STEP_FIVE_DATA_TYPE_DELETE:
      return {
        ...state,
        roles:payload
      }
    default:
      return state;
  }
}
