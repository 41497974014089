import { MsalAuthProvider, LoginType } from 'react-aad-msal';
// Msal Configurations
import { LogLevel } from "msal";
let dom=sessionStorage.getItem('CURRENT')
const dynamicRedirectUri = `http://${window.location.hostname}/redirect`;
const getAuthRedirectUri = () => {
  if (window.location.hostname.startsWith('dev0365tc')) {
    return dynamicRedirectUri;
  }
  return window.location.origin + '/redirect';
};
const config = {
  auth: {
    authority: process.env.REACT_APP_AUTHORITY_DOMAIN,
    clientId: process.env.REACT_APP_CLIENT_ID,
    // redirectUri: getAuthRedirectUri(),
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    validateAuthority: true,
    navigateToLoginRequestUrl: true,
    // postLogoutRedirectUri:process.env.REACT_APP_REDIRECT_URI,
    
  },
 
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};
 
// Authentication Parameters
const authenticationParameters = {
  scopes: [
    'user.read'
  ]
}

// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + '/auth.html'
  // tokenRefreshUri: `${dom}/signin` + '/auth.html'
}
 
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)