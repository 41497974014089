import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import closeIcon from "../../images/close1.png";
import editICon from "../../images/editIcon.png";
import deleteicon from "../../images/deleteicon.png";
import { CustomMessage, authHeader } from "../common/mainfunctions";
import API from "../common/API";
import {
  DELETE_MEMBER_FROM_GROUP,
  GET_GROUP_MEMBER_LIST_URL,
} from "../common/ApiUrl";
import AddMember from "../users/AddMember";
import { getUserDetails } from "../../redux/action";
import { useSnackbar } from "notistack";
import CustomLoader from "../common/CustomLoader";
import { setLocale } from "yup";
const style = {
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  background: "#FFFFFF",
  boxShadow: 24,
  padding: "30px",
  overflowY: "scroll",
};
export default function GroupDetails(props) {
  const { open, handleClose, groupid, groupname } = props;
  const [openmem, setOpenmem] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const addnewMem = () => {
    setOpenmem(!openmem);
  };

  const [data, setData] = useState();
  const getGroupMemberDetails = async () => {
    const config = {
      method: "GET",
      url: GET_GROUP_MEMBER_LIST_URL,
      headers: authHeader(),
      params: {
        groupId: groupid,
      },
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        setData(data.value);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClosemem = () => {
    setOpenmem(false);
    setTimeout(() => {
      getGroupMemberDetails();
    }, 500);
  };
  useEffect(() => {
    getGroupMemberDetails();
  }, [groupid]);
  const deleteFun = async (e) => {
    setLoading(true);
    let id = e.currentTarget.dataset.id;
    let upn = e.currentTarget.dataset.upn;
    const config = {
      method: "DELETE",
      url: DELETE_MEMBER_FROM_GROUP,
      headers: authHeader(),
      params: {
        groupId: groupid,
        memberId: id,
        userPrincipalName: upn,
      },
    };
    try {
      const response = await API(config);
      const { status, message } = response.data;
      if (status == "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
        setTimeout(() => {
          getGroupMemberDetails();
        }, 500);
        getUserDetails();
      }
    } catch (error) {
      // const {data}=error.response
      // const {message,status}=data
      CustomMessage("Error", "error", enqueueSnackbar);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      className="userlt-popup"
      open={open}
      // onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{ ...style }}
        className="manage-expense-transaction side-scroll-list"
      >
        <Grid container>
          <Grid item xs={12} md={2}>
            <img src={closeIcon} onClick={handleClose} />
          </Grid>

          <Grid item xs={12} md={10} style={{ textAlign: "end" }}>
            <Button onClick={addnewMem}>+ Add New USer</Button>
          </Grid>
        </Grid>
        <Typography className="group-detail-title">{groupname}</Typography>
        {loading && <CustomLoader loading={loading} />}

        {data && data.length == 0 ? (
          <Typography className="login-action">
            No group member found
          </Typography>
        ) : (
          <></>
        )}
        {data &&
          data.map((r, i) => {
            return (
              <Grid
                container
                spacing={1}
                className="card"
                sx={{ paddingLeft: "10px", marginTop: "30px" }}
                key={i}
              >
                <Grid xs={12} md={9}>
                  <Stack direction="column">
                    <Typography className="grp-dtl-name">
                      {r.displayName}
                    </Typography>

                    <Typography className="grp-dtl-mail">
                      {r.userPrincipalName}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid xs={12} md={3} className="btncard">
                  <Stack direction="row">
                    <IconButton>{/* <img src={editICon} /> */}</IconButton>
                    <IconButton
                      data-id={r.id}
                      data-upn={r.userPrincipalName}
                      onClick={deleteFun}
                    >
                      <img src={deleteicon} />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            );
          })}
        {openmem && (
          <AddMember
            open={openmem}
            handleClose={handleClosemem}
            gid={groupid}
          />
        )}
      </Box>
    </Modal>
  );
}
