import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import { Grid, Stack } from "@mui/material";
import MailIcons from "../images/mail-icon.svg";
import LocationIcon from "../images/location-icon.svg";
import PhoneIcon from "../images/phone-icon.svg";
import Navbar from "./layout/Navbar";
import DashboardOnboarding from "./DashboardOnboarding";
import {
  DASHBOARD_ORGANIZATION_LIST,
  GET_ORGANIZATION_DETAILS,
} from "./common/ApiUrl";
import API from "./common/API";
import {
  CustomMessage,
  authHeader,
  getOrgId,
  getSideFlag,
} from "./common/mainfunctions";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
export default function Dashboard() {
  const theme = useTheme();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [orgList, setOrgList] = useState();
  const [details, setDetails] = useState();
  const Navigation = useNavigate();
  const organizationId = getOrgId();
  console.log(organizationId, "oid");

  const getOrganizationList = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: DASHBOARD_ORGANIZATION_LIST,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        setOrgList(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  function getCookie(name) {
    console.log(name, document.cookie);
    const value = `; ${document.cookie}`;
    console.log(value);
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  useEffect(() => {
    const token = getCookie("userToken");
    console.log("from cookie token", token);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      getOrganizationList();
    }, 500);
  }, []);
  const getOrganizationDetails = async () => {
    const config = {
      method: "GET",
      url: GET_ORGANIZATION_DETAILS,
      headers: authHeader(),
      params: {
        organizationId: organizationId,
      },
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        const { orgDetails = [] } = data;

        setDetails(orgDetails);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
    }
  };
  useEffect(() => {
    if (organizationId) {
      getOrganizationDetails();
    }
  }, [organizationId]);

  return (
    <>
      <Box sx={{ display: "-webkit-box" }}>
        {getSideFlag() == "true" ? <></> : <Navbar name="Dashboard" />}
        {/* {getSideFlag() == "true" ? "0px" : "65px"} */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: getSideFlag() === "true" ? "0px" : "65px",
            marginTop: { xs: "25px", md: "35px" },
          }}
        >
          <Box className="address-box" sx={{ flexGrow: 1 }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={9}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box className="logo-box">
                    <img
                      className="dash-logo"
                      src={details && details.profilePath}
                      style={{ width: "-webkit-fill-available" }}
                    ></img>
                  </Box>
                  <Box>
                    <Typography className="dash-head">
                      {details && details.name}
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <img src={LocationIcon} />
                      <Typography className="address-text" variant="body2">
                        {details && details.address}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <img src={MailIcons} />
                      <Typography className="address-text" variant="body2">
                        {details && details.contactEmail}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <img src={PhoneIcon} />
                      <Typography className="address-text" variant="body2">
                        {details && details.contactNumber}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
              <Grid sx={{ mt: "auto" }} item xs>
                <Box sx={{ textAlign: "right" }}></Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ width: "100%" }}>
            <DashboardOnboarding />
          </Box>
        </Box>
      </Box>
    </>
  );
}
