import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import filicon from "../../../images/filter-new.png";
import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const BootstrapInputSel = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function OrgLicenseFilter(props) {
  const { handleClose, open, filter, setFilter } = props;
  const applyFilter = (values) => {
    setFilter(values);
    handleClose();
  };
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: filter,
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      applyFilter(values);
    },
  });
  return (
    <div>
      <BootstrapDialog
        className="org-popup"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box style={{ textAlign: "center" }}>
            <img src={filicon} style={{ width: "40px", height: "40px" }} />
          </Box>
          <Box style={{ textAlign: "center" }}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                alignContent: "center",
              }}
            >
              Filter
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl
                style={{ marginTop: "10px" }}
                sx={{ width: "100%", minWidth: "500px" }}
                variant="standard"
              >
                <BootstrapInput
                  labelId="licenseName"
                  id="licenseName"
                  value={values.licenseName}
                  label="licenseName"
                  name="licenseName"
                  placeholder="License Name"
                  onChange={handleChange}
                ></BootstrapInput>
              </FormControl>

              <FormControl
                style={{ marginTop: "10px" }}
                sx={{ width: "100%", minWidth: "500px" }}
                variant="standard"
              >
                <BootstrapInput
                  labelId="price"
                  id="price"
                  value={values.price}
                  label="price"
                  name="price"
                  placeholder="Price"
                  onChange={handleChange}
                ></BootstrapInput>
              </FormControl>

              <FormControl
                style={{ marginTop: "10px" }}
                sx={{ width: "100%", minWidth: "500px" }}
                variant="standard"
              >
                <BootstrapInput
                  labelId="priceType"
                  id="priceType"
                  value={values.priceType}
                  label="priceType"
                  name="priceType"
                  placeholder="Price Type"
                  onChange={handleChange}
                ></BootstrapInput>
              </FormControl>
              <Button
                type="submit"
                className="login-btn-group-bl"
                size="medium"
              >
                APPLY
              </Button>
              <Button
                className="login-btn-group"
                size="medium"
                onClick={handleClose}
              >
                CANCEL
              </Button>
            </form>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
