import styled from "@emotion/styled";
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));
const DeviceSetting = () => {
  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      sx={{ mt: "15px" }}
    >
      <Grid item xs={6}>
        <Item sx={{ padding: "0px !important", boxShadow: "none" }}>
          <Typography className="notify-header">
            Intune device settings
          </Typography>
          <Divider sx={{ borderColor: "rgb(0 0 0 / 7%)" }} />

          <Box sx={{ p: "15px 20px" }}>
            <Stack direction="row" justifyContent="space-between">
              <FormLabel
                id="demo-radio-buttons-group-label"
                className="notify-text-one"
              >
                Enable Device?
              </FormLabel>
              <Box
                style={{
                  backgroundColor: "#7cbb00",
                  padding: "5px 5px",
                  borderRadius: "5px",
                }}
                // data-status={values.pauseStatus == "on" ? "off" : "on"}
                // onClick={handleStatus}
              >
                {/* {values.pauseStatus == "on" ? ( */}
                {1 == 1 ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <Typography className="pause-text">YES</Typography>
                    <Box className="pause-box"></Box>
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <Box className="pause-box"></Box>

                    <Typography className="pause-text">NO</Typography>
                  </Stack>
                )}
              </Box>
            </Stack>
          </Box>
        </Item>
      </Grid>
    </Grid>
  );
};

export default DeviceSetting;
