import * as types from "../action/ActionType";
import { DISTRIBUTION_LIST_INITIAL } from "../store/InitialStore";
export default function DistributionGroupListReducer(state = DISTRIBUTION_LIST_INITIAL, action) {
  const { type, payload } = action;
  switch (type) {
    case types.DISTRIBUTION_GROUP_LIST_TYPE:
      return (state = payload);
    default:
      return state;
  }
}
