import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import APIcon from "../../images/icon api.png";
import Bitmap from "../../images/Bitmap.png";
import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import MailIcon from "../../images/mail.svg";
import PassIcon from "../../images/password.svg";
import { useFormik } from "formik";
import {
  ADD_NEW_GROUP_URL,
  ADD_NEW_USER_URL,
  ADD_SYSTEM_USER_URL,
  ALL_USERS_LIST_URL,
  AZURE_CONNECT_URL,
  GET_GROUP_LIST_AZURE_URL,
  USER_LISTS_URL,
  VERIFY_AZURE_URL,
} from "../common/ApiUrl";
import { CustomMessage, authHeader } from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import { getGroupList, getUserDetails } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import gicon from "../../images/group.png";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import CustomLoader from "../common/CustomLoader";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { MultiSelect } from "react-multi-select-component";
import Autocomplete from "@mui/material/Autocomplete";
import { debounce } from "underscore";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const BootstrapInputSel = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const initialValues = {
  name: "Select User",
  role: "Select Role",
};
const CustomDropdown = ({ children }) => (
  <div style={{ maxHeight: "200px", overflowY: "auto" }}>{children}</div>
);

export default function AddNewSystemUser(props) {
  // const [open, setOpen] = React.useState(false);
  const glist = useSelector((gl) => gl.GroupListReducer);
  const roleList = useSelector((rl) => rl.HardCodeRoleListReducer);
  const userList = useSelector((ul) => ul.UserListReducer);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { open, handleClose, data, successcall } = props;
  const [page, setPage] = useState("");
  const [pageurl, setPageUrl] = useState();
  const [list, setList] = useState();
  const [ulist, setUserList] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedone, setSelectedone] = useState([]);
  const [loadingOp, setLoadingOp] = useState(false);
  const dispatch = useDispatch();
  let scrollRef = React.useRef(null);

  const validationSchema = Yup.object({
    name: Yup.string().test(
      "name",
      "User name is required",
      (value) => value != "Select User"
    ),
    role: Yup.string().test(
      "role",
      "Role is required",
      (value) => value != "Select Role"
    ),
  });
  const customOptionRenderer = ({ checked, option, onClick }) => (
    <>
      <div key={option.value}>
        <label>
          {/* <input
            style={{ opacity: option.label == "done" ? "0" : "" }}
            type="checkbox"
            className="option-checkbox"
            checked={option.label == "done" ? true : checked}
            onChange={() => {
              onClick(option);

              if (option.label != "done") {
                setDropdownOpen(false);
              }
              setShowDoneButton(true);
            }}
          /> */}
          {option.name}
        </label>
      </div>
    </>
  );
  const getMsList = async () => {
    const options = {
      method: "GET",
      url: GET_GROUP_LIST_AZURE_URL,
      headers: authHeader(),
      params: {
        search: "",
      },
    };
    try {
      var apiResponse = await API(options);
      const { status, message, data } = apiResponse.data;
      if (status === "Success") {
        setList(data.value);
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
      }
    }
  };
  useEffect(() => {
    getMsList();
  }, []);
  const getUserListed = async (str, nxtlink) => {
    setLoadingOp(true);
    const options = {
      method: "GET",
      headers: authHeader(),
      url: ALL_USERS_LIST_URL,
      params: {
        paginationUrl: nxtlink,
        search: str ? str : null,
        pageLimit: 20,
      },
    };
    try {
      const res = await API(options);
      const { data, status } = res.data;
      if (status == "Success") {
        const { "@odata.nextLink": nextLink, value } = data;
        if (nextLink) {
          getUserListed("", nextLink);
        }
        if (Array.isArray(value)) {
          setUserList((prevUserList) => [...prevUserList, ...value]);
        }
        let temp = data.value;
        let newColumns;
        if (temp) {
          newColumns = temp.map((item) => {
            const { displayName: name, ...rest } = item;
            return { name, ...rest };
          });
        }
        // setItems(newColumns);
        setItems((prev) => {
          // Check if each record in data.value already exists in prev (existing data)
          console.log("prev", prev);
          const newData = newColumns.filter(
            (newValue) =>
              !prev.some((prevValue) => prevValue.id === newValue.id)
          );
          return prev.concat(newData);
        });

        console.log(nextLink);
        if (nextLink) {
          setPageUrl(nextLink);
        } else {
          setPageUrl();
        }
        console.log("new ", newColumns);
        // if (nextLink != "undefined") {
        //   setPage(nextLink);
        // } else {
        //   setPage(null);
        // }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOp(false);
    }
  };
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    // sessionStorage.setItem("scrollPosition", scrollRef.current.scrollTop);
    sessionStorage.setItem("scrollPosition", scrollTop);
    // setScrollTop(scrollTop);
    // Check if user has scrolled to the bottom
    if (scrollHeight - scrollTop === clientHeight) {
      // Load more data or perform other actions
      console.log("Scrolled to the bottom", pageurl);
      // For example, you can fetch more data here
      // fetchMoreData();

      if (pageurl) {
        getUserListed();
      }
    } else {
      console.log("Scrolled not to the bottom");
    }
  };
  useEffect(() => {
    getUserListed();
  }, [items.length == 0]);
  // useEffect(() => {
  //   if (page != null) {
  //     getUserListed();
  //   }
  // }, [page]);
  console.log("new1 ", items);

  const handleMenuScroll = (e) => {
    console.log("e", e.target);
    const target = e.target;
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      !loading &&
      page
    ) {
      getUserListed();
    }
  };
  const addNewSystemUser = async (values) => {
    console.log(values)
    if (values.name == "Select User" || values.role == "Select Role") {
      CustomMessage(
        "Missing required parameters. Please provide all necessary information.",
        "error",
        enqueueSnackbar
      );
    } else {
      console.log("value", values.name,ulist);
      let n1 = ulist.filter((rec) => rec.displayName == values.name);
      // let group = "";
      // if (n1) {
      //   group = n1[0].displayName;
      // }
      setLoading(true);

      const options = {
        method: "POST",
        url: ADD_SYSTEM_USER_URL,
        headers: authHeader(),
        data: {
          firstName: n1[0].givenName,
          lastName: n1[0].surname,
          email: n1[0].userPrincipalName,
          msUserId: n1[0].id,
          role: values.role,
        },
      };
      try {
        var apiResponse = await API(options);
        const { status, message } = apiResponse.data;
        if (status === "Success") {
          CustomMessage(message, "success", enqueueSnackbar);
          successcall();
          handleClose();
        }
      } catch (error) {
        if (error.response) {
          const { message } = error.response.data;
          CustomMessage(message, "error", enqueueSnackbar);
        }
      } finally {
        setLoading(false);
      }
    }
  };
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      addNewSystemUser(values);
    },
  });
  const handleOnSearch = (string, results) => {
    setValues({ ...values, name: string });
    getUserListed(string, "");
  };
  const handleChangeFun = (e) => {
    console.log(e.target.value);
    setValues({ ...values, name: e.target.value });
    delaySearch(e.target.value);
  };

  const delaySearch = React.useRef(
    debounce((search) => getUserListed(search, ""), 500)
  ).current;
  const handleOnHover = (result) => {
    // the item hovered
  };
  const handleOnSelect = (e) => {
    // the item selected
    // setValues(item)
    console.log(e.target.value)
    setPageUrl();
    setValues({ ...values, name: e.target.value });
  };
  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    console.log("item", item);
    const formattedItems = items.map((item) => item.name).join(", ");
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };
  const renderResults = (results) => (
    <div style={{ maxHeight: "50px", overflowY: "auto" }}>
      {results.map((result, index) => (
        <div key={index}>{formatResult(result)}</div>
      ))}
    </div>
  );
  const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "The Dark Knight", year: 2008 },
    { title: "12 Angry Men", year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: "Pulp Fiction", year: 1994 },
    {
      title: "The Lord of the Rings: The Return of the King",
      year: 2003,
    },
  ];
  const handleOnChange = (event, value) => {
    // The selected item
    console.log(value); // This will log the selected item or `null` if no item is selected
    setPageUrl();
    setValues({ ...values, name: value });
  };
  return (
    <div>
      <BootstrapDialog
        className="org-popup"
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box style={{ textAlign: "center" }}>
            <img src={gicon} />
          </Box>
          <Box style={{ textAlign: "center" }}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                alignContent: "center",
              }}
            >
              Add New User
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl
                style={{ marginTop: "10px" }}
                sx={{
                  width: "100%",
                  minWidth: "500px",
                }}
                variant="standard"
              >
                <Autocomplete
                  // freeSolo
                  sx={{
                    backgroundColor: "#F8F8F8",
                  }}
                  id="name"
                  name="name"
                  disableClearable
                  options={items.map((option) => option.name)}
                  // onSelect={handleOnSelect}
                  onChange={handleOnChange}
                  // onSearch={handleOnSearch}
                  renderInput={(params) => (
                    <TextField
                      ref={scrollRef}
                      onScroll={handleScroll}
                      {...params}
                      label="Write the user name"
                      onChange={handleChangeFun}
                    />
                  )}
                />
                {/* <Autocomplete
                  id="grouped-demo"
                  options={items.map((option) => option.name)}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="With categories" />
                  )}
                /> */}
                <FormHelperText error={true}>
                  {errors.name && touched.name ? errors.name : ""}
                </FormHelperText>
              </FormControl>
              {/* <FormControl
                  style={{ marginTop: "10px" }}
                  sx={{ width: "100%", minWidth: "500px" }}
                  variant="standard"
                > */}
              {/* <div style={{ maxHeight: "200px", overflowY: "auto" }}> */}
              {/* <MultiSelect
                  style={{ backgroundColor: "#f8f8f8" }}
                  id="group"
                  open={false}
                  name="group"
                  options={items}
                  // options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10",'11','12','13','14']}
                  value={selectedone}
                  // value={selectedone}
                  onChange={setSelectedone}
                  labelledBy="Group"
                  closeOnChangedValue
                  ItemRenderer={customOptionRenderer}
                  // onMenuOpen={() => setDropdownOpen(true)}
                  //   onMenuClose={() => setDropdownOpen(false)}
                  overrideStrings={{
                    selectSomeItems: "Groups",

                    search: "Write the user name",
                  }}
                /> */}
              {/* <ReactSearchAutocomplete
                  name="name"
                  items={items}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  autoFocus
                  showIcon={false}
                  isLoading={loadingOp}
                  placeholder="Write the user name"
                  styling={{
                    backgroundColor: "#F8F8F8",
                    borderRadius: "0px",
                    height: "46px",
                    zIndex: 3,
                  }}
                  // formatResult={formatResult}
                  renderResult={renderResults}
                  dropdownStyle={{
                    maxHeight: "50px",
                    overflowY: "scroll",
                  }}
                /> */}

              {/* </FormControl> */}
              {/* <BootstrapInputSel
                  labelId="name"
                  id="name"
                  value={values.name}
                  label="name"
                  name="name"
                  onChange={handleChange}
                  onScroll={handleMenuScroll}
                  style={{ maxHeight: "50px !important", overflowY: "auto" }}
                >
                  <MenuItem selected={true} disabled={true} value="Select User">
                    Select User
                  </MenuItem>
                  {ulist &&
                    ulist.map((r, i) => {
                      const { displayName = "", id = "" } = r;

                      return (
                        <MenuItem
                          key={id}
                          value={id}
                          style={{
                            maxHeight: "50px !important",
                            overflowY: "auto",
                          }}
                        >
                          {displayName}
                        </MenuItem>
                      );
                    })}
                </BootstrapInputSel> */}
              {/* <FormHelperText error={true}>
                  {errors.name && touched.name ? errors.name : ""}
                </FormHelperText>
              </FormControl> */}
              <FormControl
                style={{ marginTop: "10px" }}
                sx={{ width: "100%", minWidth: "500px" }}
                variant="standard"
              >
                <BootstrapInputSel
                  labelId="role"
                  id="role"
                  value={values.role}
                  label="role"
                  name="role"
                  onChange={handleChange}
                >
                  <MenuItem selected={true} disabled={true} value="Select Role">
                    Select Role
                  </MenuItem>
                  {roleList &&
                    roleList.map((r, i) => {
                      const { roleName = "", id = "" } = r;

                      return (
                        <MenuItem key={id} value={roleName}>
                          {roleName}
                        </MenuItem>
                      );
                    })}
                </BootstrapInputSel>
                <FormHelperText error={true}>
                  {errors.role && touched.role ? errors.role : ""}
                </FormHelperText>
              </FormControl>
              <Button
                type="submit"
                className="login-btn-group-bl"
                size="medium"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading && <CustomLoader loading={loading} />}
                SUBMIT
              </Button>
              <Button
                className="login-btn-group"
                size="medium"
                onClick={handleClose}
              >
                CANCEL
              </Button>
            </form>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
