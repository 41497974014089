import React, { useState } from "react";
import {
  DEVICE_NEW_PERMISSION_URL,
  FIRST_ALERT_FLAG_URL,
  PERMISSIONN_FLAG_UPDATE_URL,
  PERMISSION_URL,
} from "../../common/ApiUrl";
import { CustomMessage, authHeader } from "../../common/mainfunctions";
import API from "../../common/API";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  Typography,
  alpha,
} from "@mui/material";
import BitMapPax from "../../../images/Bitmap.png";
import CustomLoader from "../../common/CustomLoader";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import entraid from "../../../images/menid.jpg";
import { useDispatch } from "react-redux";
import { getUserDetails } from "../../../redux/action";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(10),
  },
  "& .MuiInputBase-input": {
    borderRadius: "0px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    marginTop: "15px !important",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const initialValues = {
  appObjectId: "",
};
export default function DeviceAlert(props) {
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    // appObjectId: Yup.string().trim().required("Object Id is required"),
  });
  const handleCloseBef = async () => {
    const config = {
      method: "POST",
      url: FIRST_ALERT_FLAG_URL,
      headers: authHeader(),
      data: {
        newUpdateFlag: 1,
      },
    };
    try {
      const res = await API(config);
    } catch (error) {
      console.log(error);
    } finally {
      handleClose();
    }
  };
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // permissionFun(values);
    },
  });

  const closeFun = () => {};
  return (
    <>
      <div className="mar-top-azure">
        <BootstrapDialog
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          disableBackdropClick
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></BootstrapDialogTitle>
          <DialogContent className="mar-top-azure">
            <Box style={{ textAlign: "center" }}>
              <img style={{ height: "50px", width: "auto" }} src={entraid} />
            </Box>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              New Feature Alert: Device Management Now Available!
            </Typography>
            <form>
              <Typography
                style={{
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >
                Efficiently manage all your Intune devices with our new feature
                by enabling it through the integration settings.
              </Typography>

              <div style={{ textAlign: "right" }}>
                <Button className="login-btn-apr" onClick={handleCloseBef}>
                  Ok
                </Button>
              </div>
            </form>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
}
