import * as types from "../action/ActionType";
import { NAV_FLAG_INITIAL } from "../store/InitialStore";
export default function NavbarFlagReducer(state = NAV_FLAG_INITIAL, action) {
  const { type, payload } = action;
  switch (type) {
    case types.NAV_FLAG_TYPE:
      return (state = payload);
    default:
      return state;
  }
}
