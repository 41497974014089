import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {
  ThemeProvider,
  alpha,
  createTheme,
  styled,
} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import filicon from "../../images/filter-new.png";
import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import moment from "moment/moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useFormik } from "formik";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const BootstrapInputSel = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none",
        },
      },
    },
  },
});

export default function DeviceFilter(props) {
  const { handleClose, open, setFilter, setSearch, filter, setDeviceData } =
    props;
  const [value, setValue] = useState();
  const [dateValue, setDateValue] = useState(null);
  const [dateToValue, setDateToValue] = useState(null);
  const compState = [
    { name: "Compliant", value: "compliant" },
    { name: "Non-Compliant", value: "noncompliant" },
  ];
  const devType = [
    { name: "Windows", value: "windows" },
    { name: "Linux", value: "linux" },
  ];
  const applyFilter = (values) => {
    setDeviceData([]);
    setFilter(values);
    setSearch();
    handleClose();
  };
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: filter,
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      applyFilter(values);
    },
  });
  const handleDate = (e) => {
    setDateValue(e);
    setValues({
      ...values,
      fromDate: moment(new Date(e)).format("YYYY-MM-DD"),
    });
  };
  const handleDateTo = (e) => {
    setDateToValue(e);
    setValues({ ...values, toDate: moment(new Date(e)).format("YYYY-MM-DD") });
  };
  return (
    <div>
      <BootstrapDialog
        className="org-popup"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box style={{ textAlign: "center" }}>
            <img style={{ width: "40px", height: "40px" }} src={filicon} />
          </Box>
          <Box style={{ textAlign: "center" }}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                alignContent: "center",
              }}
            >
              Filter
            </Typography>
            <form>
              <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                Compliance State{" "}
              </InputLabel>
              <FormControl
                style={{ marginTop: "10px" }}
                sx={{ width: "100%", minWidth: "500px" }}
                variant="standard"
              >
                <BootstrapInputSel
                  labelId="compliance"
                  id="compliantState"
                  value={values.compliantState}
                  label="compliantState"
                  name="compliantState"
                  placeholder="Select Compliance"
                  onChange={handleChange}
                  displayEmpty // Display empty option
                  renderValue={(selected) => {
                    if (!selected) {
                      return "Select Compliance";
                    }
                    return selected;
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Compliance
                  </MenuItem>
                  {compState.map((r, i) => {
                    return (
                      <MenuItem key={`comp_${i}`} value={r.value}>
                        {r.name}
                      </MenuItem>
                    );
                  })}
                </BootstrapInputSel>
              </FormControl>
              <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                Os{" "}
              </InputLabel>
              <FormControl
                style={{ marginTop: "10px" }}
                sx={{ width: "100%", minWidth: "500px" }}
                variant="standard"
              >
                <BootstrapInputSel
                  labelId="deviceType"
                  id="deviceType"
                  value={values.deviceType}
                  label="deviceType"
                  name="deviceType"
                  onChange={handleChange}
                  displayEmpty // Display empty option
                  renderValue={(selected) => {
                    if (!selected) {
                      return "Select OS";
                    }
                    return selected;
                  }}
                >
                  <MenuItem value="" disabled>
                    Select OS
                  </MenuItem>
                  {devType.map((r, i) => {
                    return (
                      <MenuItem key={`os_${i}`} value={r.value}>
                        {r.name}
                      </MenuItem>
                    );
                  })}
                </BootstrapInputSel>
              </FormControl>
              <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                Last Check-in
              </InputLabel>
              <Stack direction="row" spacing={1}>
                <ThemeProvider theme={theme}>
                  <div className="report-date filter-date">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        sx={{ width: "194px !important" }}
                        components={{
                          OpenPickerIcon: ({ className }) => (
                            <ArrowDropDownIcon
                              className={className}
                              style={{ margin: "-8px" }}
                            />
                          ),
                        }}
                        value={dateValue}
                        maxDate={new Date()}
                        onChange={handleDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                border: "none",
                                backgroundColor: "#f8f8f8",
                              },
                            }}
                            sx={{
                              width: "130px !important",
                              mr: 2,
                              background: "#FFFFFF !important",
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "From",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <div className="report-date filter-date">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        components={{
                          OpenPickerIcon: ({ className }) => (
                            <ArrowDropDownIcon
                              className={className}
                              style={{ margin: "-8px" }}
                            />
                          ),
                        }}
                        value={dateToValue}
                        maxDate={new Date()}
                        onChange={handleDateTo}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                border: "none",
                                backgroundColor: "#f8f8f8",
                              },
                            }}
                            sx={{
                              width: "130px !important",
                              mr: 2,
                              background: "#FFFFFF !important",
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "To",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </ThemeProvider>
              </Stack>
              <Button
                type="submit"
                className="login-btn-group-bl"
                size="medium"
                onClick={handleSubmit}
              >
                APPLY
              </Button>
              <Button
                className="login-btn-group"
                size="medium"
                onClick={handleClose}
              >
                CANCEL
              </Button>
            </form>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
