import * as types from "../action/ActionType";
import {  STEP_TWO_INITIAL } from "../store/InitialStore";
export default function StepTwoReducer(state = STEP_TWO_INITIAL, action) {
  const { type, payload } = action;
  switch (type) {
    case types.STEP_TWO_DATA_TYPE:
      return {
        ...state,
        arr: [...state.arr, payload]
      }
      case types.STEP_TWO_DATA_TYPE_DELETE:
        return {
          ...state,
          arr:payload
        }
    default:
      return state;
  }
}
