import React from 'react'

export default function Terms(){
  return (
    <>
      <div style={{ margin: '20px' }}>

      Just created this.  Terms of Use
1. Acceptance of Terms
By accessing and using HR Assist, you accept and agree to be bound by the terms and provision of this agreement. In addition, when using HR Assist, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use this service.
2. Intellectual Property
The HR Assist app, including but not limited to text, graphics, images, and logos, is the property of IntelligIS and is protected by applicable copyright and trademark laws. Unauthorized use of the content may violate copyright, trademark, and other laws.
3. User Conduct
You agree to use HR Assist only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the website. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within the website.
4. Limitation of Liability
IntelligIS will not be liable for any damages that may occur to you as a result of your misuse of our website. This includes, but is not limited to, direct, indirect, incidental, punitive, and consequential damages.
5. Changes to Terms
IntelligIS reserves the right to modify these terms at any time. We will notify you of any changes by posting the new terms on this page. You are advised to review this Terms of Use page periodically for any changes. Changes to these terms are effective when they are posted on this page.
6. Governing Law
These terms shall be governed and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law provisions.
7. Contact Us
If you have any questions or concerns, please contact us at help@intelligis.com.
      </div>
    </>
  );
}