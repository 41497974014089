import { Label } from "recharts";
import API from "../../components/common/API";
import {
  AZURE_DOMAIN_LIST_URL,
  CODE_AUTH_FOR_USER_URL,
  GET_DEPARTMENT_LIST_URL,
  GET_DISTRIBUTION_GROUP_LIST_URL,
  GET_GROUP_LIST_DROP_URL,
  GET_JOB_TITILE_URL,
  GET_LICENCE_LIST_URL,
  GET_OFFICE_LIST_URL,
  GET_ROLES_LIST_URL,
  HARD_CODE_ROLE_LIST,
  STATE_LIST_URL,
  SYSTEM_LICENSE_LIST_URL,
  USER_LISTS_URL,
  USER_PROFILE_URL,
  ZIP_CODE_LIST_URL,
} from "../../components/common/ApiUrl";
import { authHeader } from "../../components/common/mainfunctions";
import {
  DEPARTMENT_LIST_TYPE,
  DISTRIBUTION_GROUPS,
  DISTRIBUTION_GROUP_LIST_TYPE,
  DOMAIN_LIST_TYPE,
  GROUP_LIST_TYPE,
  HARD_CODE_ROLE_TYPE,
  JOB_TITLE_TYPE,
  LICENCE_TYPE,
  OFFICE_LIST_TYPE,
  ROLE_TYPE,
  STATE_LIST,
  SYSTEM_LICENCE_TYPE,
  USER_DETAILS_FLAG,
  USER_LIST_TYPE,
  ZIP_CODE_LIST,
} from "./ActionType";
import { id } from "date-fns/locale";

export function getStateList() {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: STATE_LIST_URL,
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          dispatch({
            type: STATE_LIST,
            payload: data.list,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}

export function getZipCodeList() {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: ZIP_CODE_LIST_URL,
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          dispatch({
            type: ZIP_CODE_LIST,
            payload: data.list,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}
export function getUserDetails() {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: USER_PROFILE_URL,
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          localStorage.setItem("ORGID", data.organizationId);
          localStorage.setItem("MSFLAG", data.msIntegrateFlag);
          dispatch({
            type: USER_DETAILS_FLAG,
            payload: data,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}
export function getUserAuth(code) {
  console.log(code);
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: CODE_AUTH_FOR_USER_URL,
      params: {
        tokenCode: code,
      },
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}

export function getGroupList(search) {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: GET_GROUP_LIST_DROP_URL,
      params: {
        search: search,
      },
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          dispatch({
            type: GROUP_LIST_TYPE,
            payload: data.list,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}
export function getDepartmentList(id, search) {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: GET_DEPARTMENT_LIST_URL,
      params: {
        organizationId: id,
        search: search,
      },
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          dispatch({
            type: DEPARTMENT_LIST_TYPE,
            payload: data.list,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}

export function getOfficeList(id, search) {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: GET_OFFICE_LIST_URL,
      params: {
        organizationId: id,
        search: search,
      },
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          dispatch({
            type: OFFICE_LIST_TYPE,
            payload: data.list,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}

export function getRoleList(id, search) {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: GET_ROLES_LIST_URL,
      params: {
        organizationId: id,
        search: search,
      },
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          dispatch({
            type: ROLE_TYPE,
            payload: data.list,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}
export function getJobTitleList(id, search) {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: GET_JOB_TITILE_URL,
      params: {
        organizationId: id,
        search: search,
      },
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          dispatch({
            type: JOB_TITLE_TYPE,
            payload: data.list,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}
export function getLicenceList() {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: GET_LICENCE_LIST_URL,
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          dispatch({
            type: LICENCE_TYPE,
            payload: data.value,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}
export function getSystemLicenceList(value, search) {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: SYSTEM_LICENSE_LIST_URL,
      params: {
        organizationId: value,
        search: search,
      },
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          dispatch({
            type: SYSTEM_LICENCE_TYPE,
            payload: data.list,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}
export function getAZUserList() {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: USER_LISTS_URL,
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          // dispatch({
          //   type: USER_PAGE_TYPE,
          //   payload:data.odata.nextLink
          // });
          dispatch({
            type: USER_LIST_TYPE,
            payload: data.value,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}

export function getAzurerDomainList() {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: AZURE_DOMAIN_LIST_URL,
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          dispatch({
            type: DOMAIN_LIST_TYPE,
            payload: data.value,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}

export function getHardCodeRoleList() {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: HARD_CODE_ROLE_LIST,
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          dispatch({
            type: HARD_CODE_ROLE_TYPE,
            payload: data.list,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}

export function getDistributionList(search) {
  return (dispatch) => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: GET_DISTRIBUTION_GROUP_LIST_URL,
      params: {
        search: search,
      },
    };
    API(options)
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response.data;
          dispatch({
            type: DISTRIBUTION_GROUPS,
            payload: data.value,
          });
          const transformedArray = data.value.map((item) => ({
            label: item.displayName,
            id: item.id,
            value: item.displayName,
            upn: item.mail,
          }));
          const modifiedGlist = [
            ...transformedArray,
            { label: "done", id: "", value: "" },
          ];
          dispatch({
            type: DISTRIBUTION_GROUP_LIST_TYPE,
            payload: modifiedGlist,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}
