import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Box,
  FormControl,
  FormHelperText,
  InputBase,
  Select,
} from "@mui/material";
import { useFormik } from "formik";
import { JOB_TITLE_URL_ADD } from "../common/ApiUrl";
import { CustomMessage, authHeader } from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import jobIcon from "../../images/jobicon.png";
import { STEP_SIX_DATA_TYPE } from "../../redux/action/ActionType";
import * as Yup from "yup";
import { useState } from "react";
import CustomLoader from "../common/CustomLoader";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const initialValues = {
  jobTitles: "",
};
export default function AddNewJobTitle(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { open, handleClose, gid, orgid, flag } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    jobTitles: Yup.string().required("Job title is required"),
  });
  const addJobFun = async (values) => {
    setLoading(true);
    if (flag) {
      const config = {
        method: "POST",
        url: JOB_TITLE_URL_ADD,
        headers: authHeader(),
        data: {
          organizationId: parseInt(orgid),
          name: values.jobTitles,
        },
      };
      try {
        const response = await API(config);
        const { status, message } = response.data;
        if (status == "Success") {
          CustomMessage(message, "success", enqueueSnackbar);
          handleClose();
        }
      } catch (error) {
        if (error.response) {
          let { data } = error.response;
          if (data.message == "This jobtitle already exits. Try another.") {
            setErrors({ ...errors, jobTitles: data.message });
          } else {
            CustomMessage(data.message, "error", enqueueSnackbar);
          }
        }
      } finally {
        setLoading(false);
      }
    } else {
      dispatch({
        type: STEP_SIX_DATA_TYPE,
        payload: values.jobTitles,
      });
    }
    // handleClose();
  };
  const { handleSubmit, handleChange, values, touched, errors, setErrors } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        addJobFun(values);
      },
    });

  return (
    <div>
      <BootstrapDialog
        className="org-popup"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box style={{ textAlign: "center" }}>
            <img src={jobIcon} />
          </Box>
          <Box style={{ textAlign: "center" }}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                alignContent: "center",
              }}
            >
              Add New Job Title
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box style={{ textAlign: "center" }}>
              <FormControl
                style={{ marginTop: "10px" }}
                sx={{ width: "100%", minWidth: "500px" }}
                variant="standard"
              >
                <BootstrapInput
                  labelId="jobTitles"
                  id="jobTitles"
                  value={values.jobTitles}
                  label="jobTitles"
                  name="jobTitles"
                  placeholder="Job Title"
                  onChange={handleChange}
                ></BootstrapInput>
                <FormHelperText error={true}>
                  {errors.jobTitles && touched.jobTitles
                    ? errors.jobTitles
                    : ""}
                </FormHelperText>
              </FormControl>

              <Button
                type="submit"
                className="login-btn-group-bl"
                size="medium"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading && <CustomLoader loading={loading} />}
                SUBMIT
              </Button>
              <Button
                className="login-btn-group"
                size="medium"
                onClick={handleClose}
              >
                CANCEL
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
