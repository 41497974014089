import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import filicon from "../../../images/filter-new.png";
import SearchIcon from "@mui/icons-material/Search";
import addicon from "../../../images/addicon.png";
import { useDispatch } from "react-redux";
import { DELETE_JOB_TITLE_URL, GET_JOB_TITILE_URL } from "../../common/ApiUrl";
import { CustomMessage, authHeader } from "../../common/mainfunctions";
import API from "../../common/API";
import { useSnackbar } from "notistack";
import closeIcon from "../../../images/close1.png";
import AddNewJobTitle from "../../organizationadd/AddNewJobTitle";
import OrgJobFilter from "../Filters/OrgJobFilter";
import clrfilter from "../../../images/clearfilter.svg";
import PublishIcon from "@mui/icons-material/Publish";
import OrgJobImport from "../Filters/OrgJobImport";

export default function OrganizationJobTitles(props) {
  const [open, setOpen] = useState(false);
  const [jobList, setJobList] = useState();
  const { data = [], getOrgDetails, orgid } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState();
  const [openfil, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState();
  const [openDep, setOpenDep] = useState(false);
  const callImport = () => {
    setOpenDep(!openDep);
  };
  const openDepartment = (e) => {
    setSearch("");

    setOpenDep(!openDep);
  };
  const getJobTitleList = async () => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: GET_JOB_TITILE_URL,
      params: {
        organizationId: orgid,
        search: search ? search : filter,
      },
    };
    try {
      const response = await API(options);

      if (response.status === 200) {
        const { status, data } = response.data;
        setJobList(data.list);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
      }
    }
  };
  const addlicense = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
    getOrgDetails();
    getJobTitleList(orgid, search);
  };
  useEffect(() => {
    getJobTitleList(orgid, search);
  }, [orgid, search, filter]);
  const clearFilter1 = () => {
    setFilter("");
  };
  const handleDelete = async (e) => {
    const id = e.currentTarget.dataset.id;
    const config = {
      method: "DELETE",
      url: DELETE_JOB_TITLE_URL,
      headers: authHeader(),
      data: {
        jobTitleId: id,
      },
    };
    try {
      const response = await API(config);
      const { status, message } = response.data;
      if (status == "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
        getOrgDetails();
        dispatch(getJobTitleList(orgid, search));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const setSearchFun = (e) => {
    setSearch(e.target.value);
  };
  const setSearchFuncall = () => {
    getJobTitleList(orgid, search);
  };
  const openFilter = () => {
    setSearch("");
    setOpenFilter(!openfil);
  };
  const clearFilter = () => {
    setFilter();
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Grid
            sx={{ mt: 1.5, pl: 2 }}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <Typography
                className="acti-log"
                style={{
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "50px",
                }}
              >
                Job Titles List
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={openFilter}
                >
                  <img src={filicon} />
                </IconButton>
                {filter && (
                  <IconButton
                    aria-label="delete"
                    sx={{ padding: "10px" }}
                    size="small"
                    onClick={clearFilter1}
                  >
                    <img src={clrfilter} />
                  </IconButton>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1} direction="row" justifyContent="flex-end">
                <Paper
                  sx={{
                    p: "2px 6px",
                    display: "flex",
                    alignItems: "center",
                    width: "-webkit-fill-available",
                    marginTop: "auto",
                    marginBottom: "auto",
                    height: "50px",
                    boxShadow: "none",
                    borderRadius: "5px",
                  }}
                >
                  <InputBase
                    sx={{ flex: 1 }}
                    placeholder="Search ..."
                    inputProps={{ "aria-label": "   search" }}
                    onChange={setSearchFun}
                    value={search}
                  />
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    style={{
                      backgroundColor: "#FFAB00",
                      borderRadius: "10%",
                      color: "#fff",
                    }}
                    onClick={setSearchFuncall}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>

                <IconButton
                  type="submit"
                  sx={{ p: "12px" }}
                  aria-label="search"
                  style={{
                    backgroundColor: "#00A1F1",
                    borderRadius: "10%",
                    marginTop: "1px",
                    marginBottom: "4px",
                  }}
                  onClick={addlicense}
                >
                  <img src={addicon} style={{ color: "#FFFFFF" }} />
                </IconButton>
                <IconButton
                  type="submit"
                  sx={{ p: "12px" }}
                  aria-label="search"
                  style={{
                    backgroundColor: "#00A1F1",
                    borderRadius: "10%",
                    marginTop: "1px",
                    marginBottom: "4px",
                  }}
                  onClick={callImport}
                >
                  <PublishIcon sx={{ color: "#FFF" }} />
                </IconButton>
              </Stack>
            </Grid>
            {jobList &&
              jobList.map((rec, i) => {
                return (
                  <Box sx={{ ml: "6px" }}>
                    <Box
                      className="category-item"
                      key={rec.jobTitle}
                      sx={{
                        cursor: "pointer",
                        ml: "12px",
                        mt: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {rec.jobTitle}
                      <IconButton
                        aria-label="delete"
                        size="small"
                        data-id={rec.id}
                        onClick={handleDelete}
                      >
                        <img src={closeIcon} alt={rec.jobTitle} />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
          </Grid>
        </Box>
        {open && (
          <AddNewJobTitle
            open={open}
            handleClose={handleClose}
            flag={true}
            orgid={orgid}
          />
        )}
        {openfil && (
          <OrgJobFilter
            open={openfil}
            handleClose={openFilter}
            setFilter={setFilter}
            setSearch={setSearch}
            clearFilter={clearFilter}
          />
        )}
        {openDep && (
          <OrgJobImport
            open={openDep}
            handleClose={openDepartment}
            setFilter={setFilter}
            setSearch={setSearch}
          />
        )}
      </Box>
    </>
  );
}
