import { Stack, Typography } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";

const HalfPie = () => {
  const options = {
    series: [74],
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: ["Average Results"],
  };
  const options1 = {
    series: [34],
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: ["Average Results"],
  };
  return (
    <>
      {/* <div className="app">
        <div className="row"> */}
      <Stack direction="row" gap={10}>
        <div className="mixed-chart">
          <Typography sx={{ marginTop: "15px", textAlign: "center" }}>
            Desktop policy complaince
          </Typography>

          <Chart
            options={options}
            series={options.series}
            type="radialBar"
            height={350}
          />
        </div>
        <div className="mixed-chart">
          <Typography sx={{ marginTop: "15px", textAlign: "center" }}>
            Desktop microsoft complaince
          </Typography>

          <Chart
            options={options1}
            series={options1.series}
            type="radialBar"
            height={350}
          />
        </div>
      </Stack>
      {/* </div>
      </div> */}
    </>
  );
};

export default HalfPie;
