import styled from "@emotion/styled";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { lightGreen } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import infoIcon from "../../images/info.png";
import { useFormik } from "formik";
import {
  GET_NOTIFICATIONS_SETTINGS_URL,
  SET_NOTIFICATIONS_SETTINGS_URL,
} from "../common/ApiUrl";
import { CustomMessage, authHeader } from "../common/mainfunctions";
import API from "../common/API";
import CustomLoader from "../common/CustomLoader";
import { useSnackbar } from "notistack";
import plus from "../../images/plus blue.png";
import ToggleButton from "../toggleButton";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  borderColor:
    theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",

  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#7CBB00",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#bfbfbf",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    borderColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const initialvalues = {
  notificationMethod: "",
  pauseStatus: "",
  pauseTimePeriod: "",
  preferredEmail: "",
  allEmails: [],
  emailSendItems: [],
  appSendItems: [],
  teamsAppSendItems: [],
};
const customCheckboxStyle = {
  color: "#7cbb00", // Replace this with your desired custom color code
  "&$checked": {
    color: "#7cbb00", // Replace this with your desired custom color code
  },
};
export default function NotifiationList() {
  const [loading, setLoading] = useState(false);
  const [settingLoading, setSettingLoading] = useState(false);
  const [mailFlag, setMailFlag] = useState(false);
  const [allEmails1, setAllEmails1] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const addmailFun = () => {
    setMailFlag(true);
  };
  const handleChangeFun = (e) => {
    console.log(values.notificationMethod, e);
    if (values.notificationMethod != e) {
      setValues({ ...values, notificationMethod: e });
    } else {
      setValues({ ...values, notificationMethod: "" });
    }
  };
  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initialvalues,
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      addSettings(values);
    },
  });
  const addSettings = async (values) => {
    setSettingLoading(true);
    const {
      notificationMethod,
      pauseStatus,
      pauseTimePeriod,
      preferredEmail,
      allEmails,
      emailSendItems,
      appSendItems,
      teamsAppSendItems,
    } = values;
    const config = {
      method: "POST",
      url: SET_NOTIFICATIONS_SETTINGS_URL,
      headers: authHeader(),
      data: {
        notificationMethod: notificationMethod,
        pauseStatus: pauseStatus,
        pauseTimePeriod: pauseTimePeriod,
        preferredEmail: preferredEmail,
        allEmails: allEmails.join(","),
        emailSendItems: emailSendItems.join(","),
        appSendItems: appSendItems.join(","),
        teamsAppSendItems: teamsAppSendItems.join(","),
      },
    };
    try {
      const response = await API(config);
      const { status, message, data } = response.data;
      if (status == "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSettingLoading(false);
    }
  };
  const handleChangeemail = (event) => {
    const selectedValue = event.target.value;
    const isChecked = event.target.checked;

    // Create a new array with the updated appSendItems based on the selected checkbox
    const updatedAppSendItems = isChecked
      ? [...values.emailSendItems, selectedValue] // Add the selectedValue if checked
      : values.emailSendItems.filter((item) => item !== selectedValue); // Remove the selectedValue if unchecked

    // Use setValues to update the appSendItems in the values object
    setValues({
      ...values,
      emailSendItems: updatedAppSendItems,
    });
  };
  const handleCheckboxChange = (event) => {
    const selectedValue = event.target.value;
    const isChecked = event.target.checked;

    // Create a new array with the updated appSendItems based on the selected checkbox
    const updatedAppSendItems = isChecked
      ? [...values.appSendItems, selectedValue] // Add the selectedValue if checked
      : values.appSendItems.filter((item) => item !== selectedValue); // Remove the selectedValue if unchecked

    // Use setValues to update the appSendItems in the values object
    setValues({
      ...values,
      appSendItems: updatedAppSendItems,
    });
  };
  const handleCheckboxChangeteam = (event) => {
    const selectedValue = event.target.value;
    const isChecked = event.target.checked;

    const updatedAppSendItems = isChecked
      ? [...values.teamsAppSendItems, selectedValue] // Add the selectedValue if checked
      : values.teamsAppSendItems.filter((item) => item !== selectedValue); // Remove the selectedValue if unchecked

    setValues({
      ...values,
      teamsAppSendItems: updatedAppSendItems,
    });
  };
  const getNotificationSettingDetails = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: GET_NOTIFICATIONS_SETTINGS_URL,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { status, messge, data } = response.data;
      if (status == "Success") {
        const {
          notificationMethod,
          pauseStatus,
          pauseTimePeriod,
          preferredEmail,
          allEmails,
          emailSendItems,
          appSendItems,
          teamsAppSendItems,
        } = data;
        let ap = appSendItems.split(",");
        let ap1 = ap.map((item) => item.trim());
        setValues({
          notificationMethod: notificationMethod,
          pauseStatus: pauseStatus,
          pauseTimePeriod: pauseTimePeriod,
          preferredEmail: preferredEmail,
          emailSendItems: emailSendItems.split(","),
          appSendItems: ap1,
          teamsAppSendItems: teamsAppSendItems.split(","),
          allEmails: allEmails.split(","),
        });
        setAllEmails1(allEmails.split(","));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getNotificationSettingDetails();
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const enteredEmail = event.target.value.trim();

      if (enteredEmail === "" || !validateEmail(enteredEmail)) {
        setIsValidEmail(false);
      } else {
        setAllEmails1((prevEmails) => [...prevEmails, event.target.value]);
        setMailFlag(false);
        setIsValidEmail(true);
      }
    }
  };
  useEffect(() => {
    if (allEmails1) {
      setValues({ ...values, allEmails: allEmails1 });
    }
  }, [allEmails1]);
  const handleStatus = (e) => {
    let status = e.currentTarget.dataset.status;
    if (status == "off") {
      setValues({ ...values, pauseStatus: status, pauseTimePeriod: "" });
    } else {
      setValues({ ...values, pauseStatus: status });
    }
  };
  return (
    <>
      {!loading && (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mt: "15px" }}
        >
          <Grid item xs={12} md={6}>
            <Item sx={{ padding: "0px !important", boxShadow: "none" }}>
              <Typography className="notify-header">
                Notification Preference
              </Typography>
              <Divider sx={{ borderColor: "rgb(0 0 0 / 7%)" }} />
              <FormControl sx={{ p: "15px 20px" }}>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{ paddingBottom: "10px", paddingTop: "10px" }}
                  className="notify-text-one"
                >
                  PREFERRED NOTIFICATION METHOD
                </FormLabel>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  style={{ padding: "5px" }}
                >
                  {/* <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    onChange={handleChangeFun}
                    value={values.notificationMethod}
                  >
                    <FormControlLabel
                      className="notify-text"
                      value="email"
                      control={
                        <Radio
                          sx={{
                            color: "",
                            "&.Mui-checked": {
                              color: lightGreen[600],
                            },
                          }}
                        />
                      }
                      label="Email"
                    />
                  </RadioGroup> */}
                  <ToggleButton
                    name="email"
                    id="email"
                    value={values.notificationMethod == "email" ? 1 : 0}
                    onChange={(email) => handleChangeFun(email)}
                    // disabled={!editState}
                  />
                  <Typography
                    className="notify-text"
                    style={{ marginLeft: "10px" }}
                  >
                    Email
                  </Typography>

                  {/* <Box
                    component="span"
                    sx={{ width: "70px" }}
                    className="notify-box"
                  >
                    <Typography className="notify-setup">
                      Setup Email
                    </Typography>
                  </Box> */}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  style={{ padding: "5px" }}
                >
                  {/* <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    onChange={handleChangeFun}
                    value={values.notificationMethod}
                  >
                    <FormControlLabel
                      className="notify-text"
                      value="teams"
                      control={
                        <Radio
                          sx={{
                            color: "",
                            "&.Mui-checked": {
                              color: lightGreen[600],
                            },
                          }}
                        />
                      }
                      label="Teams"
                    />
                  </RadioGroup> */}
                  {/* <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} /> */}
                  <ToggleButton
                    name="teams"
                    id="teams"
                    value={values.notificationMethod == "teams" ? 1 : 0}
                    onChange={(teams) => handleChangeFun(teams)}
                    // disabled={!editState}
                  />
                  <Typography className="notify-text">Teams</Typography>

                  {/* <Box
                    component="span"
                    sx={{ width: "75px" }}
                    className="notify-box"
                  >
                    <Typography className="notify-setup">
                      Setup Teams
                    </Typography>
                  </Box> */}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  style={{ padding: "5px" }}
                >
                  <ToggleButton
                    name="teamsandemail"
                    id="teamsandemail"
                    value={
                      values.notificationMethod == "email_and_teams" ? 1 : 0
                    }
                    onChange={() => handleChangeFun("email_and_teams")}
                    // disabled={!editState}
                  />
                  <Typography
                    className="notify-text"
                    style={{ marginLeft: "10px" }}
                  >
                    Teams and Email
                  </Typography>
                </Stack>
                {/* <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    onChange={handleChangeFun}
                    value={values.notificationMethod}
                  >
                    <FormControlLabel
                      className="notify-text"
                      value="email_and_teams"
                      control={
                        <Radio
                          sx={{
                            color: "",
                            "&.Mui-checked": {
                              color: lightGreen[600],
                            },
                          }}
                        />
                      }
                      label="Email and Teams"
                    />
                  </RadioGroup>
                  <Box
                    component="span"
                    sx={{ width: "110px" }}
                    className="notify-box"
                  >
                    <Typography className="notify-setup">
                      Setup Email & Teams
                    </Typography>
                  </Box>
                </Stack> */}
              </FormControl>
              {/* <Divider
                style={{ marginTop: "10px", borderColor: "rgb(0 0 0 / 7%)" }}
              /> */}
              {/* <Box sx={{ p: "15px 20px" }}>
                <Stack direction="row" justifyContent="space-between">
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    className="notify-text-one"
                  >
                    PAUSE NOTIFICATIONS?
                  </FormLabel>
                  <Box
                    style={{
                      backgroundColor: "#7cbb00",
                      padding: "5px 5px",
                      borderRadius: "5px",
                    }}
                    data-status={values.pauseStatus == "on" ? "off" : "on"}
                    onClick={handleStatus}
                  >
                    {values.pauseStatus == "on" ? (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Typography className="pause-text">YES</Typography>
                        <Box className="pause-box"></Box>
                      </Stack>
                    ) : (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Box className="pause-box"></Box>

                        <Typography className="pause-text">NO</Typography>
                      </Stack>
                    )}
           
                  </Box>
                </Stack>
                <FormControl style={{ marginTop: "10px" }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"

                    name="pauseTimePeriod"
                    id="pauseTimePeriod"
                    value={values.pauseTimePeriod}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      className="notify-text"
                      disabled={values.pauseStatus == "off" ? true : false}
                      value="until_tommorrow"
                      control={
                        <Radio
                          sx={{
                            color: "",
                            "&.Mui-checked": {
                              color: lightGreen[600],
                            },
                          }}
                        />
                      }
                      label="Until tommorrow"
                    />
                    <FormControlLabel
                      className="notify-text"
                      disabled={values.pauseStatus == "off" ? true : false}
                      value="indefinitely"
                      control={
                        <Radio
                          sx={{
                            color: "",
                            "&.Mui-checked": {
                              color: lightGreen[600],
                            },
                          }}
                        />
                      }
                      label="Indefinitely"
                    />
                  </RadioGroup>
                </FormControl>
              </Box> */}
            </Item>
          </Grid>

          {/* email preference */}
          {/* <Grid item xs={12} md={6}> */}
            {/* <Item> */}
              {/* <Typography className="notify-header">
                Email Notification
              </Typography> */}
              {/* <Divider style={{marginTop:'10px',marginBottom:'10px'}}/>

      <Stack direction='row'
        justifyContent='space-between'>
        <FormLabel id="demo-radio-buttons-group-label" className="notify-text-one">PREFERRED Email</FormLabel>
<Box className='email-box'
sx={{textAlign:'center'}}
onClick={addmailFun}
>
  <Stack direction='row'
  spacing={1}
  justifyContent='space-between'>

<IconButton>
  <img src={plus}/>
</IconButton>
  <Typography className="email-text" sx={{padding:'6px'}}
  >Add Email</Typography>
  </Stack>

</Box>
        </Stack>
    <FormControl>
      
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={values.preferredEmail}
        name="preferredEmail"
        value={values.preferredEmail}
onChange={handleChange}
      >
        {values.allEmails&&values.allEmails.map((r)=>{
          return(
             <FormControlLabel 
        className="notify-text" 
        value={r} control={<Radio 
          sx={{
            color: '',
            '&.Mui-checked': {
              color: lightGreen[600],
            },
          }}/>} label={r} />
          )
        })}
      
      </RadioGroup>
      {mailFlag&&
     <div>

      <TextField id="standard-basic" label="Email" variant="standard" 
      onKeyDown={handleKeyDown}/>
      {isValidEmail ? null : <p style={{ color: 'red' }}>Invalid email format</p>}
</div>

      }
    
    </FormControl> */}

              {/* <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

              <FormControl>
                <Stack direction="row">
                  <Typography>SEND ME EMAIL NOTIFICATIONS FOR:</Typography>
                  <IconButton style={{ padding: "2px" }}>
                    <img src={infoIcon} />
                  </IconButton>
                </Stack>
              </FormControl>
              <Typography className="notify-message" sx={{ mt: "10px" }}>
                Lorem ipsum dolor sit amet,consectetur adipiscing elit,sed to
                eiusmod tempor incididunt ut laboreet dolore magna aliqua.
              </Typography>
              <FormControlLabel
                style={{ paddingBottom: "40px" }}
                className="notify-text"
                control={
                  <Checkbox
                    defaultChecked={values.emailSendItems}
                    onChange={handleChangeemail}
                    value="monthly_reports"
                    style={customCheckboxStyle}
                  />
                }
                label="Monthly Reports"
              /> */}
            {/* </Item> */}
          {/* </Grid> */}

          {/* App notification */}
          {/* <Grid item xs={12} md={6}>
            <Item
              sx={{ mt: "20px", padding: "0px !important", boxShadow: "none" }}
            >
              <Typography className="notify-header">
                App Notification
              </Typography>
              <Divider style={{ borderColor: "rgb(0 0 0 / 7%)" }} />
              <Box sx={{ p: "15px 20px" }}>
                <Stack direction="row">
                  <Typography className="notify-text-one">
                    SEND ME EMAIL NOTIFICATIONS FOR:
                  </Typography>
                  <IconButton style={{ padding: "2px" }}>
                    <img src={infoIcon} />
                  </IconButton>
                </Stack>
                <Stack direction="column">
                  <FormControlLabel
                    className="notify-text"
                    control={
                      <Checkbox
                        defaultChecked={values.appSendItems.includes(
                          "activity_updates"
                        )}
                        onChange={handleCheckboxChange}
                        value="activity_updates"
                        style={customCheckboxStyle}
                      />
                    }
                    label="Activity Updates"
                  />

                  <FormControlLabel
                    className="notify-text"
                    control={
                      <Checkbox
                        defaultChecked={values.appSendItems.includes(
                          "daily_reports"
                        )}
                        // defaultChecked={true}
                        onChange={handleCheckboxChange}
                        value="daily_reports"
                        style={customCheckboxStyle}
                      />
                    }
                    label="Daily Summaries"
                  />
                  <FormControlLabel
                    className="notify-text"
                    control={
                      <Checkbox
                        defaultChecked={values.appSendItems.includes(
                          "weekly_reports"
                        )}
                        onChange={handleCheckboxChange}
                        value="weekly_reports"
                        style={customCheckboxStyle}
                      />
                    }
                    label="Weekly Reports"
                  />
                  <FormControlLabel
                    className="notify-text"
                    control={
                      <Checkbox
                        defaultChecked={values.appSendItems.includes(
                          "monthly_reports"
                        )}
                        onChange={handleCheckboxChange}
                        value="monthly_reports"
                        style={customCheckboxStyle}
                      />
                    }
                    label="Monthly Reports"
                  />
                </Stack>
              </Box>
            </Item>
          </Grid> */}
          {/* ms team notification */}
          {/* <Grid item xs={12} md={6}>
            <Item
              sx={{ mt: "20px", padding: "0px !important", boxShadow: "none" }}
            >
              <Typography className="notify-header">
                MS Teams Notification
              </Typography>
              <Divider style={{ borderColor: "rgb(0 0 0 / 7%)" }} />
              <Box sx={{ p: "15px 20px" }}>
                <Stack direction="row">
                  <Typography className="notify-text-one">
                    SEND ME EMAIL NOTIFICATIONS FOR:
                  </Typography>
                  <IconButton style={{ padding: "2px" }}>
                    <img src={infoIcon} />
                  </IconButton>
                </Stack>
                <Stack direction="column">
                  <FormControlLabel
                    className="notify-text"
                    control={
                      <Checkbox
                        defaultChecked={values.teamsAppSendItems.includes(
                          "activity_updates"
                        )}
                        onChange={handleCheckboxChangeteam}
                        value="activity_updates"
                        style={customCheckboxStyle}
                      />
                    }
                    label="Activity Updates"
                  />
                  <FormControlLabel
                    className="notify-text"
                    control={
                      <Checkbox
                        defaultChecked={values.teamsAppSendItems.includes(
                          "daily_reports"
                        )}
                        onChange={handleCheckboxChangeteam}
                        value="daily_reports"
                        style={customCheckboxStyle}
                      />
                    }
                    label="Daily Summaries"
                  />
                  <FormControlLabel
                    className="notify-text"
                    control={
                      <Checkbox
                        defaultChecked={values.teamsAppSendItems.includes(
                          "weekly_reports"
                        )}
                        onChange={handleCheckboxChangeteam}
                        value="weekly_reports"
                        style={customCheckboxStyle}
                      />
                    }
                    label="Weekly Reports"
                  />
                  <FormControlLabel
                    className="notify-text"
                    // style={{ paddingBottom: "94px" }}
                    control={
                      <Checkbox
                        defaultChecked={values.teamsAppSendItems.includes(
                          "monthly_reports"
                        )}
                        onChange={handleCheckboxChangeteam}
                        value="monthly_reports"
                        style={customCheckboxStyle}
                      />
                    }
                    label="Monthly Reports"
                  />
                </Stack>
              </Box>
            </Item>
          </Grid> */}

          <Grid item xs={12}>
            <Box style={{ textAlign: "center" }}>
              <Button
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#00A1F1",
                  margin: "5px",
                  marginTop: "20px",
                  width: { xs: "100%", md: "465px" },
                  height: "50px",
                  borderRadius: "5px",
                }}
                type="submit"
                onClick={handleSubmit}
              >
                {settingLoading && (
                  <CustomLoader loading={settingLoading ? true : false} />
                )}
                SAVE CHANGES
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
