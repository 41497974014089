import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import {
  Grid,
  IconButton,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Navbar from "../../layout/Navbar";
import backicon from "../../../images/backicon.png";
import { useLocation, useNavigate } from "react-router-dom";
import LocationIcon from "../../../images/location-icon.svg";
import MailIcons from "../../../images/mail-icon.svg";
import PhoneIcon from "../../../images/phone-icon.svg";
import OrganizationLicenses from "./OrganizationLicenses";
import { GET_ORGANIZATION_DETAILS } from "../../common/ApiUrl";
import {
  CustomMessage,
  authHeader,
  getDomain,
  getSideFlag,
} from "../../common/mainfunctions";
import editicon from "../../../images/editIcon.png";
import API from "../../common/API";
import { useSnackbar } from "notistack";
import deleteIcon from "../../../images/deleteicon.png";
import { useDispatch } from "react-redux";
import OrganizationOffices from "./OrganizationOffices";
import OrganizationDepartment from "./OrganizationDepartment";
import OrganizationRoles from "./OrganizationRoles";
import OrganizationJobTitles from "./OrganizationJobTitles";
import oval from "../../../images/white oval.png";
import OrganizationWorkflow from "./OrganizationWorkflow";
import SystemUserList from "../../SystemUser/SystemUserList";
import OrganizationDomain from "./OrganizationDomain";
function TabPanel({ children, value, index, other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default function OrganizationDetailsIndex() {
  const { state } = useLocation();
  const location = useLocation();
  const oid = (location.state && location.state.eid) || "";
  const { orgid } = state || [];
  const navigation = useNavigate();
  let domain = getDomain();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const cancelFun = () => {
    navigation("/organization");
  };
  const editOrgFun = (e) => {
    navigation("/organization-edit", { state: { eid: oid || "" } });
  };
  useEffect(() => {
    const tok = authHeader();
    if (!tok) {
      navigation("/signin");
    } else if (tok && !oid) {
      navigation("/organization");
    }
  }, []);
  const getOrgDetails = async (values) => {
    const config = {
      method: "GET",
      url: GET_ORGANIZATION_DETAILS,
      headers: authHeader(),
      params: {
        organizationId: oid,
      },
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        setData(data);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
    }
  };
  useEffect(() => {
    getOrgDetails();
  }, [orgid]);

  const { orgDetails = [] } = data || [];
  const { name, address, contactEmail, contactNumber, profilePath, isActive } =
    orgDetails || [];
  return (
    <>
      <Box sx={{ display: "flex" }}>
        {getSideFlag() == "true" ? (
          <></>
        ) : (
          <Navbar name="Organization Settings" />
        )}
        <Box
          component="main"
          sx={{ flexGrow: 1, mt: getSideFlag() == "true" ? "-11px" : "55px" }}
        >
          <Stack direction="row" spacing={2} sx={{ margin: "15px 0px" }}>
            <IconButton>
              <img src={backicon} onClick={cancelFun} />
            </IconButton>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: "17px",
                lineHeight: "26px",
                marginTop: "2px",
              }}
            >
              ORGANIZATION DETAILS
            </Typography>
          </Stack>
          <Box className="address-box" sx={{ flexGrow: 1 }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={10}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box className="logo-box">
                    <img
                      src={profilePath}
                      style={{ width: "-webkit-fill-available" }}
                    ></img>
                  </Box>
                  <Box>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography variant="h4">{name}</Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <img src={LocationIcon} />
                      <Typography className="address-text" variant="body2">
                        {address}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <img src={MailIcons} />
                      <Typography className="address-text" variant="body2">
                        {contactEmail}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <img src={PhoneIcon} />
                      <Typography className="address-text" variant="body2">
                        {contactNumber}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} md={2} sx={{ textAlign: "right" }}>
                <Stack direction="row" justifyContent="space-between">
                  {console.log(isActive)}
                  {isActive == 1 ? (
                    <Box
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#7CBB00",
                        width: "80px",
                        borderRadius: "10px",
                        margin: "10px",
                      }}
                    >
                      <Stack direction="row" justifyContent="center">
                        <IconButton>
                          <img src={oval}></img>
                        </IconButton>
                        <Typography
                          style={{
                            fontWeight: "700",
                            color: "#FFF",
                            fontSize: "12px",
                            margin: "5px 10px",
                            pr: "5px !important",
                            marginLeft: "0px",
                          }}
                        >
                          Active
                        </Typography>
                      </Stack>
                    </Box>
                  ) : (
                    <></>
                  )}
                  <IconButton onClick={editOrgFun}>
                    <img src={editicon} />
                  </IconButton>
                  {/* <IconButton>
                    <img src={deleteIcon} />
                  </IconButton> */}
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  maxWidth: { xs: 300, sm: 620, md: "100%" },
                  width: "100%",
                  mt: "20px",
                }}
              >
                <Tabs
                  className="tabs"
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab value={1} label="LICENSE" />
                  <Tab value={2} label="OFFICE" />
                  <Tab value={3} label="DEPARTMENT" />
                  {/* <Tab value={5} label="ROLES" /> */}
                  <Tab value={6} label="JOB TITLE" />
                  <Tab value={4} label="SYSTEM USER" />

                  <Tab value={7} label="WORKFLOW" />
                  <Tab value={8} label="DOMAIN" />
                </Tabs>
              </Box>

              <TabPanel value={1} index={value}>
                <OrganizationLicenses
                  data={data}
                  getOrgDetails={getOrgDetails}
                  orgid={oid}
                />
              </TabPanel>
              <TabPanel value={2} index={value}>
                <OrganizationOffices
                  data={data}
                  getOrgDetails={getOrgDetails}
                  orgid={oid}
                />
              </TabPanel>
              <TabPanel value={3} index={value}>
                <OrganizationDepartment
                  data={data}
                  getOrgDetails={getOrgDetails}
                  orgid={oid}
                />
              </TabPanel>
              <TabPanel value={4} index={value}>
                <SystemUserList
                  data={data}
                  getOrgDetails={getOrgDetails}
                  orgid={oid}
                />
              </TabPanel>
              <TabPanel value={5} index={value}>
                <OrganizationRoles
                  data={data}
                  getOrgDetails={getOrgDetails}
                  orgid={oid}
                />
              </TabPanel>
              <TabPanel value={6} index={value}>
                <OrganizationJobTitles
                  data={data}
                  getOrgDetails={getOrgDetails}
                  orgid={oid}
                />
              </TabPanel>
              <TabPanel value={7} index={value}>
                <OrganizationWorkflow
                  data={data}
                  getOrgDetails={getOrgDetails}
                  orgid={oid}
                />
              </TabPanel>
              <TabPanel value={8} index={value}>
                <OrganizationDomain
                  data={data}
                  getOrgDetails={getOrgDetails}
                  orgid={oid}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
