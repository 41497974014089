export const NAV_FLAG_INITIAL=true
export const STATE_LIST_INITIAL=[]
export const ZIP_CODE_LIST_INITIAL=[]
export const STEP_ONE_INITIAL=[]
export const MIC_AUTH_FLAG=false
export const AUTH_FLAG_INITIAL=""
export const USER_DETAILS_INITIAL=[]
export const GROUP_LIST_INITIAL=[]
export const OFFICE_LIST_INITIAL=[]
export const DEPARTMENT_LIST_INITIAL=[]
export const ROLE_LIST_INITIAL=[]
export const JOB_TITLE_LIST_INITIAL=[]
export const LICENCE_LIST_INITIAL=[]
export const SYSTEM_LICENCE_LIST_INITIAL=[]
export const USER_LIST_INITIAL=[]
export const CREATE_ORG_DETAILS_INITIAL={
    name: "",
    contactEmail: "",
    contactNumber: "",
    address: "",
    state: "",
    zipCode: "",
    profilePath: "",
    licenses: [
      {
        productName: "",
        productId: "",
        productPrice: "",
        priceType: "",
        quantity: 0
      }
    ],
    offices: [
      {
        name: ""
      }
    ],
    departments: [
      {
        name: ""
      }
    ],
    roles: [
      {
        name: ""
      }
    ],
    "jobTitles": [
      {
        "name": ""
      }
    ]
  }
export const STEP_TWO_INITIAL={
    arr:[
       
]}
export const STEP_THREE_DATA_INITIAL={
  arr:[]
}
export const STEP_FOUR_DATA_INITIAL={
  department:[]
}
export const STEP_FIVE_DATA_INITIAL={
  roles:[]

}
export const STEP_SIX_DATA_INITIAL={
  jobTitles:[]
}
export const DOMAIN_LIST_INITIAL=[]
export const HARD_ROLE_LIST_INITIAL=[]
export const DISTRIBUTION_LIST_INITIAL=[]
