import React, { useState } from "react";
import {
  PERMISSIONN_FLAG_UPDATE_URL,
  PERMISSION_URL,
} from "../../common/ApiUrl";
import { CustomMessage, authHeader } from "../../common/mainfunctions";
import API from "../../common/API";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  Typography,
  alpha,
} from "@mui/material";
import BitMapPax from "../../../images/Bitmap.png";
import CustomLoader from "../../common/CustomLoader";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import entraid from "../../../images/menid.jpg";
import { useDispatch } from "react-redux";
import { getUserDetails } from "../../../redux/action";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(10),
  },
  "& .MuiInputBase-input": {
    borderRadius: "0px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    marginTop: "15px !important",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const initialValues = {
  appObjectId: "",
};
export default function PermissionPopup(props) {
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { open, handleClose, azureFlag, setAzureFlag } = props;
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    appObjectId: Yup.string().trim().required("Object Id is required"),
  });
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      permissionFun(values);
    },
  });
  const flagUpdate = async () => {
    const config = {
      method: "POST",
      url: PERMISSIONN_FLAG_UPDATE_URL,
      headers: authHeader(),
      data: {
        permissionAddedFlag: "1",
        powershellPermissionFlag: null,
      },
    };
    try {
      const response = await API(config);
      const { status, data, message } = response.data;
      console.log("mes", message);
      if (status == "Success") {
        const { permissionAddedFlag } = data;
        CustomMessage(message, "success", enqueueSnackbar);
        setAzureFlag(permissionAddedFlag);
        dispatch(getUserDetails());
        handleClose();
      }
      console.log("worked1");
    } catch (error) {
      console.error(error);
    }
  };
  const permissionFun = async (values) => {
    const { appObjectId } = values;
    if (appObjectId == "") {
      CustomMessage(
        "Missing required parameters. Please provide all necessary information.",
        "error",
        enqueueSnackbar
      );
    } else {
      setLoading(true);
      const options = {
        method: "POST",
        url: PERMISSION_URL,
        headers: authHeader(),
        data: {
          appObjectId: appObjectId,
        },
      };
      try {
        var apiResponse = await API(options);
        const { status, data, message } = apiResponse.data;
        if (status === "Success") {
          // CustomMessage(message, "success", enqueueSnackbar);
          const { appClientId, tenantId } = data;
          sessionStorage.setItem("Flag", 1);
          localStorage.setItem("Flag", 1);

          // let tid = localStorage.getItem("TID");
          if (tenantId && appClientId) {
            setTimeout(() => {
              let childWindow = window.open(
                `https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${appClientId}&scope=https://graph.microsoft.com/.default&redirect_uri=${redirecturl}`,
                "_blank"
              );
              // handleShell();
              if (childWindow) {
                window.addEventListener("message", (event) => {
                  // Handle the message received from the child window
                  if (event.data.type === "flagMessage") {
                    console.log(
                      "Received message from child window:",
                      event.data
                    );
                    flagUpdate();
                  }
                  //  else {
                  //   CustomMessage(
                  //     "Unable to add permissions",
                  //     "error",
                  //     enqueueSnackbar
                  //   );
                  // }
                });
              }
              // handleClose();
            }, 5000);
          }
        }
      } catch (error) {
        if (error.response) {
          const { message } = error.response.data;

          CustomMessage(message, "error", enqueueSnackbar);
        }
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      }
    }
  };
  return (
    <>
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></BootstrapDialogTitle>
          <DialogContent>
            <Box style={{ textAlign: "center" }}>
              <img style={{ height: "50px", width: "auto" }} src={entraid} />
            </Box>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              Azure Permission
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl
                sx={{ width: "100%", minWidth: "500px" }}
                variant="standard"
              >
                <BootstrapInput
                  placeholder="Object ID"
                  name="appObjectId"
                  id="appObjectId"
                  onChange={handleChange}
                  value={values.appObjectId}
                />
              </FormControl>
              <FormHelperText error={true}>
                {errors.appObjectId && touched.appObjectId
                  ? errors.appObjectId
                  : ""}
              </FormHelperText>
              <Box style={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  className="login-btn"
                  size="medium"

                  // onClick={permissionFun}
                >
                  <CustomLoader loading={loading} />
                  Submit
                </Button>
                <Button
                  type="submit"
                  className="login-btn3"
                  size="medium"
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
              </Box>
            </form>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
}
