import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Navbar from "../layout/Navbar";
import SearchIcon from "@mui/icons-material/Search";
import IndividualList from "./IndiviualList";
import SharedList from "./SharedList";
import ConfigureMailbox from "./ConfigureMailbox";
import {
  MAILBOX_LIST_URL,
  MAILBOX_USER_LIST_URL,
  SHARED_MAIL_BOX_LIST_URL,
} from "../common/ApiUrl";
import { authHeader, getOrgId, getSideFlag } from "../common/mainfunctions";
import API from "../common/API";
import { debounce } from "underscore";
import { useEffect } from "react";
import { getDistributionList } from "../../redux/action";
import { useDispatch } from "react-redux";

function TabPanel({ children, value, index, other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
export default function MailboxList() {
  const [value, setValue] = useState(1);
  const [list, setList] = useState([]);
  const [Blist, setBList] = useState([]);
  const [config, setConfig] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [sharedList, setSharedList] = useState([]);
  const [BsharedList, setBSharedList] = useState([]);
  const [usersList, setUList] = useState([]);

  const dispatch = useDispatch();
  const setSearchFun = (e) => {
    setSearch(e.target.value);
  };
  const getAllUsersList = async (search) => {
    const config = {
      method: "GET",
      url: MAILBOX_USER_LIST_URL,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { message, status, data } = response.data;
      if (status == "Success") {
        const transformedArray = data.map((item) => ({
          label: item.DisplayName,
          id: item.Identity,
          value: item.DisplayName,
          upn: item.UserPrincipalName,
        }));
        const modifiedGlist = [
          ...transformedArray,
          { label: "done", id: "", value: "" },
        ];
        console.log(modifiedGlist);
        setUList(modifiedGlist);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setUserLoading(false)
    }
  };
  // useEffect(() => {
  //   getAllUsersList();
  // }, []);
  const getIndividualMailList = async () => {
    setLoading(true);
    let flist = [];
    const config = {
      method: "GET",
      url: MAILBOX_LIST_URL,
      headers: authHeader(),
      // params:{
      //   organizationId:getOrgId(),
      // }
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        getAllUsersList();
        setList(data);
        setBList(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (value == 1) {
      getIndividualMailList();
    }
  }, [value]);
  const getSharedMailList = async () => {
    setLoading(true);
    let flist = [];
    const config = {
      method: "GET",
      url: SHARED_MAIL_BOX_LIST_URL,
      headers: authHeader(),
      // params:{
      //   organizationId:getOrgId(),
      // }
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        setSharedList([data]);
        setBSharedList(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (value == 2) {
      getSharedMailList();
    }
  }, [value]);
  useEffect(() => {
    console.log("search");
    if (search != "" && value == 1) {
      let flist =
        Blist &&
        Blist.filter((r) =>
          r.DisplayName.toLowerCase().startsWith(search.toLowerCase())
        );
      console.log("search1", flist, list);

      setList(flist);
    } else if (search != "" && value == 2) {
      let flist =
        BsharedList &&
        BsharedList.filter((r) =>
          r.DisplayName.toLowerCase().startsWith(search.toLowerCase())
        );
      setSharedList(flist);
    }
  }, [search]);
  useEffect(() => {
    if (search == "" && value == 1) {
      console.log("empty search", search, list);
      setList(Blist);
    } else if (search == "" && value == 2) {
      setSharedList(BsharedList);
    }
  }, [search]);
  useEffect(() => {
    dispatch(getDistributionList());
  }, []);
  // const delayedQuery = useRef(debounce((filter) => getIndividualMailList(filter), 500))
  //   .current;

  // useEffect(() => {
  //   delayedQuery(search);
  // }, [search]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const openConfig = () => {
    setConfig(!config);
  };
  const donoth = () => {};
  const handleclose = () => {
    setConfig(false);
    getIndividualMailList();
  };
  const successCallBack = () => {
    setConfig(false);
    getIndividualMailList();
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        {getSideFlag() == "true" ? (
          <></>
        ) : (
          <Navbar name="Mailbox Maintenance" />
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: getSideFlag() === "true" ? "0px" : "20px",
            pt: "20px",
          }}
        >
          <Grid
            sx={{ mt: getSideFlag() == "true" ? -2 : 5 }}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid item xs={12} md={6}>
              <Typography
                className="dis-head-name"
                style={{ marginLeft: "20px" }}
              >
                Inactive Users
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sx={{
                display: { xs: "unset", sm: "unset", md: "flex" },
                textAlign: "right",
                pl: {
                  xs: "20px !important",
                  sm: "20px !important",
                  md: "8px !important",
                },
              }}
            >
              {/* <Box className='config-btn1'> */}

              <Typography
                style={{
                  margin: "5px 25px 5px 5px",
                  cursor: "pointer",
                  backgroundColor: loading ? "#e0dada" : "#FFFFFF",
                  borderRadius: "5px",
                  padding: "6px 2px 2px 2px",
                  minWidth: "70px",
                  textAlign: "center",
                }}
                className={loading ? "config-btn-dis" : "config-btn"}
                // onClick={openConfig}
                onClick={loading ? donoth : openConfig}
                disabled={loading}
              >
                Configure
              </Typography>
              {/* </Box> */}
              <Paper
                //   component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "-webkit-fill-available",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "20px",
                  height: "50px",
                  boxShadow: "none !important",
                }}
              >
                <InputBase
                  sx={{ flex: 1, padding: "2px 2px 2px 12px !important" }}
                  placeholder="Search ..."
                  inputProps={{ "aria-label": "   search" }}
                  onChange={setSearchFun}
                  value={search}
                  disabled={loading}
                />
                <IconButton
                  type="submit"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  style={{
                    backgroundColor: "#FFAB00",
                    borderRadius: "10%",
                    color: "#FFF",
                  }}
                  onClick={setSearchFun}
                  disabled={loading}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", marginLeft: "10px", mt: "20px" }}>
            <Tabs
              className="tabs1"
              value={value}
              onChange={handleChange}
              aria-label="secondary tabs example"
            >
              <Tab value={1} label="INDIVIDUAL" />
              <Tab value={2} label="SHARED" />
            </Tabs>
            <TabPanel value={1} index={value}>
              <IndividualList
                loading={loading}
                search={search}
                list={list}
                getIndividualMailList={getIndividualMailList}
                usersList={usersList}
              />
            </TabPanel>
            <TabPanel value={2} index={value}>
              <SharedList
                loading={loading}
                search={search}
                list={sharedList}
                getSharedMailList={getSharedMailList}
              />
            </TabPanel>
          </Box>
        </Box>
      </Box>
      {config && (
        <ConfigureMailbox
          open={config}
          handleclose={handleclose}
          successCallBack={successCallBack}
        />
      )}
    </>
  );
}
