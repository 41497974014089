import * as types from "../action/ActionType";
import { AUTH_FLAG_INITIAL } from "../store/InitialStore";
export default function AuthTokenSaveReducer(state = AUTH_FLAG_INITIAL, action) {
  const { type, payload } = action;
  switch (type) {
    case types.AUTH_TOKEN_TYPE:
      return (state = payload);
    default:
      return state;
  }
}
