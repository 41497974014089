import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Typography, alpha } from "@mui/material";
import shareIcon from "../../images/shared.svg"
import PropTypes from "prop-types";
import CustomLoader from "../common/CustomLoader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { ALL_USERS_LIST_URL, CONVERT_SHARED_MAIL_URL, MAILBOX_USER_LIST_URL } from "../common/ApiUrl";
import { CustomMessage, authHeader } from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";
import { useEffect } from "react";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  const BootstrapInputSel = styled(Select)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      //   borderRadius: "32.5px",
      position: "relative",
      backgroundColor: "#F8F8F8",
      //border: "2px solid #CFCFCF",
      fontSize: 18,
      width: "100%",
      padding: "18px 20px",
      color: "#343434",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  const initialValues={
    userPrincipalName:'',
    userIds:'Select users',
    groupIds:'Select groups',
  }
export default function SharedConfirmation(props){
    const {open,handleclose,usersList,upn}=props
    const [loading,setLoading]=useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDoneButton, setShowDoneButton] = useState(false);
  const [showDoneButton1, setShowDoneButton1] = useState(false);
  const glist = useSelector((gl) => gl.DistributionGroupListReducer);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [dscl,setDoneClick]=useState('no')
  const handleDoneButtonClick = () => {
    setDropdownOpen(!dropdownOpen);
    setAnchorEl(null)

      // multiSelectRef.current.focus(); // Remove focus
    // }
  };
  const handleDoneButtonClick1 = () => {
    setDropdownOpen1(!dropdownOpen1);
    setAnchorEl(null)

      // multiSelectRef.current.focus(); // Remove focus
    // }
  };
  const [selectedone1, setSelectedone1] = useState([]);
  const [selectedone, setSelectedone] = useState([]);
  const [allItemsAreSelected, setAllItemsAreSelected] = useState(false);
const {enqueueSnackbar}=useSnackbar()
const customOptionRenderer = ({ checked, option, onClick }) => (
  <>
  <div key={option.value}>
      <label>
        <input
        style={{opacity:option.label=='done'?'0':''}}
          type="checkbox"
          className="option-checkbox"
          checked={option.label=='done'?true:checked}
          onChange={() => {
            onClick(option);
           
            if(option.label!='done'){
            setDropdownOpen(false)
            }
            setShowDoneButton(true);
          }}
        />
        {option.label!='done'?option.label:''}
      </label>
      
    </div>
    <div>

    {showDoneButton && option === glist[glist.length-1] && (
      <Box style={{textAlign:'end'}}>
      <Button onClick={handleDoneButtonClick}>Done</Button>
      </Box>
    )}
    </div>

</>
);
const customOptionRenderer1 = ({ checked, option, onClick }) => (
  <>
  <div key={option.value}>
      <label>
        <input
        style={{opacity:option.label=='done'?'0':''}}
          type="checkbox"
          className="option-checkbox"
          checked={option.label=='done'?true:checked}
          onChange={() => {
            onClick(option);
           
            if(option.label!='done'){
            setDropdownOpen1(false)
            }
            setShowDoneButton1(true);
          }}
        />
        {option.label!='done'?option.label:''}
      </label>
      
    </div>
    <div>

    {showDoneButton1 && option === usersList&&usersList[usersList.length-1] && (
      <Box style={{textAlign:'end'}}>
      <Button onClick={handleDoneButtonClick1}>Done</Button>
      </Box>
    )}
    </div>

</>
);

    // const validationSchema = Yup.object({
    //   userIds:Yup.string().required('Inactive duration is required'),
    //   groupIds:Yup.string().required('Notification email is required')
    //   })
      const sharedConvertion=async()=>{
        setLoading(true)
        let selone=selectedone.filter((el)=>el.label!='done')
        const idsArray = selone.map(item => item.id);
        // users
        let userone=selectedone1.filter((el)=>el.label!='done')
        const idsArrayuser = userone.map(item => item.id);
        const userprincipalArr = userone.map(item => item.upn);
        console.log('ids result',userone,idsArrayuser,userprincipalArr)
        const config={
          method:"POST",
          url:CONVERT_SHARED_MAIL_URL,
          headers:authHeader(),
          data:{
            userPrincipalName:upn,
            userIds:idsArrayuser.toString(),
            groupIds:idsArray.toString(),
          }
        }
        try{
          const response=await API(config)
          const {status,data}=response.data
          if(status=='Success'){
            CustomMessage('Converted successfully','success',enqueueSnackbar)
            handleclose()
          }
        }catch(error){
          console.error(error)
          if (error.response) {
            const { message } = error.response.data;
            CustomMessage(message, "error", enqueueSnackbar);
          }

        }finally{
          setLoading(false)
        }
      }
    const {
      handleSubmit,
      handleChange,
      setValues,
      values,
      errors,
      setErrors,
      touched,
    } = useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      // validationSchema: validationSchema,
      onSubmit: (values) => {
        sharedConvertion(values);
      },
    });
    return(
        <>
         <div>
      <BootstrapDialog
        className="rq-user-popup org-popup "
        onClose={handleclose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleclose}
        ></BootstrapDialogTitle>
        <DialogContent>
          
          <Box style={{ textAlign: "center" }}>
            <img src={shareIcon} />
          </Box>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            Convert as Shared Mailbox
          </Typography>
          {/* {userLoading&&<CustomLoader loading={loading}/>} */}

          <form 
          onSubmit={handleSubmit}
          >
            <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
               Add Users 
              </InputLabel>
              <FormControl
              style={{ marginTop: "10px"}}
              sx={{ width: "100%", minWidth: "500px",backgroundColor:'#f8f8f8' }}
              variant="standard"

            >
            <MultiSelect

            style={{backgroundColor:'#f8f8f8'}}
            id="userIds"
            open={false}
            name="userIds"
        options={usersList}
        value={selectedone1&&
          selectedone1.length ==usersList&& usersList.length
          ?selectedone1:selectedone1.filter((el)=>el.label!='done')}
        onChange={setSelectedone1}
        labelledBy="Users" 
        closeOnChangedValue={dropdownOpen1}
        ItemRenderer={customOptionRenderer1}
        // onMenuOpen={() => setDropdownOpen(true)}
        //   onMenuClose={() => setDropdownOpen(false)}
          overrideStrings={{
            selectSomeItems: "Users(you can select more than 1)" ,
            allItemsAreSelected:selectedone1.length==2
            ?selectedone1.map(item => item.label!='done'?item.label :'')
            :'All users are selected',
            search: 'Search'
          }}
          allItemsAreSelected={usersList&&usersList.length!=2&&selectedone1.length == usersList&&usersList.length-1?true:false}
          
      />
        <FormHelperText error={true}>
                {errors.userIds && touched.userIds ? errors.userIds : ""}
              </FormHelperText>
      </FormControl>
              <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
             Add Groups
              </InputLabel>
              <FormControl
              style={{ marginTop: "10px"}}
              sx={{ width: "100%", minWidth: "500px",backgroundColor:'#f8f8f8' }}
              variant="standard"

            >
            <MultiSelect

            style={{backgroundColor:'#f8f8f8'}}
            id="group"
            open={false}
            name="group"
        options={glist}
        value={
          selectedone.length == glist.length
          ?selectedone:selectedone.filter((el)=>el.label!='done')}
        onChange={setSelectedone}
        labelledBy="Group" 
        closeOnChangedValue={dropdownOpen}
        ItemRenderer={customOptionRenderer}
        // onMenuOpen={() => setDropdownOpen(true)}
        //   onMenuClose={() => setDropdownOpen(false)}
          overrideStrings={{
            selectSomeItems: 'Groups(you can select more than 1)',
            allItemsAreSelected:selectedone.length==2
            ?selectedone.map(item => item.label!='done'?item.label :'')
            :'All Groups are selected',
            search: 'Search'
          }}
          allItemsAreSelected={glist.length!=2&&selectedone.length == glist.length-1?true:false}
          
      />
        <FormHelperText error={true}>
                {errors.group && touched.group ? errors.group : ""}
              </FormHelperText>
      </FormControl>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  className="login-btn-group-bl"
                  size="medium"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  <CustomLoader loading={loading} />
                  SUBMIT
                </Button>
                <Button
                  className="login-btn-group"
                  size="medium"
                  onClick={handleclose}
                >
                  CANCEL
                </Button>
              </Box>
</form>
          </DialogContent>
          </BootstrapDialog>
          </div>
        </>
    )
}