import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import filicon from "../../../images/filter-new.png";
import GetAppIcon from "@mui/icons-material/GetApp";
import importicon from "../../../images/import.jpg";
import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  Stack,
  InputLabel,
} from "@mui/material";
import { useSnackbar } from "notistack";

import emptyxlsx from "../../../images/Office-SampleDocument.xlsx";
import { CustomMessage, authHeader } from "../../common/mainfunctions";
import API from "../../common/API";
import { OFFICES_IMPORT_URL } from "../../common/ApiUrl";
import * as XLSX from "xlsx";
import CustomLoader from "../../common/CustomLoader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const BootstrapInputSel = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const initialValues = {
  userName: "User Name",
};
export default function OrgOfficeImport(props) {
  const { handleClose, open, setFilter, setSearch } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState();
  const [file, setFile] = React.useState();
  const [format, setFormat] = React.useState();

  const handleFileChange = async (e) => {
    let fileFormat = e.target.files[0].name.split(".")[1];
    console.log("fileFor", fileFormat);

    if (fileFormat == "xlsx" || fileFormat == "csv") {
      setFile(e.target.files[0]);
      setFormat(fileFormat);
    } else {
      CustomMessage(
        "Please upload files in CSV and XLSX formats only.",
        "error",
        enqueueSnackbar
      );
    }
    console.log("e", e, e.target.files[0].name.split(".")[1]);
  };
  const handleSubmit = async () => {
    setSearch();
    // handleClose();
    console.log("format", format);
    if (format == "csv" || format == "xlsx") {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", format);
      const config = {
        method: "POST",
        url: OFFICES_IMPORT_URL,
        headers: authHeader(),
        data: formData,
      };
      try {
        const res = await API(config);
        const { status, message } = res.data;
        if (status == "Success") {
          CustomMessage(message, "success", enqueueSnackbar);
          handleClose();
        }
      } catch (error) {
        if (error.response) {
          let { data } = error.response;
          CustomMessage(data.message, "error", enqueueSnackbar);
        }
      } finally {
        setLoading(false);
      }
    } else {
      CustomMessage(
        "Please upload files in CSV and XLSX formats only.",
        "error",
        enqueueSnackbar
      );
    }
  };
  const handleChange = (e) => {
    const off = e.target.value;
    setValue(e.target.value);
  };

  const handleDownload = () => {
    // Create an anchor element
    const anchor = document.createElement("a");

    // Set the href attribute to the path of the Excel file
    anchor.href = emptyxlsx;

    // Set the download attribute to the desired file name
    anchor.download = "Office.xlsx";

    // Trigger a click event on the anchor element
    anchor.click();
  };
  return (
    <div>
      <BootstrapDialog
        className="org-popup"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box style={{ textAlign: "center" }}>
            <img style={{ width: "40px", height: "40px" }} src={importicon} />
          </Box>
          <Box style={{ textAlign: "center" }}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                alignContent: "center",
              }}
            >
              Import
            </Typography>
          </Box>
          <Box style={{ textAlign: "left" }}>
            <form>
              <Button
                // style={{ marginLeft: "0px" }}
                // variant="contained"
                // color="default"
                startIcon={<GetAppIcon />}
                onClick={handleDownload} // Call handleDownload function when button is clicked
              >
                Download Template
              </Button>
              <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "16px",
                  mt: "10px",
                  mb: "10px",
                }}
              >
                Upload Template{" "}
              </InputLabel>
              <div>
                <input
                  type="file"
                  accept=".xlsx,.csv"
                  onChange={handleFileChange}
                />
              </div>
              <div>{/* <button type="submit">Upload</button> */}</div>

              <Button
                // type="submit"
                className="login-btn-group-bl"
                size="medium"
                onClick={handleSubmit}
              >
                {loading && <CustomLoader loading={loading} />}
                Submit
              </Button>
              <Button
                className="login-btn-group"
                size="medium"
                onClick={handleClose}
              >
                CANCEL
              </Button>
            </form>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
