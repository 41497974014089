import * as types from "../action/ActionType";
import {  STEP_FIVE_DATA_INITIAL, STEP_SIX_DATA_INITIAL } from "../store/InitialStore";
export default function StepSixReducer(state = STEP_SIX_DATA_INITIAL, action) {
  const { type, payload } = action;
  switch (type) {
    case types.STEP_SIX_DATA_TYPE:
      return {
        ...state,
        jobTitles: [...state.jobTitles, payload]
      }
      case types.STEP_SIX_DATA_TYPE_DELETE:
      return {
        ...state,
        jobTitles:payload
      }
    default:
      return state;
  }
}
