import * as types from "../action/ActionType";
import { ZIP_CODE_LIST_INITIAL } from "../store/InitialStore";
export default function NavbarFlagReducer(state = ZIP_CODE_LIST_INITIAL, action) {
  const { type, payload } = action;
  switch (type) {
    case types.ZIP_CODE_LIST:
      return (state = payload);
    default:
      return state;
  }
}
